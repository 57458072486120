import { useIridiumStore, useEtaStore, useCustomRangeStore } from '@/stores';
import MapIridium from '@/views/showonmap/MapIridium_view.vue';
import range_modal from '@/components/modals/Range_Modal.vue';
import $ from "jquery";

export default {
  name: "Iridium",
  components: {
    MapIridium,
  },
  data() {
    return {
      iridium_store: useIridiumStore(),
      eta_store: useEtaStore(),
      customrange_store : useCustomRangeStore(),
      MapIridiumComp: MapIridium,
      range_modal: range_modal,
      map: null,
      marker: null,
    }
  },

  mounted() {
    this.iridium_store.fetch_iridium()
    var mother = this
    window.showonmap_iridium = function (id) {
       mother.iridium_store.showonMap(id);
    }

    window.onchange_click = function (id,index) {
      mother.iridium_store.onchange_click(id,index);
    }

    window.roledown_click= function (id,index,value) {
      mother.iridium_store.roledown_click(id,index,value);
    }

    $(window).scroll(function () {
      if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        mother.iridium_store.isBottom = true
      } else {
        mother.iridium_store.isBottom = false
      }
    });
  },

  methods: {

  },

  watch: {
  },
  computed: {
    chatroom_character() {
      return 100 - (this.iridium_store.chatroom_data.sender.length + this.iridium_store.chatroom_body.length);
    },
  }
};