import { useNewsStore } from '@/stores';


export default {
  name: "News",
  components: {
  },
  data() {
    return {
      news_store: useNewsStore(),
    }
  },

  mounted() {
    this.news_store.fetch_news()
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};