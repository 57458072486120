import {
    useAuthStore,
    useNavplusStore,
    useSailinkStore,
    useReportsStore,
    useSnapshotStore,
    useThurayaStore,
    useManifestStore,
    useSailoggerStore,
    useOrbCommStore,
    useGlobalStarStore,
    useIridiumStore,
    useAdminStore,
    useCrewwatchStore,
    useSummaryStore,
    useNotifStore,
    useNewsStore,
} from '@/stores';
import Navigatorplus from "@/views/dashboards/NavigatorView.vue";
import Mysailink from "@/views/dashboards/MysailinkView.vue";
import Mythuraya from "@/views/dashboards/MythurayaView.vue";
import Report from "@/views/dashboards/ReportView.vue";
import Snapshot from "@/views/dashboards/SnapshotView.vue";
import Manifest from "@/views/dashboards/ManifestView.vue";
import Sailogger from "@/views/dashboards/SailoggerView.vue";
import OrbComm from "@/views/dashboards/OrbCommView.vue";
import GlobalStar from "@/views/dashboards/GlobalStarView.vue";
import Iridium from "@/views/dashboards/IridiumView.vue";
import Admin from "@/views/dashboards/AdminView.vue";
import Crewwatch from "@/views/dashboards/CrewwatchView.vue";
import Summary from "@/views/dashboards/SummaryView.vue";
import News from "@/views/dashboards/NewsView.vue";
import $ from "jquery";


export default {
    name: "App",
    components: {
        Navigatorplus,
        Mysailink,
        Mythuraya,
        Report,
        Snapshot,
        Manifest,
        Sailogger,
        OrbComm,
        Admin,
        Crewwatch,
        Summary,
        News
    },
    data: function () {
        return {
            currentComponent: Navigatorplus,
            componentActive: 0,
            componentsArray: [
                { title: "Navigator+", component: Navigatorplus },
                { title: "My Sailink", component: Mysailink },
                { title: "Reports", component: Report },
                { title: "Snapshot", component: Snapshot },
                { title: "My Thuraya", component: Mythuraya },
                { title: "Manifest", component: Manifest },
                { title: "Sailogger", component: Sailogger },
                { title: "OrbComm", component: OrbComm },
                { title: "Global Star", component: GlobalStar },
                { title: "Iridium", component: Iridium },
                { title: "Admin", component: Admin },
                { title: "Crewwatch", component: Crewwatch },
                { title: "Summary", component: Summary },
                { title: "News", component: News },
            ],
            authStore: useAuthStore(),
            navplusStore: useNavplusStore(),
            sailinkStore: useSailinkStore(),
            reportStore: useReportsStore(),
            snapshotStore: useSnapshotStore(),
            thurayaStore: useThurayaStore(),
            manifestStore: useManifestStore(),
            sailoggerStore: useSailoggerStore(),
            orbcommStore: useOrbCommStore(),
            globalStarStore: useGlobalStarStore(),
            iridiumStore: useIridiumStore(),
            adminStore: useAdminStore(),
            notification_store: useNotifStore(),
            crewwatchStore: useCrewwatchStore(),
            summaryStore: useSummaryStore(),
            newsStore: useNewsStore(),
        };
    },

    mounted() {

        const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
        const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

        this.authStore.fetch_allowed();
        this.$root.$on('swapComponent', (index) => {
            this.swapComponent(index)
        });


        $(window).scroll(function () {
            console.log("SCROLLLLLLLLLLLLLLLLLLL");

        })



        var mother = this;

        window.onclick_swapComponent = function (index) {
            mother.swapComponent(index)
        }
        window.popover_hide = function () {
            mother.newsStore.popover_hide();
        }

        window.popover_close = function () {
            mother.newsStore.popover_close()
        }

        window.coachmark = function (mark, step) {
            mother.newsStore.coachmark(mark, step);
        }

        window.onclick_notification = function (data, index, isviewall) {
            var item_data;
            if (isviewall == 'VIEWALL') {
                item_data = mother.notification_store.notification.notifications[index];
            } else {
                item_data = data;
            }
            mother.notification_store.onclick_notification(item_data, index);
        }

        window.onclick_notif_speedhistory = function (index, type) {
            mother.notification_store.onclick_notif_speedhistory(index, type);
        }

        window.oncheck_notif = function (id, type) {
            if (type == 'sailink') {
                mother.sailinkStore.oncheck_notif(id);
            } else if (type == 'thuraya') {
                mother.thurayaStore.oncheck_notif(id);
            } else if (type == 'iridium') {
                mother.iridiumStore.oncheck_notif(id);
            } else if (type == 'center') {
                mother.notification_store.oncheck_notif_center(id);
            }
        }
    },

    methods: {
        swapComponent(index) {
            console.log("MASUK KOMPONEN");
            console.log(index);
            this.componentActive = index;
            this.currentComponent = this.componentsArray[index].component;
            if (index == 0) {
                // Navigatorplus
                this.invalidateMap1();
            }
            else if (index == 1) {
                // Sailink
                this.sailinkStore.fetch_sailink()
            }
            else if (index == 2) {
                // Reports
                this.reportStore.fetch_reports()
            }
            else if (index == 3) {
                // Snapshot
                this.snapshotStore.fetch_snapshot()
            }
            else if (index == 4) {
                // Thuraya
                this.thurayaStore.fetch_thuraya()
            }
            else if (index == 5) {
                // Manifest
                this.manifestStore.fetch_manifest()
            }
            else if (index == 6) {
                // Sailogger
                this.sailoggerStore.fetch_sailogger()
            }
            else if (index == 7) {
                // Orbcomm
                this.orbcommStore.fetch_orbcomm()
            }
            else if (index == 8) {
                //GlobalStar
                this.globalStarStore.fetch_globalstar()
            }
            else if (index == 9) {
                //Iridium
                this.iridiumStore.fetch_iridium()
            }
            else if (index == 10) {
                //Admin
                this.adminStore.fetch_admin()
            }
            else if (index == 11) {
                //Crewwatch
                this.crewwatchStore.fetch_crewwatch()
            }
            else if (index == 12) {
                //Summary 
                this.summaryStore.fetch_summary()
            }
            else if (index == 13) {
                //Summary 
                this.newsStore.fetch_news()
            }

        },

        invalidateMap1() {
            this.$root.$emit('invalidateMap') //like this
        },


        notif_showonmap(type, id) {
            var index;

            console.log("SHOW ON MAP");
            console.log("TYPE : " + type);
            console.log("ID   : " + id);

            if (type == 1) {
                this.thurayaStore.showonMap(id)
                index = 4
            }
            else if (type == 2) {
                this.sailinkStore.showonMap(id)
                index = 1
            } else if (type == 3) {
                this.sailoggerStore.show_rpm_report(id, true)
                index = 6
            } else if (type == 5) {
                this.iridiumStore.showonMap(id)
                index = 9
            }

            this.swapComponent(index)
        },
    }


};