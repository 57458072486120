import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore, useNewsStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";
import Chart from 'chart.js/auto'
import moment from 'moment';


export const useSailoggerStore = defineStore({
    id: 'sailogger',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        authStore: useAuthStore(),
        news_store : useNewsStore(),
        sailogger_table: null,
        sailoggergpio_table: null,
        sailoggerreboot_table: null,
        sailoggersuser_table: null,
        sailoggerstrack_table: null,
        is_rpmreport: null,
        item_data: null,
        reboot_item_data: null,
        reboot_data: null,
        interval_reboot: null,
        map_rpmdata: null,
        rpm_chart: null,
        rpm_chart_arr: [],
        search_sailogger: null,
        search_sailoggergpio: null,
        search_reboot: null,
        search_sailoggersuser: null,
        search_sailoggertrack: null,
        sailogger_data: null,
        gpio_data: null,
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 6,
        rpmreport_data: null,
        page_off: 10,
        page_off_gpio: 10,
        page_off_tracking: 10,
        page_off_reboot: 10,
        rpmshow_range: "Today",
        export_range: "today",
        rpmreport_id: null,
        loading_range: false,
        tracking_layer: L.layerGroup(),
        target_layer: L.layerGroup(),
        link_layer: L.layerGroup(),
        search_layer: L.layerGroup(),
        marker_tracking: [],
        moving_marker: null,
        tracking_path: null,
        tracking_latLng: null,
        tracking_rotation: null,
        interval_tracking: null,
        sailogger_map: null,
        rpmMap: null,
        rpmMarker: null,
        sailogger_marker: null,
        date_range: null,
        isBottom: false,
        is_notif: false,
        rpmshowrange_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        page_offgpio_arr: [
            10,
            25,
            50,
            100
        ],

        page_offreboot_arr: [
            10,
            25,
            50,
            100
        ],

        th_sailogger_arr: [
            "No",
            "Vessel Name",
            "Company",
            "Group",
            "Device State",
            "Current GPIO Status",
            "Manage",
            ""
        ],
        th_sailogger_gpio_arr: [
            "No",
            "GPIO Type",
            "Status",
            "Group",
            "dateTime (GMT+7)"
        ],
        th_sailogger_track_arr: [
            "#",
            "Current\n(RPM)",
            "Average\n(RPM)",
            "Min\nRPM)",
            "Max\n(RPM)",
            "Interval\n(minutes)",
            "Uptime\n(minutes)",
            "Latitude, Longitude",
            "Weather Status",
            "Heading\n(Degree)",
            "Speed\n(Knots)",
            "Distance\n(Nmi)",
            "dateTime\n(GMT+7)",
            "Detail",
            ""
        ],

        th_sailogger_reboot_arr: [
            "No",
            "Content",
            "dateTime(GMT+7)",
        ],
        th_sailogger_gpio_arr: [
            "No",
            "Gpio Type",
            "Status",
            "dateTime(GMT+7)",
        ],
        td_button: [
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'button_report', b: 'btn-primary' },
            { a: 'button_show', b: 'btn-success' },
        ],
        td_sailogger_arr: [
            4,
            13.7,
            13.7,
            13.7,
            13.7,
            13.7,
            13.7,
            13.7,
        ],
        td_sailogger_track_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ],
        td_sailogger_gpio_arr: [
            0,
            0,
            0,
            0,
        ],

        rpmalert_data: {
            emails: '',
            fieldset: '',
            id: 0,
            isEnabled: 0,
            max: 0,
            min: 0,
            vessel_pt: ''
        },
        rpmalert_item: null,
        rpmalert_minrpmalert_min: 0,
        fuelusage_data: null,
        fuelusage_item: null,
        fuelusage_engine: '',
        fuelusage_loaddata: null,
        fuelusage_loadtable: [
            [
                '',
                '',
                ''
            ]
        ],
        fuelusage_loadtable_isload: false,
        fuelusage_calculate_isload: false,
        fuelusage_calculate_data: null,
        fuelusage_range: null,
        th_fuelusage_history: [
            "No",
            "Engine Model",
            "Date Range",
            "Usage (l)",
            "dateTime (GMT+7)",
            "Detail",
            "Delete",
        ],
        th_fuelusage_recordedmsg: [
            "#",
            "Current\n(RPM)",
            "Average\n(RPM)",
            "Min\n(RPM)",
            "Max\n(RPM)",
            "Interval\n(Minutes)",
            "Uptime\n(Minutes)",
            "dateTime (GMT+7)",
        ],
        fuelusage_iscalc: false,
        fuelusage_recorded_msgs: null,
        fuelusage_recordmsg_search: '',
        fuelusage_recordmsg_table: null,
        fuelusage_history_table: null,
        recordmsg_page_off: 10,
        sailoggerfetch_count: 0,
        recordmsg_page_off_arr: [
            10,
            25,
            50,
            100
        ],
        fuelhistory_page_off: 10,
        fuelhistory_page_off_arr: [
            10,
            25,
            50,
            100
        ],
        fuelhistory_search: '',

    }),

    actions: {

        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //fetch table sensor management
        async fetch_sailogger() {
            
            await api.get(`/api/v1/navplus/iot`, {}
            ).then((res) => {
                console.log("sailoggers RESPONSE");
                console.log(res);
                this.sailoggerfetch_count = this.sailoggerfetch_count+1
                var mom =  this;
                if (this.search_sailoggers == null || this.search_sailoggers.length == 0) {
                    this.sailogger_data = res.data.data.vessels;
                    if(!this.sailogger_table){
                        this.initTable();
                    }

                    
                    
                }

       


            }).catch((err) => {
            }).then(() => {
            });
        },
        //fetch gpio
        async fetchGPIO(item) {

            await api.get(`/api/v1/navplus/iot/` + item.id, {}
            ).then((res) => {
                console.log("GPIO RESPONSE");
                console.log(res);
                this.initTable_gpio(res.data.data)
                this.gpio_data = res.data.data
            }).catch((err) => {
            }).then(() => {
            });
        },

        async fetch_weather_dt(id, msgid, type) {



            await api.post(`/api/v1/navplus/weather/fetchRow`, {
                id: id,
                msg_id: msgid,
                type: type,
            }
            ).then((res) => {
                console.log("WEATHER DETAIL RESPONSE");
                console.log(res);

                setTimeout(() => {
                    $('#wd' + msgid).html('<span title="The direction from which it originates, measured in degrees clockwise from due north (at 10m).">' + res.data.data.windDirection + '</span>')
                    $('#wd_img' + msgid).html('<img style="height:1em;width:1em" src="' + require('@/assets/weather/wind/' + res.data.data.windIcon) + '"/>')
                    $('#ws' + msgid).html(res.data.data.windSpeed);
                    $('#tmp' + msgid).html(res.data.data.temperature);
                    $('#hmd' + msgid).html(res.data.data.humidity);
                    $('#dp' + msgid).html(res.data.data.dewPoint);
                    $('#wh' + msgid).html(res.data.data.waveSignificantHeight);
                    $('#wfd' + msgid).html('<span title="The direction of the combined wind waves and swells are moving in.">' + res.data.data.waveFromDirection + '</span>');
                    if (res.data.data.waveFromIcon != '-') {
                        $('#wfd_img' + msgid).html('<img style="height:1em;width:1em" src="' + require('@/assets/weather/wind/' + res.data.data.waveFromIcon) + '"/>')
                    }

                }, 100);


            }).catch((err) => {
            }).then(() => {
            });


        },

        async fetch_reboot_sailogger(item) {
            this.reboot_data = null
            if (this.interval_reboot) {
                clearInterval(this.interval_reboot);
            }
            await api.get(`/api/v1/navplus/sailogger/` + item.id + `/reboot`, {}
            ).then((res) => {
                console.log("FETCH REBOOT RESPONSE");
                console.log(res);
                this.reboot_data = res.data.data
                this.reboot_item_data = item
                this.initTable_reboot(res.data.data)
            }).catch((err) => {
            }).then(() => {
            });


        },

        async fetch_reboot_only() {

            await api.get(`/api/v1/navplus/sailogger/` + this.reboot_item_data.id + `/reboot`, {}
            ).then((res) => {
                console.log("FETCH REBOOT RESPONSE");
                console.log(res);
                this.reboot_data = res.data.data
            }).catch((err) => {
            }).then(() => {
            });


        },
        //fetch vessels & websocket subscribe
        async fetch_rpm_report(item, range) {


            this.rpmreport_data = null;
            this.rpmreport_id = item.id;
            console.log(item.id);
            console.log(range)

            if (this.rpm_chart) {
                console.log("DESTROY CHART");

                this.rpm_chart.destroy();
                this.rpm_chart = null
            }

            await api.post(`/api/v1/navplus/iot/rpm`, {
                id: item.id,
                range: range,
            }
            ).then((res) => {
                console.log("RPM REPORT SAILOGGER FETCH");
                console.log(res);
                this.rpmreport_data = res.data.data
                this.moveTracking(res.data.data.tracking)
                this.export_range = range
                setTimeout(() => {
                    this.initTable_tracking(res.data.data)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //fetch report only using id 
        async fetch_rpm_report_notif(id, range) {
            this.rpmreport_data = null;
            this.rpmreport_id = id;
            console.log(id);
            console.log(range)

            if (this.rpm_chart) {
                console.log("DESTROY CHART");
                this.rpm_chart.destroy();
                this.rpm_chart = null
            }

            await api.post(`/api/v1/navplus/iot/rpm`, {
                id: id,
                range: range,
            }
            ).then((res) => {
                console.log("RPM REPORT SAILOGGER FETCH");
                console.log(res);
                this.rpmreport_data = res.data.data
                this.item_data = res.data.data;
                this.moveTracking(res.data.data.tracking)
                this.export_range = range
                setTimeout(() => {
                    this.initTable_tracking(res.data.data)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //fetch rpm alert
        async fetch_rpm_alert(item) {
            await api.get(`/api/v1/navplus/rpm/` + item.id, {}
            ).then((res) => {
                console.log("RPM ALERT SAILOGGER FETCH");
                console.log(res);
                this.rpmalert_data = res.data.data

                this.rpmalert_data = {
                    emails: '',
                    fieldset: '',
                    id: 0,
                    isEnabled: 0,
                    max: 0,
                    min: 0,
                    vessel_pt: ''
                }

                this.rpmalert_data.emails = res.data.data.emails
                this.rpmalert_data.fieldset = res.data.data.fieldset
                this.rpmalert_data.id = res.data.data.id
                this.rpmalert_data.isEnabled = res.data.data.isEnabled
                this.rpmalert_data.max = parseInt(res.data.data.max)
                this.rpmalert_data.min = parseInt(res.data.data.min)
                this.rpmalert_data.vessel_pt = res.data.data.vessel_pt

                if (this.rpmalert_data.isEnabled > 0 || this.rpmalert_data.isEnabled != null) {
                    $("#rpmalert_enable_yes").prop('checked', true);
                    $("#rpmalert_enable_no").prop('checked', false);
                } else {
                    $("#rpmalert_enable_yes").prop('checked', false);
                    $("#rpmalert_enable_no").prop('checked', true);
                }

            }).catch((err) => {
            }).then(() => {
            });
        },
        //fetch fuel calculator
        async fetch_fuel_calc(item) {
            var mom = this
            await api.get(`/api/v1/navplus/engineData/fetch/` + item.id, {}
            ).then((res) => {
                console.log("FUEL CALC SAILOGGER FETCH");
                console.log(res);
                this.fuelusage_data = res.data.data;
                if (res.data.data.latest) {
                    this.fuelusage_engine = res.data.data.latest.engine_model
                    mom.fetch_fuel_load()
                }
                this.set_fuel_range();
                this.initTable_fuel_history(res.data.data.history)
            }).catch((err) => {
            }).then(() => {
            });
        },
        
        //fetch fuel calculator
        async fetch_fuel_load() {
            this.fuelusage_loadtable_isload = true;
            this.fuelusage_loaddata = null;
            var mom = this;
            this.fuelusage_loadtable = [];
            await api.post(`/api/v1/navplus/engineData/load`, {
                engine_model: this.fuelusage_engine
            }
            ).then((res) => {
                this.fuelusage_loadtable_isload = false;
                console.log("FUEL LOAD SAILOGGER FETCH");
                console.log(res);
                if (!this.fuelusage_loaddata) {
                    mom.fuelusage_loaddata = res.data.data.engine_data
                    console.log("LOOPING")
                    console.log(res.data.data.engine_data.fuel_usage_data)
                    for (let key in res.data.data.engine_data.fuel_usage_data) {
                        console.log(`Key: ${key}, Value: ${res.data.data.engine_data.fuel_usage_data[key]}`);
                        let newArray = [key, res.data.data.engine_data.fuel_usage_data[key][0], res.data.data.engine_data.fuel_usage_data[key][1]];
                        console.log(newArray);
                        mom.fuelusage_loadtable.push(newArray);
                    }
                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_fuel_history_delete(item) {
            console.log("DELETE FUEL HISTORY");
                await api.delete("/api/v1/navplus/engineData/history/delete/"+item.id, {}

                ).then((res) => {
                    console.log("DELETE FUEL HISTORY RESPONSE");
                    console.log(res);
                    this.refresh_table_history();
                }).catch((err) => {
               
                }).then(() => {
                });
            
               
        },
        async refresh_table_history(){
            await api.get(`/api/v1/navplus/engineData/fetch/` + this.fuelusage_item.id, {}
            ).then((res) => {
                console.log(res);
                this.initTable_fuel_history(res.data.data.history)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        // POST DATA FUNCTIONS
        //------------------------------------------------------------------ 
        async post_reboot_sailogger() {

            await api.post(`/api/v1/navplus/sailogger/reboot`, {
                thuraya_number: this.reboot_data.number
            }
            ).then((res) => {
                console.log("REBOOT SAILOGGER RESPONSE");
                console.log(res);
                var mom = this;
                var intervalSeconds = 0.5;
                this.interval_reboot = setInterval(function () {
                    if (mom.reboot_data) {
                        mom.fetch_reboot_only();
                    }
                }, intervalSeconds * 1000);

            }).catch((err) => {
            }).then(() => {
            });


        },
        async post_rpmalert() {
            console.log("RPM ALERT CONFIG POST");


            await api.post("/api/v1/navplus/rpm/" + this.rpmalert_data.id, {
                isEnabled: this.rpmalert_data.isEnabled ? this.rpmalert_data.isEnabled : 0,
                rpm_min: this.rpmalert_data.min,
                rpm_max: this.rpmalert_data.max,
                emails: this.rpmalert_data.emails,
            }

            ).then((res) => {
                console.log("RPM ALERT CONFIG POST RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#sailoggerRPMAlertModal_alert').removeClass('d-none')
                    $('#sailoggerRPMAlertModal_alert').addClass('d-block')
                    $('#sailoggerRPMAlertModal_alert').html('Successfully Updated RPM Alert Configuration!')

                    setTimeout(() => {
                        $('#sailoggerRPMAlertModal_alert').removeClass('d-block')
                        $('#sailoggerRPMAlertModal_alert').addClass('d-none')
                        $('#sailoggerRPMAlertModal_alert').html('')
                    }, 1500);

                } else {
                    $('#sailoggerRPMAlertModal_alert').removeClass('alert-success')
                    $('#sailoggerRPMAlertModal_alert').addClass('alert-danger')
                    $('#sailoggerRPMAlertModal_alert').removeClass('d-none')
                    $('#sailoggerRPMAlertModal_alert').addClass('d-block')
                    $('#sailoggerRPMAlertModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#sailoggerRPMAlertModal_alert').addClass('alert-success')
                        $('#sailoggerRPMAlertModal_alert').removeClass('alert-danger')
                        $('#sailoggerRPMAlertModal_alert').removeClass('d-block')
                        $('#sailoggerRPMAlertModal_alert').addClass('d-none')
                        $('#sailoggerRPMAlertModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#sailoggerRPMAlertModal_alert').removeClass('alert-success')
                $('#sailoggerRPMAlertModal_alert').addClass('alert-danger')
                $('#sailoggerRPMAlertModal_alert').removeClass('d-none')
                $('#sailoggerRPMAlertModal_alert').addClass('d-block')
                $('#sailoggerRPMAlertModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#sailoggerRPMAlertModal_alert').addClass('alert-success')
                    $('#sailoggerRPMAlertModal_alert').removeClass('alert-danger')
                    $('#sailoggerRPMAlertModal_alert').removeClass('d-block')
                    $('#sailoggerRPMAlertModal_alert').addClass('d-none')
                    $('#sailoggerRPMAlertModal_alert').html('')
                }, 1500);
            }).then(() => {
            });

        },
        async post_loadtable() {
            console.log("SAVE LOAD TABLE");
            var rpm_avg = []
            var fuel_rate = []
            var is_alert = false;

            for (let i = 0; i < this.fuelusage_loadtable.length; i++) {

                console.log(this.fuelusage_loadtable[i][0]);
                console.log(this.fuelusage_loadtable[i][1]);
                console.log(this.fuelusage_loadtable[i][2]);
                console.log('---');

                if (is_alert == false && ((this.fuelusage_loadtable[i][1].length == 0 || this.fuelusage_loadtable[i][2].length == 0) || this.fuelusage_loadtable[i][0].length == 0)) {
                    is_alert = true
                } else {
                    var avg = this.fuelusage_loadtable[i][1] + '-' + this.fuelusage_loadtable[i][2]
                    rpm_avg.push(avg)
                    fuel_rate.push(this.fuelusage_loadtable[i][0])
                }

            }

            console.log(this.fuelusage_engine);
            console.log(fuel_rate);
            console.log(rpm_avg);

            if (!is_alert) {
                this.fuelusage_loadtable_isload = true;
                await api.post("/api/v1/navplus/engineData/save", {
                    engine_model: this.fuelusage_engine,
                    rpm_avg: rpm_avg,
                    fuel_rate: fuel_rate
                }

                ).then((res) => {
                    console.log("RPM ALERT CONFIG POST RESPONSE");
                    console.log(res);
                    this.fuelusage_loadtable_isload = false

                    if (res.status == 200) {
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').html('Engine Data Saved Successfully!')

                        setTimeout(() => {
                            $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                            $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                            $('#sailoggerFuelCalcModal_alert').html('')
                        }, 1500);

                    } else {
                        $('#sailoggerFuelCalcModal_alert').removeClass('alert-success')
                        $('#sailoggerFuelCalcModal_alert').addClass('alert-danger')
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').html('Something wrong, cannot save!')

                        setTimeout(() => {
                            $('#sailoggerFuelCalcModal_alert').addClass('alert-success')
                            $('#sailoggerFuelCalcModal_alert').removeClass('alert-danger')
                            $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                            $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                            $('#sailoggerFuelCalcModal_alert').html('')
                        }, 1500);
                    }

                }).catch((err) => {
                    this.fuelusage_loadtable_isload = false
                    $('#sailoggerFuelCalcModal_alert').removeClass('alert-success')
                    $('#sailoggerFuelCalcModal_alert').addClass('alert-danger')
                    $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                    $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                    $('#sailoggerFuelCalcModal_alert').html('Something wrong, cannot save!')

                    setTimeout(() => {
                        $('#sailoggerFuelCalcModal_alert').addClass('alert-success')
                        $('#sailoggerFuelCalcModal_alert').removeClass('alert-danger')
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').html('')
                    }, 1500);
                }).then(() => {
                });
            } else {
                $('#sailoggerFuelLoad_alert').removeClass('d-none')
                $('#sailoggerFuelLoad_alert').addClass('d-block')
                $('#sailoggerFuelLoad_alert').html('Wrong Format, Please Check Your Input!')

                setTimeout(() => {
                    $('#sailoggerFuelLoad_alert').removeClass('d-block')
                    $('#sailoggerFuelLoad_alert').addClass('d-none')
                    $('#sailoggerFuelLoad_alert').html('')
                }, 4000);
            }








        },
        async post_fuel_calculate() {
            console.log("POST FUEL CALCULATE");
            var rpm_avg = []
            var fuel_rate = []
            var is_alert = false;
            this.fuelusage_calculate_data = null;
            var mom = this;

            for (let i = 0; i < this.fuelusage_loadtable.length; i++) {

                console.log(this.fuelusage_loadtable[i][0]);
                console.log(this.fuelusage_loadtable[i][1]);
                console.log(this.fuelusage_loadtable[i][2]);
                console.log('---');

                if (is_alert == false && ((this.fuelusage_loadtable[i][1].length == 0 || this.fuelusage_loadtable[i][2].length == 0) || this.fuelusage_loadtable[i][0].length == 0)) {
                    is_alert = true
                } else {
                    var avg = this.fuelusage_loadtable[i][1] + '-' + this.fuelusage_loadtable[i][2]
                    rpm_avg.push(avg)
                    fuel_rate.push(this.fuelusage_loadtable[i][0])
                }

            }

            console.log(this.fuelusage_engine);
            console.log(fuel_rate);
            console.log(rpm_avg);

            if (!is_alert) {
                this.fuelusage_calculate_isload = true;
                await api.post("/api/v1/navplus/engineData/calculate/" + this.fuelusage_item.id, {
                    rpm_avg: rpm_avg,
                    fuel_rate: fuel_rate,
                    range: this.fuelusage_range
                }

                ).then((res) => {
                    console.log("FUEL CALCULATE RESPONSE");
                    console.log(res);
                    this.fuelusage_calculate_isload = false
                    this.fuelusage_calculate_data = res.data.data
                    this.fuelusage_recorded_msgs = res.data.data.recorded_info.recorded_msgs
                    this.initTable_fuel_recordmsg(res.data.data.recorded_info.recorded_msgs)

                    $('#modal_fuelusage').animate({
                        scrollTop: ($("#Fuel_Calculation").offset().top) - 60
                    }, 500);

                    if (res.status == 200) {
                        mom.fuelusage_iscalc = true;
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').html('Calculation Successful!')

                        setTimeout(() => {
                            mom.fuelusage_iscalc = false;
                            $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                            $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                            $('#sailoggerFuelCalcModal_alert').html('')
                        }, 1500);

                    } else {
                        $('#sailoggerFuelCalcModal_alert').removeClass('alert-success')
                        $('#sailoggerFuelCalcModal_alert').addClass('alert-danger')
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').html('Something wrong!')

                        setTimeout(() => {
                            $('#sailoggerFuelCalcModal_alert').addClass('alert-success')
                            $('#sailoggerFuelCalcModal_alert').removeClass('alert-danger')
                            $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                            $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                            $('#sailoggerFuelCalcModal_alert').html('')
                        }, 1500);
                    }

                }).catch((err) => {
                    this.fuelusage_loadtable_isload = false
                    $('#sailoggerFuelCalcModal_alert').removeClass('alert-success')
                    $('#sailoggerFuelCalcModal_alert').addClass('alert-danger')
                    $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                    $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                    $('#sailoggerFuelCalcModal_alert').html('Something wrong!')

                    setTimeout(() => {
                        $('#sailoggerFuelCalcModal_alert').addClass('alert-success')
                        $('#sailoggerFuelCalcModal_alert').removeClass('alert-danger')
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').html('')
                    }, 1500);
                }).then(() => {
                });
            } else {
                $('#sailoggerFuelLoad_alert').removeClass('d-none')
                $('#sailoggerFuelLoad_alert').addClass('d-block')
                $('#sailoggerFuelLoad_alert').html('Wrong Format, Please Check Your Input!')

                setTimeout(() => {
                    $('#sailoggerFuelLoad_alert').removeClass('d-block')
                    $('#sailoggerFuelLoad_alert').addClass('d-none')
                    $('#sailoggerFuelLoad_alert').html('')
                }, 4000);
            }








        },
        async post_fuel_calculate_save() {
            console.log("SAVE FUEL CALCULATE");
          
                await api.post("/api/v1/navplus/engineData/calculate/"+this.fuelusage_item.id+"/save", {
                    engine_model : this.fuelusage_engine,
                    msg_ids : this.fuelusage_calculate_data.recorded_info.msg_ids,
                    info : this.fuelusage_calculate_data.info,
                    range : this.fuelusage_range
                }

                ).then((res) => {
                    console.log("FUEL CALCULATE SAVE RESPONSE");
                    console.log(res);
                    this.refresh_table_history();
                    
                    $('#modal_fuelusage').animate({
                        scrollTop: ($("#Fuel_History").offset().top) - 20
                    }, 500);

                    if (res.status == 200) { 
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').html('Fuel Calculation Saved Successfully!')

                        setTimeout(() => {
                            $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                            $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                            $('#sailoggerFuelCalcModal_alert').html('')
                        }, 1500);

                    } else {
                        $('#sailoggerFuelCalcModal_alert').removeClass('alert-success')
                        $('#sailoggerFuelCalcModal_alert').addClass('alert-danger')
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').html('Something wrong!')

                        setTimeout(() => {
                            $('#sailoggerFuelCalcModal_alert').addClass('alert-success')
                            $('#sailoggerFuelCalcModal_alert').removeClass('alert-danger')
                            $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                            $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                            $('#sailoggerFuelCalcModal_alert').html('')
                        }, 1500);
                    }

                }).catch((err) => {
                    this.fuelusage_loadtable_isload = false
                    $('#sailoggerFuelCalcModal_alert').removeClass('alert-success')
                    $('#sailoggerFuelCalcModal_alert').addClass('alert-danger')
                    $('#sailoggerFuelCalcModal_alert').removeClass('d-none')
                    $('#sailoggerFuelCalcModal_alert').addClass('d-block')
                    $('#sailoggerFuelCalcModal_alert').html('Something wrong!')

                    setTimeout(() => {
                        $('#sailoggerFuelCalcModal_alert').addClass('alert-success')
                        $('#sailoggerFuelCalcModal_alert').removeClass('alert-danger')
                        $('#sailoggerFuelCalcModal_alert').removeClass('d-block')
                        $('#sailoggerFuelCalcModal_alert').addClass('d-none')
                        $('#sailoggerFuelCalcModal_alert').html('')
                    }, 1500);
                }).then(() => {
                });

        },
        async post_fuel_history_show(item) {
            console.log("SHOW FUEL HISTORY");
                await api.post("/api/v1/navplus/engineData/history/show/"+item.vessel_id, {
                    msg_id: item.id,
                }

                ).then((res) => {
                    console.log("SHOW FUEL HISTORY RESPONSE");
                    console.log(res.data.data.recorded_info.recorded_msgs);
                    this.initTable_fuelhistory_recordmsg(res.data.data.recorded_info.recorded_msgs,item)
                }).catch((err) => {
               
                }).then(() => {
                });
           







        },

        //------------------------------------------------------------------ 



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search sailoggers table
        sailoggerTable_search() {
            if (this.search_sailogger != null && this.search_sailogger.length > 0) {
                this.sailogger_table.search(this.search_sailogger).draw();
            }
        },
        //search sailoggers table
        sailoggerGPIOTable_search() {
            if (this.search_sailoggergpio != null && this.search_sailoggergpio.length > 0) {
                this.sailoggergpio_table.search(this.search_sailoggergpio).draw();
            }
        },
        //search sailoggers table
        rebootTable_search() {
            if (this.search_reboot != null && this.search_reboot.length > 0) {
                this.sailoggerreboot_table.search(this.search_reboot).draw();
            }
        },
        //search sailoggers table
        fuelrecordmsgTable_search() {
            if (this.fuelusage_recordmsg_search != null && this.fuelusage_recordmsg_search.length > 0) {
                this.fuelusage_recordmsg_table.search(this.fuelusage_recordmsg_search).draw();
            }
        },
        fuelhistoryTable_search() {
            if (this.fuelhistory_search != null && this.fuelhistory_search.length > 0) {
                this.fuelusage_history_table.search(this.fuelhistory_search).draw();
            }
        },
        //clear snapshot table
        clear_fuelrecordmsgTable_search() {
            this.fuelusage_recordmsg_search = ''
            this.fuelusage_recordmsg_table.search(this.fuelusage_recordmsg_search).draw();
        },
        //clear snapshot table
        clear_fuelhistoryTable_search() {
            this.fuelhistory_search = ''
            this.fuelusage_history_table.search(this.fuelhistory_search).draw();
        },
        //clear snapshot table
        clear_sailoggerTable_search() {
            this.search_sailogger = ''
            this.sailogger_table.search(this.search_sailogger).draw();
        },
        //clear snapshot table
        clear_sailoggerGPIOTable_search() {
            this.search_sailoggergpio = ''
            this.sailoggergpio_table.search(this.search_sailoggergpio).draw();
        },
        //clear snapshot table
        clear_sailoggerRebootTable_search() {
            this.search_reboot = ''
            this.sailoggerreboot_table.search(this.search_reboot).draw();
        },
        clear_sailoggerTableTrack_search() {
            this.search_sailoggertrack = ''
            this.sailoggerstrack_table.search(this.search_sailoggertrack).draw();
        },
        //search sailoggers user table
        userTable_search() {
            this.sailoggersuser_table.search(this.search_sailoggersuser).draw();
        },
        //search sailoggers tracking table
        trackTable_search() {
            this.sailoggerstrack_table.search(this.search_sailoggertrack).draw();
        },
        //data shorting sailoggers table 
        sailoggersTable_showof(page) {
            console.log("PAGE : " + page);
            this.sailogger_table.page.len(page).draw()
            this.page_off = page;
        },
        //data shorting sailoggers table 
        sailoggerTrackTable_showof(page) {
            console.log("PAGE : " + page);
            this.sailoggerstrack_table.page.len(page).draw()
            this.page_off_tracking = page;
        },
        sailoggerGPIOTable_showof(page) {
            console.log("PAGE : " + page);
            this.sailoggergpio_table.page.len(page).draw()
            this.page_off_gpio = page;
        },
        sailoggerrebootTable_showof(page) {
            console.log("PAGE : " + page);
            this.sailoggerreboot_table.page.len(page).draw()
            this.page_off_reboot = page;
        },
        fuelrecordmsgTable_showof(page) {
            console.log("PAGE : " + page);
            this.fuelusage_recordmsg_table.page.len(page).draw()
            this.recordmsg_page_off = page;
        },
        fuelhistoryTable_showof(page) {
            console.log("PAGE : " + page);
            this.fuelusage_history_table.page.len(page).draw()
            this.fuelhistory_page_off = page;
        },
        //------------------------------------------------------------------


        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        initTable_gpio(data) {
            console.log("INIT TABLE GPIO");
            console.log(this.sailoggergpio_table);
            console.log("MASUK INIT TABLE GPIO");
            console.log(data.output)
            try {
                console.log("MASUK SINI");

                this.sailoggergpio_table = new DataTable('#sailoggerTableGPIO', {
                    data: data.output,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "5%",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                 ${meta.row + 1}
                                </div>
                                `
                            }
                        },
                        {
                            width: "31.6%",
                            data: 'type', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },

                        {
                            width: "31.6%",
                            data: 'label', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return (
                                    data.toLowerCase() == 'plugged' ?
                                        `
                                    <div class="p-1 h-100">
                                    <span class="badge text-bg-success text-uppercase">PLUGGED</span>
                                    </div>` :
                                        `
                                    <div class="p-1 h-100">
                                    <span class="badge text-bg-danger text-uppercase">UNPLUGGED</span>
                                    </div>`
                                )
                            }
                        },
                        {
                            width: "31.6%",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },

                    ]
                });




            } catch (e) {

            }

        },
        initTable_reboot(data) {
            console.log("INIT TABLE REBOOT");
            console.log(this.sailoggerreboot_table);
            console.log("MASUK INIT TABLE REBOOT");
            console.log(data.output)
            try {
                console.log("MASUK SINI");

                this.sailoggerreboot_table = new DataTable('#sailoggerrebootTable', {
                    data: data.output,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "3%",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                 ${meta.row + 1}
                                </div>
                                `
                            }
                        },
                        {
                            width: "68.5%",
                            data: 'content', className: '', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },

                        {
                            width: "28.5%",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },


                    ]
                });




            } catch (e) {

            }

        },
        initTable() {
            var root = this
            if (this.sailogger_table) {
                console.log("MASUK CLEAR TABLE");
                root.sailogger_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.sailogger_data);
                this.sailogger_table = new DataTable('#sailoggerTable', {
                    data: this.sailogger_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "8%",
                            data: '', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "12.7%",
                            data: 'name', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                            }
                        },
                        {
                            width: "13.7%",
                            data: 'pt_name', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "14.7%",
                            data: 'pt_group', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                if (data) {
                                    return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            </div>
                            `
                                } else {
                                    return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                             -
                            </div>
                            `
                                }
                            }
                        },
                        {
                            width: "11.7%",
                            data: 'id', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                             <div> `+ (this.sailogger_data[meta.row].ae3.on ? `UP Since` + this.sailogger_data[meta.row].ae3.on : '-') + `</div>\n
                              <div> `+ (this.sailogger_data[meta.row].ae3.off ? `DOWN at` + this.sailogger_data[meta.row].ae3.off : '-') + `</div>
                            </div>
                            `
                            }
                        },
                        {
                            width: "13.7%",
                            data: 'id', className: 'p-1 no-wrap', render: (data, type, row, meta) => {


                                return `
                                <div class="p-1 h-100 d-block `+ meta.row + `">
                                <div class="row">
                                `+
                                    (
                                        this.sailogger_data[meta.row].devices.isGpio == true ?
                                            `
                                <div class="col-sm-12">
                                <i class="fas fa-bolt me-2"></i> CUBIC AC Power : 
                                `
                                            +
                                            (
                                                this.sailogger_data[meta.row].ae5 == null || this.sailogger_data[meta.row].ae5 ?
                                                    `<span class="badge text-bg-success text-uppercase">PLUGGED</span>` :
                                                    this.sailogger_data[meta.row].ae5 == false ?
                                                        `<span class="badge text-bg-danger text-uppercase blink">UNPLUGGED</span>` :
                                                        ``
                                            )
                                            +
                                            `
                                </div>
                                `
                                            : ``
                                    )
                                    +

                                    (
                                        this.sailogger_data[meta.row].gpio_type == 'sailogger-rpm' ?
                                            `
                                    <div class="col-sm-12">
                                    <i class="fas fa-bolt me-2"></i> SAILOGGER AC Power : 
                                    `
                                            +

                                            (
                                                this.sailogger_data[meta.row].ae6 ?
                                                    `<span class="badge text-bg-success text-uppercase">PLUGGED</span>` :
                                                    this.sailogger_data[meta.row].ae6 == false ?
                                                        `<span class="badge text-bg-danger text-uppercase blink">UNPLUGGED</span>` :
                                                        ``
                                            )

                                            +
                                            `
                                    </div>
                                    `
                                            : ``
                                    )
                                    +
                                    `
                                </div>
                                </div>
                                `


                            }
                        },
                        {
                            width: "12.7%",
                            data: 'id', className: 'p-1 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <div class="row ps-0 pe-1">
                              `
                                    +
                                    (this.sailogger_data[meta.row].devices.isGpio ?
                                        `<div class="col-sm-12 p-1">
                              <button id="slggpio_btn" data-bs-toggle="modal" data-bs-target="#sailoggerGPIOModal"  type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class="fas fa-cog me-2"></i>GPIO History</button>
                              </div>`: ``)
                                    +
                                    (this.sailogger_data[meta.row].devices.rpm == true ?
                                        `<div id="rpmalertdiv_${meta.row}" class="col-sm-12 p-1">
                        <button id="rpmalert_btn" data-bs-toggle="modal" data-bs-target="#modal_rpmalert"   type="button" class="btn btn-sm btn-primary rpmalertbtn_${meta.row} w-100 rounded-none"><i class="fa-solid fa-wrench me-2"></i>RPM Alert</button>
                        </div>`: ``)
                                    +

                                    (this.sailogger_data[meta.row].devices.sailogger && (this.authStore.user.user.role_id <= 2) ?
                                        `<div class="col-sm-12 p-1">
                              <button id="reboot_btn" data-bs-toggle="modal" data-bs-target="#modalreboot_sailogger"   type="button" class="btn btn-sm btn-primary w-100 rounded-none"><i class="fa-solid fa-wrench me-2"></i>Reboot Sailogger</button>
                              </div>`: ``)
                                    +


                                    ((this.sailogger_data[meta.row].devices.rpm || this.sailogger_data[meta.row].devices.iot) ?
                                        `<span class="col-sm-12 p-1" id="fueldiv_${meta.row}" tabindex="0" data-bs-toggle="popover"
                                        >
                              <button id="fuel_btn" data-bs-toggle="modal" data-bs-target="#modal_fuelusage" type="button" class="btn btn-sm fuelbtn_${meta.row} btn-primary w-100 rounded-none"><i class="fa-solid fa-wrench me-2"></i>Fuel Usage Calculator</button>
                              </span>`: ``)
                                    +
                                    `
                              </div>
                              </div>
                              `
                            }
                        },
                        {
                            width: "12.7%",
                            data: 'id', className: 'p-1 no-wrap', render: (data, type, row, meta) => {

                                return `
                        
                              <div class="p-1 h-100">
                              <div class="row ps-1 pe-1">
                              `
                                    +
                                    (this.sailogger_data[meta.row].devices.rpm ?
                                        `<div class="p-1 h-100">
                              <button id="slgrpmshow_btn"  type="button" class="btn btn-sm btn-success w-100 rounded-none"><i class="fas fa-tachometer-alt me-2"></i>RPM Report</button>
                              </div>`: ``)
                                    +
                                    (this.sailogger_data[meta.row].devices.rpm_live ?
                                        `<div class="p-1 h-100">
                              <button id="slgrpmshow_btn"  type="button" class="btn btn-sm btn-success w-100 rounded-none"><i class="fas fa-tachometer-alt me-2"></i><i class="fas fa-dot-circle me-2"></i>RPM Report</button>
                              </div>`: ``)
                                    +
                                    (this.sailogger_data[meta.row].devices.exh ?
                                        `<div class="p-1 h-100">
                              <button id="slgrpmshow_btn"  type="button" class="btn btn-sm btn-success w-100 rounded-none"><i class="fas fa-thermometer-three-quarters me-2"></i>Exhaust Report</button>
                              </div>`: ``)
                                    +
                                    (this.sailogger_data[meta.row].devices.iot ?
                                        `<div class="p-1 h-100">
                                  <button id="slgrpmshow_btn"  type="button" class="btn btn-sm btn-success w-100 rounded-none"><i class="fas fa-tachometer-alt me-2"></i><i class="fas fa-thermometer-three-quarters me-2"></i>IoT Report</button>
                                  </div>`: ``)
                                    +
                                    `
                              </div>
                              </div>
                              `

                            }
                        },
                    ]
                });


                setTimeout(() => {                    
                    if(mom.news_store.popover_mark){
                        mom.news_store.coachmark(mom.news_store.popover_mark,1);
                    }
                }, 500);



                var mom = this;

                this.sailogger_table.on('click', '#slgrpmshow_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailogger_table.row(tr);
                    console.log(row.data());
                    mom.rpm_report(row.data(), false)
                });

                this.sailogger_table.on('click', '#slggpio_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailogger_table.row(tr);
                    console.log("GPIO DI CLICK");
                    console.log(row.data());
                    mom.gpio_data = null
                    mom.fetchGPIO(row.data())
                });

                this.sailogger_table.on('click', '#reboot_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailogger_table.row(tr);
                    console.log("REBOOT DI CLICK");
                    console.log(row.data());

                    mom.fetch_reboot_sailogger(row.data())
                    // mom.gpio_data = null
                    // mom.fetchGPIO(row.data())
                });

                this.sailogger_table.on('click', '#rpmalert_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailogger_table.row(tr);
                    this.rpmalert_item = row.data();
                    console.log("RPM ALERT DI CLICK");
                    console.log(row.data());
                    mom.fetch_rpm_alert(row.data());
                });

                this.sailogger_table.on('click', '#fuel_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.sailogger_table.row(tr);
                    console.log("FUEL USAGE DI CLICK");
                    console.log(row.data());
                    mom.fuelusage_data = null;
                    mom.fuelusage_engine = '';
                    mom.fuelusage_range = null;
                    mom.fuelusage_calculate_data = null;
                    mom.fuelusage_calculate_isload = false;
                    mom.fuelusage_history_table = null;
                    mom.fuelusage_recordmsg_table = null;
                    mom.fuelusage_iscalc = false;
                    mom.fuelusage_recorded_msgs = null;
                    mom.fuelusage_loaddata = null;
                    mom.set_fuel_range();
                    mom.fuelusage_loadtable = [
                        [
                            '',
                            '',
                            '',
                        ]
                    ];
                    mom.fuelusage_item = row.data();
                    mom.fetch_fuel_calc(row.data());
                });
            }
        },
        initTable_user() {
            var root = this
            console.log("INIT TABLE USER");
            console.log(this.sailoggersuser_table);
            console.log("MASUK INIT TABLE USER");
            console.log(this.rpmreport_data.vesselUsers);
            this.sailoggersuser_table = new DataTable('#sailoggersTableUser', {
                data: this.rpmreport_data.vesselUsers,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 5,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "14.25%",
                        data: 'role_name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "34.25%",
                        data: 'lastLogin', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        initTable_tracking(datatracking) {
            console.log("INIT TABLE TRACKING");
            console.log(this.sailoggerstrack_table);
            console.log("MASUK INIT TABLE TRACKING");
            var mom = this;
            this.sailoggerstrack_table = new DataTable('#sailoggersTableTrack', {
                data: datatracking.output,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "6%",
                        data: 'current', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            if (this.rpmreport_data.chart.title.length > 1) {
                                return `
                                <div class="p-0 h-100 ">
                                <b>(P)</b> ${data.split("/")[0]}<br/>
                                <b>(S)</b> ${data.split("/")[1]}
                                </div>
                                `
                            } else {
                                return `
                                <div class="p-0 h-100 ">
                                ${data}
                                </div>
                                `
                            }

                        }
                    },
                    {
                        width: "6%",
                        data: 'avg', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            if (this.rpmreport_data.chart.title.length > 1) {
                                return `
                                <div class="p-0 h-100 ">
                                ${data.split("/")[0]}<br/>
                                ${data.split("/")[1]}
                                </div>
                                `
                            } else {
                                return `
                                <div class="p-0 h-100 ">
                                ${data}
                                </div>
                                `
                            }

                        }
                    },
                    {
                        width: "6%",
                        data: 'min', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            if (this.rpmreport_data.chart.title.length > 1) {
                                return `
                            <div class="p-0 h-100 ">
                            ${data.split("/")[0]}<br/>
                            ${data.split("/")[1]}
                            </div>
                            `
                            } else {
                                return `
                            <div class="p-0 h-100 ">
                            ${data}
                            </div>
                            `
                            }

                        }
                    },
                    {
                        width: "6%",
                        data: 'max', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            if (this.rpmreport_data.chart.title.length > 1) {
                                return `
                            <div class="p-0 h-100 ">
                            ${data.split("/")[0]}<br/>
                            ${data.split("/")[1]}
                            </div>
                            `
                            } else {
                                return `
                            <div class="p-0 h-100 ">
                            ${data}
                            </div>
                            `
                            }

                        }
                    },
                    {
                        width: "6%",
                        data: 'interval', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100 ">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "6%",
                        data: 'w_hour', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            if (this.rpmreport_data.chart.title.length > 1) {
                                return `
                            <div class="p-0 h-100 ">
                            ${data.split("/")[0]}<br/>
                            ${data.split("/")[1]}
                            </div>
                            `
                            } else {
                                return `
                            <div class="p-0 h-100 ">
                            ${data}
                            </div>
                            `
                            }

                        }
                    },
                    {
                        width: "6%",
                        data: 'latitude', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100 ">
                            ${datatracking.output[meta.row].latitude}<br/>
                            ${datatracking.output[meta.row].longitude}
                            </div>
                            `
                        }
                    },
                    {
                        width: "6%",
                        data: 'weather', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            if (data != null) {
                                return `
                            <div class="p-0 h-100 ">
                            ${data}
                             <i id="wtr_btn" class="fas fa-plus-square ms-2 text-success wtr_`+ datatracking.output[meta.row].id + `  btn"></i>
                             <i id="wtroff_btn" style="display:none" class="fas fa-minus-square ms-2 text-danger wtroff_`+ datatracking.output[meta.row].id + `  btn"></i>
                            </div>
                            `
                            } else {
                                return `
                            <div class="p-0 h-100 ">
                            -
                            </div>`
                            }

                        }
                    },
                    {
                        width: "6%",
                        data: 'heading', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100 ">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "6%",
                        data: 'speed', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100 ">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "6%",
                        data: 'distance', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100 ">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "6%",
                        data: 'dateTime_local', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100 ">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "6%",
                        data: 'current', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100 ">-
                            </div>
                            `
                        }
                    },
                    {
                        width: "6%",
                        data: 'current', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100 ">
                           <button id="map_rpm_btn" type="button" class="btn btn-primary btn-sm rounded-none" data-bs-toggle="modal" data-bs-target="#modalmap_rpm"><i class="fas fa-map"></i></button>
                            </div>
                            `
                        }
                    },

                ]
            });


            mom.sailoggerstrack_table.on('click', '#wtr_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sailoggerstrack_table.row(tr);


                row.child(mom.weather_detail(row.data())).show();
                $('.wtroff_' + row.data().id).show()
                $('.wtr_' + row.data().id).hide()

                mom.fetch_weather_dt(mom.rpmreport_data.vessel_id, row.data().weather_info.msg_id, 'rpm')

            });





            mom.sailoggerstrack_table.on('click', '#wtroff_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sailoggerstrack_table.row(tr);

                row.child.hide();
                $('.wtroff_' + row.data().id).hide()
                $('.wtr_' + row.data().id).show()

            });


            mom.sailoggerstrack_table.on('click', '#map_rpm_btn', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.sailoggerstrack_table.row(tr);
                console.log("INVALIDATED SIZE");
                mom.map_rpmdata = null;

                setTimeout(() => {
                    mom.map_rpmdata = row.data()

                }, 500);

            });
        },
        initTable_fuel_recordmsg(data) {
            console.log("INIT TABLE RECORD MSG");
            console.log(this.fuelusage_recordmsg_table);

            try {
                console.log("MASUK SINI");
                this.fuelusage_recordmsg_table = new DataTable('#fuelusageRecordedTable', {
                    data: data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                     ${meta.row + 1}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'current', className: '', render: (data, type, row, meta) => {
                                if (data.includes('/')) {
                                    return `
                                    <div class="p-1 h-100">
                                    <div class="row">
                                    <div class="col-sm-12">
                                    <b>[P]</b> ${data.split('/')[0]}
                                    </div>
                                    <div class="col-sm-12">
                                    <b>[S]</b> ${data.split('/')[1]}
                                    </div>
                                    </div>
                                    </div>
                                    `
                                } else {
                                    return `
                                        <div class="p-1 h-100">
                                        ${data}   
                                        </div>
                                        `
                                }
                            }
                        },
                        {
                            width: "",
                            data: 'avg', className: '', render: (data, type, row, meta) => {
                                if (data.includes('/')) {
                                    return `
                                    <div class="p-1 h-100">
                                    <div class="row">
                                    <div class="col-sm-12">
                                    <b>[P]</b> ${data.split('/')[0]}
                                    </div>
                                    <div class="col-sm-12">
                                    <b>[S]</b> ${data.split('/')[1]}
                                    </div>
                                    </div>
                                    </div>
                                    `
                                } else {
                                    return `
                                        <div class="p-1 h-100">
                                        ${data}   
                                        </div>
                                        `
                                }
                            }
                        },
                        {
                            width: "",
                            data: 'min', className: '', render: (data, type, row, meta) => {
                                if (data.includes('/')) {
                                    return `
                                    <div class="p-1 h-100">
                                    <div class="row">
                                    <div class="col-sm-12">
                                    <b>[P]</b> ${data.split('/')[0]}
                                    </div>
                                    <div class="col-sm-12">
                                    <b>[S]</b> ${data.split('/')[1]}
                                    </div>
                                    </div>
                                    </div>
                                    `
                                } else {
                                    return `
                                        <div class="p-1 h-100">
                                        ${data}   
                                        </div>
                                        `
                                }
                            }
                        },
                        {
                            width: "",
                            data: 'max', className: '', render: (data, type, row, meta) => {
                                if (data.includes('/')) {
                                    return `
                                    <div class="p-1 h-100">
                                    <div class="row">
                                    <div class="col-sm-12">
                                    <b>[P]</b> ${data.split('/')[0]}
                                    </div>
                                    <div class="col-sm-12">
                                    <b>[S]</b> ${data.split('/')[1]}
                                    </div>
                                    </div>
                                    </div>
                                    `
                                } else {
                                    return `
                                        <div class="p-1 h-100">
                                        ${data}   
                                        </div>
                                        `
                                }
                            }
                        },
                        {
                            width: "",
                            data: 'interval', className: '', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'uptime', className: '', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },

                    ]
                });




            } catch (e) {

            }


        },
        initTable_fuel_history(data) {
            console.log("INIT TABLE HISTORY");
            console.log(this.fuelusage_history_table);
            var mom = this;
            try {
                console.log("MASUK SINI");
                this.fuelusage_history_table = new DataTable('#fuelusageHistoryTable', {
                    data: data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                     ${meta.row + 1}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'engine_model', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'range', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'usage', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-0 h-100">
                                <i id="hstdetail_btn" class="fas fa-plus-square ms-2 text-success hstdetail_`+ data + `  btn"></i>
                                <i id="hstdetailoff_btn" style="display:none" class="fas fa-minus-square ms-2 text-danger hstdetailoff_`+ data + `  btn"></i>
                                </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    <button id="fuelhistory_del_btn" class="btn btn-danger rounded-none"><i
                                    class="fas fa-trash"></i></button>
                                    </div>
                                    `
                            }
                        },

                    ]
                });


                mom.fuelusage_history_table.on('click', '#hstdetail_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.fuelusage_history_table.row(tr);

                    console.log(row.data());
                    

                    row.child(mom.fuel_history_detail(row.data())).show();
                    $('.hstdetailoff_' + row.data().id).show()
                    $('.hstdetail_' + row.data().id).hide()

                    mom.post_fuel_history_show(row.data())

                });

                mom.fuelusage_history_table.on('click', '#hstdetailoff_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.fuelusage_history_table.row(tr);

                    row.child.hide();
                    $('.hstdetailoff_' + row.data().id).hide()
                    $('.hstdetail_' + row.data().id).show()

                });


                mom.fuelusage_history_table.on('click', '#fuelhistory_del_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.fuelusage_history_table.row(tr);
                    mom.fetch_fuel_history_delete(row.data());   
                });



            } catch (e) {

            }



        },
        initTable_fuelhistory_recordmsg(data,item) {
            console.log("INIT TABLE HISTORY RECORD MSG");

            try {
                console.log("MASUK SINI");
                var record_history = new DataTable('#fuelhistory_record_tb_'+item.id, {
                    data: data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                     ${meta.row + 1}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'current', className: '', render: (data, type, row, meta) => {
                                if (data.includes('/')) {
                                    return `
                                    <div class="p-1 h-100">
                                    <div class="row">
                                    <div class="col-sm-12">
                                    <b>[P]</b> ${data.split('/')[0]}
                                    </div>
                                    <div class="col-sm-12">
                                    <b>[S]</b> ${data.split('/')[1]}
                                    </div>
                                    </div>
                                    </div>
                                    `
                                } else {
                                    return `
                                        <div class="p-1 h-100">
                                        ${data}   
                                        </div>
                                        `
                                }
                            }
                        },
                        {
                            width: "",
                            data: 'avg', className: '', render: (data, type, row, meta) => {
                                if (data.includes('/')) {
                                    return `
                                    <div class="p-1 h-100">
                                    <div class="row">
                                    <div class="col-sm-12">
                                    <b>[P]</b> ${data.split('/')[0]}
                                    </div>
                                    <div class="col-sm-12">
                                    <b>[S]</b> ${data.split('/')[1]}
                                    </div>
                                    </div>
                                    </div>
                                    `
                                } else {
                                    return `
                                        <div class="p-1 h-100">
                                        ${data}   
                                        </div>
                                        `
                                }
                            }
                        },
                        {
                            width: "",
                            data: 'min', className: '', render: (data, type, row, meta) => {
                                if (data.includes('/')) {
                                    return `
                                    <div class="p-1 h-100">
                                    <div class="row">
                                    <div class="col-sm-12">
                                    <b>[P]</b> ${data.split('/')[0]}
                                    </div>
                                    <div class="col-sm-12">
                                    <b>[S]</b> ${data.split('/')[1]}
                                    </div>
                                    </div>
                                    </div>
                                    `
                                } else {
                                    return `
                                        <div class="p-1 h-100">
                                        ${data}   
                                        </div>
                                        `
                                }
                            }
                        },
                        {
                            width: "",
                            data: 'max', className: '', render: (data, type, row, meta) => {
                                if (data.includes('/')) {
                                    return `
                                    <div class="p-1 h-100">
                                    <div class="row">
                                    <div class="col-sm-12">
                                    <b>[P]</b> ${data.split('/')[0]}
                                    </div>
                                    <div class="col-sm-12">
                                    <b>[S]</b> ${data.split('/')[1]}
                                    </div>
                                    </div>
                                    </div>
                                    `
                                } else {
                                    return `
                                        <div class="p-1 h-100">
                                        ${data}   
                                        </div>
                                        `
                                }
                            }
                        },
                        {
                            width: "",
                            data: 'interval', className: '', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'uptime', className: '', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },
                        {
                            width: "",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 h-100">
                                    ${data}
                                    </div>
                                    `
                            }
                        },

                    ]
                });




            } catch (e) {

            }


        },
        //------------------------------------------------------------------



        //RPM REPORT FUNCTIONS
        //------------------------------------------------------------------
        //show on map sailoggers
        rpm_report(item, is_notif) {

            console.log("RPM REPORT CLICK")
            this.is_rpmreport = false;
            this.is_notif = is_notif;
            $('html, body').animate({ scrollTop: '0px' }, 5);

            setTimeout(() => {

                this.item_data = item;
                this.is_rpmreport = true;
                this.rpmreport_id = null;
                this.map_rpmdata = null;
                this.rpmreport_data = null;

                this.rpmshow_range = "Today";
                this.tracking_layer.clearLayers();
                this.target_layer.clearLayers();
                this.link_layer.clearLayers();

                this.fetch_rpm_report(item, 'today');

                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#rpmreportsailogger_card").offset().top) - 68
                    }, 500);
                }, 10);

            }, 5);
        },
        show_rpm_report(id, is_notif) {

            console.log("RPM REPORT CLICK")
            this.is_rpmreport = false;
            this.is_notif = is_notif;

            $('html, body').animate({ scrollTop: '0px' }, 5);

            setTimeout(() => {

                this.is_rpmreport = true;
                this.rpmreport_id = null;
                this.map_rpmdata = null;
                this.rpmreport_data = null;

                this.rpmshow_range = "Today";
                this.tracking_layer.clearLayers();
                this.target_layer.clearLayers();
                this.link_layer.clearLayers();

                this.fetch_rpm_report_notif(id, 'today');

                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#rpmreportsailogger_card").offset().top) - 68
                    }, 500);
                }, 10);

            }, 5);
        },
        closeRpmReport() {
            this.is_rpmreport = null;
            this.rpmreport_id = null;
            this.rpmreport_data = null;
            this.map_rpmdata = null;
            this.item_data = null;
            this.is_notif = false;
            this.rpmshow_range = "Today";
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.link_layer.clearLayers();
            $('html, body').animate({ scrollTop: '0px' }, 500);

        },
        //------------------------------------------------------------------



        //REBOOT SAILOGGER FUNCTIONS
        //------------------------------------------------------------------
        close_reboot_sailogger() {
            this.reboot_data = null
            if (this.interval_reboot) {
                clearInterval(this.interval_reboot);
            }
        },
        //------------------------------------------------------------------



        //MAP ESSENTIALS FUNCTIONS
        //------------------------------------------------------------------
        return_popup(marker_data, index) {
            return "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                marker_data.lat +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                marker_data.lng +
                "</td><td></td></tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                marker_data.heading +
                "°</td>" +
                "<td></td></tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                marker_data.speed +
                "&nbsp;knots </td>" +
                "<td></td></tr>" +
                "<tr>" +
                " <td><b>Distance</b></td>" +
                "<td>:</td>" +
                "<td>" +
                marker_data.distance +
                "&nbsp;Nmi</td>" +
                "<td></td></tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td></td>" +
                "<td></td>" +
                this.split_header()
                +
                "</tr>" +
                "<tr>" +
                "<td><b>Current</b></td>" +
                "<td>:</td>" +

                this.split_data(this.rpmreport_data.output[index].current)

                +
                "</tr>" +
                "<tr>" +
                "<td><b>Average</b></td>" +
                "<td>:</td>" +
                this.split_data(this.rpmreport_data.output[index].avg)
                +
                "</tr>" +
                "<tr>" +
                "<td><b>Min</b></td>" +
                "<td>:</td>" +
                this.split_data(this.rpmreport_data.output[index].min) +
                "</tr>" +
                "<tr>" +
                "<td><b>Max</b></td>" +
                "<td>:</td>" +
                this.split_data(this.rpmreport_data.output[index].max) +
                "</tr>" +
                "<tr>" +
                "<td><b>Uptime</b></td>" +
                "<td>:</td>" +
                this.split_data(this.rpmreport_data.output[index].w_hour) +
                "</tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                " <td><b>Weather</b></td>" +
                "<td>:</td>" +
                "<td>" +
                marker_data.weather +
                "</td>" +
                "<td></td></tr>" +
                "<tr>" +
                "<td><b>Win</b></td>" +
                "<td>:</td>" +
                " <td>" +
                marker_data.wind +
                "&nbsp;knots </td>" +
                "<td></td></tr>" +
                "<tr>" +
                " <td><b>Waves</b></td>" +
                "<td>:</td>" +
                "<td>" +
                marker_data.wave +
                "</td>" +
                "<td></td></tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td><b>Interval</b></td>" +
                "<td>:</td>" +
                "<td colspan='2'>" +
                marker_data.interval +
                " Minutes</td>" +
                "</tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td colspan='2'>" +
                marker_data.updated +
                "</td>" +
                "</tr>" +
                "</table>"
        },
        return_popup_map(marker_data) {
            return "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                marker_data.latitude +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                marker_data.longitude +
                "</td><td></td></tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                marker_data.heading +
                "°</td>" +
                "<td></td></tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                marker_data.speed +
                "&nbsp;knots </td>" +
                "<td></td></tr>" +
                "<tr>" +
                " <td><b>Distance</b></td>" +
                "<td>:</td>" +
                "<td>" +
                marker_data.distance +
                "&nbsp;Nmi</td>" +
                "<td></td></tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td></td>" +
                "<td></td>" +
                this.split_header()
                +
                "</tr>" +
                "<tr>" +
                "<td><b>Current</b></td>" +
                "<td>:</td>" +

                this.split_data(marker_data.current)

                +
                "</tr>" +
                "<tr>" +
                "<td><b>Average</b></td>" +
                "<td>:</td>" +
                this.split_data(marker_data.avg)
                +
                "</tr>" +
                "<tr>" +
                "<td><b>Min</b></td>" +
                "<td>:</td>" +
                this.split_data(marker_data.min) +
                "</tr>" +
                "<tr>" +
                "<td><b>Max</b></td>" +
                "<td>:</td>" +
                this.split_data(marker_data.max) +
                "</tr>" +
                "<tr>" +
                "<td><b>Uptime</b></td>" +
                "<td>:</td>" +
                this.split_data(marker_data.w_hour) +
                "</tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                " <td><b>Weather</b></td>" +
                "<td>:</td>" +
                "<td>" +
                marker_data.weather +
                "</td>" +
                "<td></td></tr>" +
                "<tr>" +
                "<td><b>Win</b></td>" +
                "<td>:</td>" +
                " <td>" +
                marker_data.wind +
                "&nbsp;knots </td>" +
                "<td></td></tr>" +
                "<tr>" +
                " <td><b>Waves</b></td>" +
                "<td>:</td>" +
                "<td>" +
                marker_data.wave +
                "</td>" +
                "<td></td></tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td><b>Interval</b></td>" +
                "<td>:</td>" +
                "<td colspan='2'>" +
                marker_data.interval +
                " Minutes</td>" +
                "</tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td colspan='2'>" +
                marker_data.dateTime_local +
                "</td>" +
                "</tr>" +
                "</table>"
        },
        initializeMap(map_, marker_) {
            console.log("MAP INITIALIZED");


            this.sailogger_map = map_;
            this.sailogger_marker = marker_;
            this.target_layer.addTo(this.sailogger_map);
            this.tracking_layer.addTo(this.sailogger_map);
            this.link_layer.addTo(this.sailogger_map);
            this.search_layer.addTo(this.sailogger_map);
            this.sailogger_map.scrollWheelZoom.disable();

            this.sailogger_map.setView(new L.LatLng(this.rpmreport_data.maptop.lat, this.rpmreport_data.maptop.lon), 8);
            setTimeout(() => {
                this.sailogger_marker.fire('click')
            }, 700);







        },
        initializeMap_Rpm(rpmmap_, rpmmarker_) {
            console.log("MAP INITIALIZED");
            this.rpmMap = rpmmap_;
            this.rpmMarker = rpmmarker_;
        },
        onmap_click() {
            this.search_layer.clearLayers();
        },
        fullscreen_scroll() {
            console.log("CLICK FULL");
            this.sailogger_map.invalidateSize();
            if (this.sailogger_map.isFullscreen() == true) {
                this.sailogger_map.scrollWheelZoom.enable();
            } else {
                this.sailogger_map.scrollWheelZoom.disable();
            }
        },
        return_track_icon() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#0000FF" 
                stroke-width="0"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M50.8,56.4L30.6,43.7c-1.3-0.8-3-0.8-4.3,0L6.2,56.4c-3.4,2.2-7.5-1.6-5.7-5.2L24.9,2.2c1.5-3,5.7-3,7.2,0l24.5,48.9
                C58.4,54.8,54.3,58.5,50.8,56.4z" fill="#0000FF"></path>
                </svg>`,
                className: "",
                iconSize: [57 / 3.5, 57 / 3.5],
                iconAnchor: [57 / 3.5 / 2, 57 / 3.5 / 2],
            });
            return svgIcon
        },
        return_icon(fill) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                    <svg
                    stroke="#FFF" 
                    stroke-width="10"
                    viewBox="0 0 40 100"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="`+ fill + `"></path>
                    </svg>`,
                className: "",
                iconSize: [40 / 5, 100 / 5],
                iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
            });

            return svgIcon;


        },
        popup_tracking(index) {
            console.log("======================== MAP POPUP TRACKING ========================")
            console.log(index);
            this.link_layer.clearLayers();
            var circle = L.circle([this.marker_tracking[index].lat, this.marker_tracking[index].lng], {
                color: "#1DC01D",
                fillColor: "#1DC01D",
                fillOpacity: 0.0,
                opacity: 0.0,
                weight: 0,
                radius: 1600,
            }).bindPopup(
                "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lat +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lng +
                "</td><td></td></tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].heading +
                "</td>" +
                "<td></td></tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                this.marker_tracking[index].speed +
                "&nbsp;knots </td>" +
                "<td></td></tr>" +
                "<tr>" +
                " <td><b>Distance</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].distance +
                "</td>" +
                "<td></td></tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td></td>" +
                "<td></td>" +
                "<td><b>(P)</b>RPM 1</td>" +
                "<td><b>(S)</b>RPM 2</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Current</b></td>" +
                "<td>:</td>" +
                "<td>927 RPM</td>" +
                "<td>927 RPM</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Average</b></td>" +
                "<td>:</td>" +
                "<td>927 RPM</td>" +
                "<td>927 RPM</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Min</b></td>" +
                "<td>:</td>" +
                "<td>927 RPM</td>" +
                "<td>927 RPM</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Max</b></td>" +
                "<td>:</td>" +
                "<td>927 RPM</td>" +
                "<td>927 RPM</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Uptime</b></td>" +
                "<td>:</td>" +
                "<td>927 RPM</td>" +
                "<td>927 RPM</td>" +
                "</tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td><b>Interval</b></td>" +
                "<td>:</td>" +
                "<td colspan='2'>" +
                this.marker_tracking[index].interval +
                " Minutes</td>" +
                "</tr>" +
                "<tr style='height:1.5em'></tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td colspan='2'>" +
                this.marker_tracking[index].updated +
                "</td>" +
                "</tr>" +
                "</table>", {
                maxWidth: 240
            }
            )

            circle.on("add", function (event) {
                event.target.openPopup();
            });

            setTimeout(() => {
                this.sailogger_map.setView(new L.LatLng(this.marker_tracking[index].lat, this.marker_tracking[index].lng), 6);
            }, 200);

            circle.addTo(this.link_layer)





            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#map_sailogger").offset().top) - 68
                    }, 500);
                }, 10);
            }, 10);


        },
        setview_map() {
            this.sailogger_map.setView(new L.LatLng(this.rpmreport_data.maptop.lat, this.rpmreport_data.maptop.lon), 6);
        },
        //------------------------------------------------------------------



        //SET CHART FUNCTIONS
        //------------------------------------------------------------------
        set_rpmchart() {
            var datas = this.rpmreport_data;

            var label_ = [];
            var datasets_ = [];

            if (this.rpmreport_data) {
                label_ = this.rpmreport_data.chart.label
                datasets_ = this.rpmreport_data.chart.datasets
            }

            console.log("SAMPAI SINI");
            console.log(datasets_);
            console.log(label_);



            setTimeout(() => {
                try {
                    if (datas.chart.title.length > 1) {
                        console.log("ARRAY RPM CHART");
                        for (let i = 0; i < datas.chart.title.length; i++) {
                            var new_chart = new Chart(
                                document.getElementById('rpm_chart_' + i),
                                {
                                    type: 'line',
                                    data: {
                                        labels: label_,
                                        datasets: datasets_[i]
                                    },
                                    options: {
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {
                                                position: "top",
                                            },
                                            title: {
                                                display: false,
                                                text: 'Chart.js Bar Chart - Stacked'
                                            },
                                        },
                                        scales: {
                                            y: {
                                                title: {
                                                    display: true,
                                                    text: "RPM",
                                                },
                                            },
                                        },
                                        interaction: {
                                            mode: "index",
                                            intersect: false,
                                        },
                                    }
                                }
                            );



                            $('#rpm_chart_' + i).removeClass('d-none');
                            $('#rpm_chart_' + i).addClass('d-block');

                            $('#rpm_chart_load' + i).removeClass('d-flex');
                            $('#rpm_chart_load' + i).addClass('d-none');
                        }
                        this.rpm_chart_arr.push(new_chart)
                    } else {

                        console.log("SOLO RPM CHART");


                        var new_chart = new Chart(
                            document.getElementById('rpm_chart'),
                            {
                                type: 'line',
                                data: {
                                    labels: label_,
                                    datasets: datasets_
                                },
                                options: {
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            position: "top",
                                        },
                                        title: {
                                            display: false,
                                            text: 'Chart.js Bar Chart - Stacked'
                                        },
                                    },
                                    scales: {
                                        y: {
                                            title: {
                                                display: true,
                                                text: "RPM",
                                            },
                                        },
                                    },
                                    interaction: {
                                        mode: "index",
                                        intersect: false,
                                    },
                                }
                            }
                        );


                        $('#rpm_chart').removeClass('d-none');
                        $('#rpm_chart').addClass('d-block');

                        $('#rpm_chart_load').removeClass('d-flex');
                        $('#rpm_chart_load').addClass('d-none');

                        this.rpm_chart = new_chart
                    }

                } catch (e) {

                }
            }, 500);

        },
        //------------------------------------------------------------------



        //CHANGE RANGE FUNCTIONS
        //------------------------------------------------------------------
        change_range(range_label, range) {
            console.log("RPM REPORT CLICK")
            this.is_rpmreport = false;
            $('html, body').animate({ scrollTop: '0px' }, 5);

            console.log("CHANGE RANGE DI CLICK")
            console.log("ITEM DATA :" + this.item_data);


            setTimeout(() => {
                this.is_rpmreport = true;
                this.rpmreport_id = null;
                this.map_rpmdata = null;
                this.rpmreport_data = null;

                this.rpmshow_range = range_label;
                this.tracking_layer.clearLayers();
                this.target_layer.clearLayers();
                this.link_layer.clearLayers();

                if (this.is_notif) {
                    this.fetch_rpm_report_notif(this.item_data.vessel_id, range);
                } else {
                    this.fetch_rpm_report(this.item_data, range);

                }


                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#rpmreportsailogger_card").offset().top) - 68
                    }, 500);
                }, 10);

            }, 5);
        },
        //------------------------------------------------------------------



        //OTHER FUNCTIONS
        //------------------------------------------------------------------
        //fetch tracking 24 hour marker clicked
        moveTracking(data_tracking) {
            clearInterval(this.interval_tracking);
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            console.log("MASUK KE MOVE TRACKING");
            console.log(data_tracking);
            this.marker_tracking = data_tracking;
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.moving_marker = null;

            if (data_tracking.length > 0) {
                this.moving_marker = L.marker([data_tracking[0].lat, data_tracking[0].lng], {
                    icon: this.return_track_icon(),
                    rotationAngle: data_tracking[0].heading,
                }).addTo(this.target_layer);

                var firstpolyline = new L.Polyline(data_tracking, {
                    color: '#FEE800',
                    weight: 6,
                    opacity: 0.5,
                    smoothFactor: 1
                });
                this.tracking_layer.addLayer(firstpolyline);
                var mom = this
                var i = data_tracking.length
                setTimeout(() => {


                    data_tracking.forEach(function (coord) {
                        i--
                        if (i != 0) {
                            var circle = L.circle([coord.lat, coord.lng], {
                                color: "#1DC01D",
                                fillColor: "#1DC01D",
                                fillOpacity: 0.3,
                                opacity: 0.6,
                                weight: 2,
                                radius: 1600,
                            }).bindPopup(
                                mom.return_popup(coord, i)
                            ).addTo(mom.target_layer)
                        }


                    });
                }, 1600);
                this.initialdata_moving();
                this.movingStart();
            }


        },
        //moving marker
        initialdata_moving() {
            this.tracking_path = this.marker_tracking.slice()
            if (this.tracking_path[0]) {
                this.tracking_latLng = L.latLng([this.tracking_path[0].lat, this.tracking_path[0].lng])
                this.tracking_rotation = this.tracking_path[0].heading
            }

        },
        //moving marker
        movingStart() {
            var mother = this
            this.interval_tracking = setInterval(function () {
                mother.simulate()
            }, 250);
        },
        simulate() {
            if (this.tracking_path && this.tracking_path.length > 0) {
                let point = this.tracking_path.shift()
                this.tracking_latLng = L.latLng([point.lat, point.lng])
                this.tracking_rotation = point.heading
                this.moving_marker.setLatLng(this.tracking_latLng)
                this.moving_marker.slideTo(this.tracking_latLng, {
                    duration: 500,
                });
                this.moving_marker.options.rotationAngle = this.tracking_rotation;
            } else {
                this.target_layer.removeLayer(this.moving_marker);
                clearInterval(this.interval_tracking);
                this.tracking_path = null;
            }

        },
        //set fuel engine
        push_fuelusage_engine(model) {
            this.fuelusage_engine = model
        },
        ontype_fuel_engine() {
            $("#fuel_eng_sg").click();
            $("#fuel_eng_input").focus();
        },
        //------------------------------------------------------------------



        //OTHER FUNCTIONS
        //------------------------------------------------------------------
        portTime_value(datas) {
            var port = datas;

            console.log("WORKING HOUR");
            let port_minutes = [];
            port.forEach((element) => {
                port_minutes.push([[element.w_hour.split("/")[0]]]);
            });
            console.log(port_minutes);
            console.log(port_minutes.reduce((a, b) => parseInt(a) + parseInt(b), 0));

            var total_port = port_minutes.reduce(
                (a, b) => parseInt(a) + parseInt(b),
                0
            );
            var minutes = total_port;
            var uptime = "";
            var th = Math.floor(minutes / 60);
            if (minutes < 60) {
                uptime = total_port + " Minutes";
            } else if (minutes >= 60 && minutes < 1440) {
                let m = minutes % 60;
                uptime = th + " Hours " + m + " Minutes";
            } else if (minutes >= 1440 && minutes < 43200) {
                let d = Math.floor(minutes / 1440);
                let h = Math.floor((minutes % 1440) / 60);
                let m = minutes % 60;
                uptime =
                    d + " Days " + h + " Hours " + m + " Minutes (" + th + " Hours)";
            } else if (minutes > 43200) {
                let mo = Math.floor(minutes / 43200);
                let d = Math.floor((minutes % 43200) / 1440);
                let h = Math.floor((minutes % 1440) / 60);
                let m = minutes % 60;
                uptime =
                    mo +
                    " Months " +
                    d +
                    " Days " +
                    h +
                    " Hours " +
                    m +
                    " Minutes (" +
                    th +
                    " Hours)";
            }

            console.log(uptime);
            this.time_Uptime = uptime;
            console.log(this.time_Uptime);
            return uptime;
        },
        split_data(data) {
            var td = ''
            for (let i = 0; i < this.rpmreport_data.chart.title.length; i++) {
                td = td + "<td>" + data.split("/")[i] + " RPM</td>"
            }
            return td
        },
        split_header() {
            var td = ''
            if (this.rpmreport_data.chart.title.length > 1) {
                for (let i = 0; i < this.rpmreport_data.chart.title.length; i++) {
                    td = td + "<td><b>(" + Array.from(this.rpmreport_data.chart.title[i])[0] + ")</b>RPM " + i + "</td>"
                }
            } else {
                td = ''
            }

            return td
        },
        weather_detail(d) {

            return (
                '<div class="bg-white p-2 border">' +

                '<div class="modal-header mt-2">' +
                '<h6 class="">Weather & Wave</h6>' +
                '</div>' +

                '<div class="row">' +
                '<div class="col">' +
                '<table class="table table-striped">' +
                '<tr>' +
                '<td>' +
                'Weather' +
                '</td>' +
                '<td>' +
                d.weather +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Wind Direction' +
                '</td>' +
                '<td class="no-wrap" >' +
                '<div class="row">' +
                '<div class="col-sm-1" id="wd_img' + d.id + '" >' +
                '</div>' +
                '<div id="wd' + d.id + '" class="col text-start link_wd">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</div>' +
                '</div>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Wind Speed' +
                '</td>' +
                '<td id="ws' + d.id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '</table>' +
                '</div>' +

                '<div class="col">' +
                '<table class="table table-striped">' +
                '<tr>' +
                '<td>' +
                'Temperature' +
                '</td>' +
                '<td id="tmp' + d.id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Humidity' +
                '</td>' +
                '<td id="hmd' + d.id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Dew Point' +
                '</td>' +
                '<td id="dp' + d.id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '</table>' +
                '</div>' +


                '<div class="col">' +
                '<table class="table table-striped">' +
                '<tr>' +
                '<td>' +
                'Wave Height' +
                '</td>' +
                '<td id="wh' + d.id + '">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>' +
                'Wave From Direction' +
                '</td>' +
                '<td >' +
                '<div class="row">' +
                '<div class="col-sm-1" id="wfd_img' + d.id + '" >' +
                '</div>' +
                '<div id="wfd' + d.id + '" class="col text-start link_wd">' +
                '<p class="placeholder-glow col"><span class="placeholder col-6"></span></p>' +
                '</div>' +
                '</div>' +
                '</td>' +
                '</tr>' +
                '</table>' +
                '</div>' +
                '</div>' +
                '</div>'
            );
        },

        rpmalert_enable_change(value, cat) {
            console.log("IS CHECKED RADIO");
            console.log($('#rpmalert_enable_yes').prop('checked'));
            console.log($('#rpmalert_enable_no').prop('checked'));

            if (cat.toUpperCase() == 'YES') {
                if ($('#rpmalert_enable_yes').prop('checked') == true) {
                    $("#rpmalert_enable_no").prop('checked', false);

                } else if ($('#rpmalert_enable_no').prop('checked') == true) {
                    $("#rpmalert_enable_yes").prop('checked', false);
                }
            } else {
                if ($('#rpmalert_enable_no').prop('checked') == true) {
                    $("#rpmalert_enable_yes").prop('checked', false);

                } else if ($('#rpmalert_enable_yes').prop('checked') == true) {
                    $("#rpmalert_enable_no").prop('checked', false);
                }
            }
            this.rpmalert_data.isEnabled = value
            console.log(this.rpmalert_data.isEnabled);
        },
        push_fuelusage_loadtable() {
            let newArray = ['', '', ''];
            this.fuelusage_loadtable.push(newArray);

        },
        delete_fuelusage_loadtable(index) {
            console.log(this.fuelusage_loadtable)
            console.log("INDEX KE : " + index);
            var newLoadTable = this.fuelusage_loadtable.splice(index, 1);
            console.log(newLoadTable)
            // this.fuelusage_loadtable = newLoadTable;
        },
        keyup_loadtable(index) {
            var value = $('#input_loadtable_' + index).val();
            if ($('#input_loadtable_' + index).val().toString().includes('-')) {
                this.fuelusage_loadtable[index][1] = value.trim().split("-")[0]
                this.fuelusage_loadtable[index][2] = value.trim().split("-")[1]
            } else {
                $('#input_loadtable_' + index).val("-")
                $('#sailoggerFuelLoad_alert').removeClass('d-none')
                $('#sailoggerFuelLoad_alert').addClass('d-block')
                $('#sailoggerFuelLoad_alert').html('Wrong Format, Please Check Your Input!')

                setTimeout(() => {
                    $('#sailoggerFuelLoad_alert').removeClass('d-block')
                    $('#sailoggerFuelLoad_alert').addClass('d-none')
                    $('#sailoggerFuelLoad_alert').html('')
                }, 4000);
            }

        },
        set_fuel_range() {
            var input_name = "fuel_daterange_input"
            console.log("INPUT NAME");
            console.log(input_name);

            setTimeout(() => {
                $(function () {
                    $('input[name="' + input_name + '"]').daterangepicker({
                        timePicker: true,
                        startDate: moment().startOf('hour'),
                        endDate: moment().startOf('hour').add(24, 'hour'),
                        locale: {

                            format: 'YYYY/MM/DD hh:mm A'
                        }
                    });
                });
            }, 1000);

        },
        fuel_calculate() {
            var start_year = $('#fuel_daterange_input').data('daterangepicker').startDate._d.getFullYear();
            var start_month = $('#fuel_daterange_input').data('daterangepicker').startDate._d.getMonth() + 1;
            var start_date = $('#fuel_daterange_input').data('daterangepicker').startDate._d.getDate();
            var start_hours = $('#fuel_daterange_input').data('daterangepicker').startDate._d.getHours();
            var start_minutes = $('#fuel_daterange_input').data('daterangepicker').startDate._d.getMinutes();

            var end_year = $('#fuel_daterange_input').data('daterangepicker').endDate._d.getFullYear();
            var end_month = $('#fuel_daterange_input').data('daterangepicker').endDate._d.getMonth() + 1;
            var end_date = $('#fuel_daterange_input').data('daterangepicker').endDate._d.getDate();
            var end_hours = $('#fuel_daterange_input').data('daterangepicker').endDate._d.getHours();
            var end_minutes = $('#fuel_daterange_input').data('daterangepicker').endDate._d.getMinutes();

            var date1 = start_year + '_'
                + ('0' + start_month).slice(-2) + '_'
                + ('0' + start_date).slice(-2);
            var time1 = ('0' + start_hours).slice(-2) + ':' + ('0' + start_minutes).slice(-2);

            var date2 = end_year + '_'
                + ('0' + end_month).slice(-2) + '_'
                + ('0' + end_date).slice(-2);
            var time2 = ('0' + end_hours).slice(-2) + ':' + ('0' + end_minutes).slice(-2);


            var range_date = date1 + ' ' + time1 + ' - ' + date2 + ' ' + time2;
            this.fuelusage_range = range_date

            this.post_fuel_calculate();

        },
        fuel_history_detail(d) {

            return (
                '<div class="bg-white p-2 border">' +

                '<div class="modal-header mt-2">' +
                '<h6 class="">Fuel Calculation Breakdown</h6>' +
                '</div>' +
                
                '<div class="modal-body">'+

                `
                                <div class="card rounded-none w-100 bg-white">

                  <div class="card-body">
                    <div class="card rounded-none mb-3">
                      <div class="card-header rounded-none bg-white">
                        Calculation Breakdown
                      </div>
                      <div class="card-body">
                        <div id="sailoggerFuelLoad_alert" class="alert rounded-none d-none alert-danger" role="alert">
                        </div>
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th scope="col">RPM Average Range</th>
                              <th scope="col">Fuel Usage Rate (Liter Per Hour)</th>
                              <th scope="col">Total Duration (Minutes)</th>
                              <th scope="col">Usage (Liter) Calculation</th>
                            </tr>
                          </thead>
                          <tbody>`+
                            
                            
                               this.return_detailinfo(d)
                            
                            + `
                            <tr class="bg-body">
                              <td class="bg-body">
                              </td>
                              <td class="bg-body">
                              </td>
                              <td class="text-end bg-body">
                                <span>Total</span>
                              </td>
                              <td class="bg-body">
                                <span>${d.info.total } Liter</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="card rounded-none w-100">
                      <div class="card-header rounded-none bg-white">
                        Recorded Messages
                      </div>
                    
                      <div class="card-body">
                        <div class="table-responsive">
                          <table id="fuelhistory_record_tb_${d.id}" class="table  table-white table-striped  "
                            style="width:100%;vertical-align: middle;">
                            <thead>
                              <tr>
                                <th class="text-start align-middle">#</th>
                                <th class="text-start align-middle">Current (RPM)</th>
                                <th class="text-start align-middle">Average (RPM)</th>
                                <th class="text-start align-middle">Min (RPM)</th>
                                <th class="text-start align-middle">Max (RPM)</th>
                                <th class="text-start align-middle">Interval (Minutes)</th>
                                <th class="text-start align-middle">Uptime (Minutes)</th>
                                <th class="text-start align-middle">dateTime (GMT+7)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="pb-0 pt-0 ">
                                  <p class="placeholder-glow ">
                                    <span class="placeholder col-12"></span>
                                  </p>
                                </td>
                                <td class="pb-0 pt-0 ">
                                  <p class="placeholder-glow ">
                                    <span class="placeholder col-12"></span>
                                  </p>
                                </td>
                                <td class="pb-0 pt-0 ">
                                  <p class="placeholder-glow ">
                                    <span class="placeholder col-12"></span>
                                  </p>
                                </td>
                                <td class="pb-0 pt-0 ">
                                  <p class="placeholder-glow ">
                                    <span class="placeholder col-12"></span>
                                  </p>
                                </td>
                                <td class="pb-0 pt-0 ">
                                  <p class="placeholder-glow ">
                                    <span class="placeholder col-12"></span>
                                  </p>
                                </td>
                                <td class="pb-0 pt-0 ">
                                  <p class="placeholder-glow ">
                                    <span class="placeholder col-12"></span>
                                  </p>
                                </td>
                                <td class="pb-0 pt-0 ">
                                  <p class="placeholder-glow ">
                                    <span class="placeholder col-12"></span>
                                  </p>
                                </td>
                                <td class="pb-0 pt-0 ">
                                  <p class="placeholder-glow ">
                                    <span class="placeholder col-12"></span>
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                
                `+



                '</div>'+
              
                '</div>'
            );
        },
        return_detailinfo(d){
            var return_string =''
            for (let key in d.info.fuel_usage_data){
                var tr = `

                <tr>
                              <td>
                                ${ d.info.fuel_usage_data[key][0] }
                                -
                                ${ d.info.fuel_usage_data[key][1] }
                              </td>
                              <td>
                                ${ key }
                              </td>
                              <td>
                                ${
                                  Object.keys(d.info.minutes).length > 0 ?
                                    d.info.minutes[key] :
                                    0
                                }
                              </td>
                              <td>
                                ${ key }*( ${
                                  Object.keys(d.info.minutes).length
                                  > 0 ?
                                    d.info.minutes[key] :
                                    0
                                }/60) =
                                ${
                                  Object.keys(d.info.usage).length > 0 ?
                                  d.info.usage[key] :
                                    '0.00'
                                }
                              </td>
                            </tr>
                `
                return_string = return_string + tr
            }
            return return_string;
        },
        //------------------------------------------------------------------



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            this.sailogger_table = null;
            this.sailoggergpio_table = null;
            this.sailoggerreboot_table = null;
            this.sailoggersuser_table = null;
            this.sailoggerstrack_table = null;
            this.is_rpmreport = null;
            this.item_data = null;
            this.reboot_item_data = null;
            this.reboot_data = null;
            this.interval_reboot = null;
            this.map_rpmdata = null;
            this.rpm_chart = null;
            this.rpm_chart_arr = [];
            this.search_sailogger = null;
            this.search_sailoggergpio = null;
            this.search_reboot = null;
            this.search_sailoggersuser = null;
            this.search_sailoggertrack = null;
            this.sailogger_data = null;
            this.gpio_data = null;
            this.last_center = [-1.4312313766684295, 119.1498789812993],
                this.last_zoom = 6;
            this.rpmreport_data = null;
            this.page_off = 10;
            this.page_off_gpio = 10;
            this.page_off_tracking = 10;
            this.page_off_reboot = 10;
            this.rpmshow_range = "Today";
            this.export_range = "today";
            this.rpmreport_id = null;
            this.loading_range = false;
            this.tracking_layer = L.layerGroup();
            this.target_layer = L.layerGroup();
            this.link_layer = L.layerGroup();
            this.search_layer = L.layerGroup();
            this.marker_tracking = [];
            this.moving_marker = null;
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            this.interval_tracking = null;
            this.sailogger_map = null;
            this.rpmMap = null;
            this.rpmMarker = null;
            this.sailogger_marker = null;
            this.date_range = null;
            this.isBottom = false;
            this.rpmshowrange_arr = [
                { label: "Today", val: "today" },
                { label: "Last 24 Hours", val: "24" },
                { label: "Last 7 Days", val: "7" },
                { label: "Last 30 Days", val: "30" },
                { label: "This Week", val: "week" },
                { label: "This Month", val: "month" },
                { label: "Last Month", val: "lmonth" },
                { label: "Custom Range", val: "24" },
            ];
            this.page_off_arr = [
                10,
                25,
                50,
                100
            ];

            this.page_offgpio_arr = [
                10,
                25,
                50,
                100
            ];

            this.page_offreboot_arr = [
                10,
                25,
                50,
                100
            ];

            this.th_sailogger_arr = [
                "No",
                "Vessel Name",
                "Company",
                "Group",
                "Device State",
                "Current GPIO Status",
                "Manage",
                ""
            ];
            this.th_sailogger_gpio_arr = [
                "No",
                "GPIO Type",
                "Status",
                "Group",
                "dateTime (GMT+7)"
            ];
            this.th_sailogger_track_arr = [
                "#",
                "Current\n(RPM)",
                "Average\n(RPM)",
                "Min\nRPM)",
                "Max\n(RPM)",
                "Interval\n(minutes)",
                "Uptime\n(minutes)",
                "Latitude, Longitude",
                "Weather Status",
                "Heading\n(Degree)",
                "Speed\n(Knots)",
                "Distance\n(Nmi)",
                "dateTime\n(GMT+7)",
                "Detail",
                ""
            ];

            this.th_sailogger_reboot_arr = [
                "No",
                "Content",
                "dateTime(GMT+7)",
            ];
            this.th_sailogger_gpio_arr = [
                "No",
                "Gpio Type",
                "Status",
                "dateTime(GMT+7)",
            ];
            this.td_button = [
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'button_report', b: 'btn-primary' },
                { a: 'button_show', b: 'btn-success' },
            ];
            this.td_sailogger_arr = [
                4,
                13.7,
                13.7,
                13.7,
                13.7,
                13.7,
                13.7,
                13.7,
            ];
            this.td_sailogger_track_arr = [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];
            this.td_sailogger_gpio_arr = [
                0,
                0,
                0,
                0,
            ];
        },

   
    }
})
