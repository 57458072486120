import { useNewsStore, useSailoggerStore} from '@/stores';
import RpmReport from '@/views/showonmap/RpmReportSailogger_view.vue';

export default {
  name: "Sailogger",
  components: {
    RpmReport
  },
  data() {
    return {
      sailogger_store: useSailoggerStore(),
      news_store:useNewsStore(),
      RpmReportComp : RpmReport,
    }
  },

  mounted() {
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};