import NavigationBar from "@/components/navbars/NavigationBar.vue";
import { mapGetters } from "vuex";
import { useAuthStore, useToastStore,useNotifStore, useNewsStore } from '@/stores';
import $ from 'jquery'


export default {
  name: "App",
  components: {
    NavigationBar,
  },
  data() {
    return {
      loadernavplus: require("@/assets/images/load100new.gif"),
      authStore: useAuthStore(),
      toastStore: useToastStore(),
      notification_store: useNotifStore(),
      news_store:useNewsStore(),
    }
  },
  methods: {

    closeToast() {
      $('#liveToast').hide()
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Some Default Title';
        const link = document.querySelector("[rel='icon']")
        link.setAttribute('href',to.meta.icon)
        console.log(to.meta.icon); 
      }
    },
  },
  computed: {
    ...mapGetters([
      "loading_map",
    ]),
    isServices() {
      return this.$route.meta.navbar;
    },
  }
};