import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";
import moment from 'moment';


export const useSnapshotStore = defineStore({
    id: 'snapshot',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        snapshot_table: null,
        snapshotuser_table: null,
        snapshottrack_table: null,
        is_showsnap: null,
        search_snapshot: null,
        search_snapshotuser: null,
        search_snapshottrack: null,
        search_snapshotimage: '',
        search_snapshotfolder: '',

        snapshot_folder_table: null,
        snapshot_images_table: null,
        snapshot_data: null,
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 6,
        notif_data: null,
        notif_id: null,
        mapsnapshot_data: null,
        mapsnapshot_data_date: null,
        page_off: 10,
        page_off_tracking: 10,
        page_off_folder: 10,
        page_off_images: 10,
        show_range: "Last 24 Hours",
        export_range: "24",
        mapsnapshot_id: null,
        showsnap_data: null,
        showsnap_item: null,
        showsnap_folder: null,
        showsnap_images: null,
        showsnap_image_detail: null,
        showsnap_ch: "kosong",
        loading_range: false,
        tracking_layer: L.layerGroup(),
        target_layer: L.layerGroup(),
        link_layer: L.layerGroup(),
        search_layer: L.layerGroup(),
        marker_tracking: [],
        moving_marker: null,
        tracking_path: null,
        tracking_latLng: null,
        tracking_rotation: null,
        interval_tracking: null,
        snapshot_map: null,
        date_range: null,
        isBottom: false,
        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        page_off_folder_arr: [
            10,
            25,
            50,
            100
        ],

        page_off_images_arr: [
            10,
            25,
            50,
            100
        ],

        th_snapshot_arr: [
            "No",
            "Vessel Name",
            "Company",
            "",
            "Show Snapshot",
        ],

        th_snapshot_folder_arr: [
            "",
        ],

        th_snapshot_images_arr: [
            "",
        ],
        th_snapshot_track_arr: [
            "#",
            "Latitude,Longitude",
            "DMS",
            "Speed\n(Knots)",
            "Heading\n(Degree)",
            "Distance\n(Nmi)",
            "Date/Time\n(UTC)",
            "Date/Time\n(GMT+7)"
        ],
        th_snapshot_user_arr: [
            "#",
            "Name",
            "Email",
            "Role",
            "Last Login"
        ],
        td_button: [
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'not', b: '' },
            { a: 'button', b: 'btn-secondary' },
            { a: 'button', b: 'btn-primary' },
        ],
        td_snapshot_arr: [
            4,
            28,
            28,
            20,
            20,
        ],
        td_snapshot_folder_arr: [
            0,
        ],

        td_snapshot_images_arr: [
            0,
        ],
        td_snapshot_track_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ],


    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Snapshot Data
        async fetch_snapshot() {
            await api.get(`/api/v1/navplus/snapshot`, {}
            ).then((res) => {
                console.log("snapshot RESPONSE");
                console.log(res);
                if (this.search_snapshot == null || this.search_snapshot.length == 0) {
                    this.snapshot_data = res.data.data.vessels;
                    this.initTable();
                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_notification_config(item) {
            this.notif_data = null
            this.notif_id = null
            await api.get("/api/v1/navplus/snapshotContact/" + item.id, {}
            ).then((res) => {
                console.log("SNAPSHOT CONFIG RESPONSE");
                console.log(res);

                this.notif_data = res.data.data
                this.notif_id = item.id
                if (res.data.data.email_enabled > 0) {
                    $('#notif_config_check').prop('checked', true);
                } else {
                    $('#notif_config_check').prop('checked', false);
                }

            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_show_snapshot(item) {
            // this.rpmreport_data = null;
            // this.rpmreport_id = item.id;


            this.showsnap_data = null
            console.log(item.id);
            await api.get(`/api/v1/navplus/snapshot/` + item.id, {
            }
            ).then((res) => {
                console.log("SHOW SNAPSHOT FETCH");
                console.log(res);
                this.showsnap_data = res.data.data
                this.showsnap_item = item
                this.showsnap_folder = res.data.data.dir_label[res.data.data.dir_label.length - 1]

                setTimeout(() => {
                    this.initTable_folder(res.data.data)
                    this.folder_click(this.showsnap_folder)

                    if (this.snapshot_folder_table) {
                        this.snapshotFolderTable_showof(10)
                    }

                    if (this.snapshot_images_table) {
                        this.snapshotImagesTable_showof(10)
                    }
                }, 1000);
                // this.rpmreport_data = res.data.data
                // this.moveTracking(res.data.data.tracking)
                // this.export_range = range
                // setTimeout(() => {
                //     this.initTable_tracking(res.data.data)
                // }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        async fetch_image_list(id, folder_name, channel, page, itemsperpage) {
            // this.rpmreport_data = null;
            // this.rpmreport_id = item.id;

            console.log(id);
            console.log(folder_name);
            console.log(channel);
            console.log(page);
            console.log(itemsperpage);

            this.showsnap_images = null




            await api.get("/api/v1/navplus/snapshot/" +
                id +
                "/" +
                folder_name +
                "/" +
                channel +
                "/" +
                page +
                "/" +
                itemsperpage, {}
            ).then((res) => {
                console.log("IMAGE LIST FETCH");
                console.log(res);
                this.showsnap_images = res.data.data
                this.initTable_image(res.data.data)
                // this.showsnap_data = res.data.data
                // this.rpmreport_data = res.data.data
                // this.moveTracking(res.data.data.tracking)
                // this.export_range = range
                // setTimeout(() => {
                //     this.initTable_tracking(res.data.data)
                // }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        // POST DATA FUNCTIONS
        //------------------------------------------------------------------     
        async update_notifalert() {
            console.log("NOTIFICATION ALERT CHANGE RESPONSE");

            var enabled_;
            if ($("#notif_config_check").prop('checked') == true) {
                enabled_ = 1;
            } else {
                enabled_ = 0;
            }

            console.log(" UPDATE NOTIF ALERT ")
            console.log(enabled_);
            console.log(this.notif_id);



            await api.post("/api/v1/navplus/snapshotContact/" + this.notif_id, {
                enabled: enabled_,
                emails: this.notif_data.emails,
            }

            ).then((res) => {
                console.log("VESSEL SPEED ALERT CHANGE RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#snapshotNotifModal_alert').removeClass('d-none')
                    $('#snapshotNotifModal_alert').addClass('d-block')
                    $('#snapshotNotifModal_alert').html('Successfully Updated Snapshot Notification Configuration!')

                    setTimeout(() => {
                        $('#snapshotNotifModal_alert').removeClass('d-block')
                        $('#snapshotNotifModal_alert').addClass('d-none')
                        $('#snapshotNotifModal_alert').html('')
                    }, 1500);

                } else {
                    $('#snapshotNotifModal_alert').removeClass('alert-success')
                    $('#snapshotNotifModal_alert').addClass('alert-danger')
                    $('#snapshotNotifModal_alert').removeClass('d-none')
                    $('#snapshotNotifModal_alert').addClass('d-block')
                    $('#snapshotNotifModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#snapshotNotifModal_alert').addClass('alert-success')
                        $('#snapshotNotifModal_alert').removeClass('alert-danger')
                        $('#snapshotNotifModal_alert').removeClass('d-block')
                        $('#snapshotNotifModal_alert').addClass('d-none')
                        $('#snapshotNotifModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#snapshotNotifModal_alert').removeClass('alert-success')
                $('#snapshotNotifModal_alert').addClass('alert-danger')
                $('#snapshotNotifModal_alert').removeClass('d-none')
                $('#snapshotNotifModal_alert').addClass('d-block')
                $('#snapshotNotifModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#snapshotNotifModal_alert').addClass('alert-success')
                    $('#snapshotNotifModal_alert').removeClass('alert-danger')
                    $('#snapshotNotifModal_alert').removeClass('d-block')
                    $('#snapshotNotifModal_alert').addClass('d-none')
                    $('#snapshotNotifModal_alert').html('')
                }, 1500);
            }).then(() => {
            });

        },
        //------------------------------------------------------------------ 



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search snapshot table
        snapshotTable_search() {
            if (this.search_snapshot != null && this.search_snapshot.length > 0) {
                this.snapshot_table.search(this.search_snapshot).draw();
            }
        },
        //search snapshot user table
        userTable_search() {
            this.snapshotuser_table.search(this.search_snapshotuser).draw();
        },
        //search snapshot tracking table
        trackTable_search() {
            this.snapshottrack_table.search(this.search_snapshottrack).draw();
        },
        imagesTable_search() {
            this.snapshot_images_table.search(this.search_snapshotimage).draw();
        },
        folderTable_search() {
            this.snapshot_folder_table.search(this.search_snapshotfolder).draw();
        },
        //data shorting snapshot table 
        snapshotTable_showof(page) {
            console.log("PAGE : " + page);
            this.snapshot_table.page.len(page).draw()
            this.page_off = page;
        },
        snapshotFolderTable_showof(page) {
            console.log("PAGE : " + page);
            this.snapshot_folder_table.page.len(page).draw()
            this.page_off_folder = page;
        },
        snapshotImagesTable_showof(page) {
            console.log("PAGE : " + page);
            this.snapshot_images_table.page.len(page).draw()
            this.page_off_images = page;
        },
        //data shorting snapshot table 
        snapshotTrackTable_showof(page) {
            console.log("PAGE : " + page);
            this.snapshottrack_table.page.len(page).draw()
            this.page_off_tracking = page;
        },
        //clear snapshot table
        clear_snapshotTable_search() {
            this.search_snapshot = ''
            this.snapshot_table.search(this.search_snapshot).draw();
        },
        //clear snapshot table
        clear_snapshotTableImages_search() {
            this.search_snapshotimage = ''
            this.snapshot_images_table.search(this.search_snapshotimage).draw();
        },
        //clear snapshot table
        clear_snapshotTableFolder_search() {
            this.search_snapshotfolder = ''
            this.snapshot_folder_table.search(this.search_snapshotfolder).draw();
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        initTable() {
            var root = this
            if (this.snapshot_table) {
                console.log("MASUK CLEAR TABLE");
                root.snapshot_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.snapshot_data);
                this.snapshot_table = new DataTable('#snapshotTable', {
                    data: this.snapshot_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "4%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "28%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data}
                            `+ (this.snapshot_data[meta.row].snap_status.toUpperCase() == 'INACTIVE' ? `<span class="badge font-small text-bg-danger">!</span>` : (this.snapshot_data[meta.row].snap_status.toUpperCase() != 'INACTIVE' && this.snapshot_data[meta.row].snap_status.toUpperCase() != 'ACTIVE') ? `<span class="badge font-small text-bg-secondary">${this.snapshot_data[meta.row].snap_status}</span>` : ``) + `
                            </div>
                            `
                            }
                        },
                        {
                            width: "28%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "20%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button id="snapshotnotif_btn" data-bs-toggle="modal" data-bs-target="#snapshotNotifModal"  type="button" class="btn btn-sm btn-secondary w-100 rounded-none"><i class="fas fa-wrench me-2"></i>Notification</button>
                              </div>
                              `
                            }
                        },
                        {
                            width: "20%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100">
                              <button id="snapshotshow_btn"  type="button" class="btn btn-sm btn-primary w-100 rounded-none">Show Snapshot</button>
                              </div>
                              `
                            }
                        },


                    ]
                });

                var mom = this;

                this.snapshot_table.on('click', '#snapshotshow_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.snapshot_table.row(tr);
                    console.log(row.data());
                    mom.show_snapshot(row.data())
                });

                this.snapshot_table.on('click', '#snapshotnotif_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.snapshot_table.row(tr);
                    console.log("NOTIF DI CLICK");
                    console.log(row.data());
                    mom.fetch_notification_config(row.data())
                });
            }
        },

        initTable_user() {
            var root = this
            console.log("INIT TABLE USER");
            console.log(this.snapshotuser_table);
            console.log("MASUK INIT TABLE USER");
            console.log(this.mapsnapshot_data.vesselUsers);
            this.snapshotuser_table = new DataTable('#snapshotTableUser', {
                data: this.mapsnapshot_data.vesselUsers,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 5,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "14.25%",
                        data: 'role_name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "34.25%",
                        data: 'lastLogin', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },

        initTable_tracking(data) {
            console.log("INIT TABLE TRACKING");
            console.log(this.snapshottrack_table);
            console.log("MASUK INIT TABLE TRACKING");
            console.log(data.tracking);
            this.snapshottrack_table = new DataTable('#snapshotTableTrack', {
                data: data.dataTable,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'latlong', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div onclick="popup_tracking('`+ meta.row + `')" class="p-0 h-100 link_tracking">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dms', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'speed', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'heading', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'distance', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'dateTime', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dateTime_local', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `  
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        
        initTable_folder(datafolder) {
            console.log("INIT TABLE FOLDER");
            console.log(this.snapshot_folder_table);
            console.log("MASUK INIT TABLE FOLDER");
            var mom = this;
            this.snapshot_folder_table = new DataTable('#snapshotTableFolder', {
                data: datafolder.dir_label,
                scrollCollapse: true,
                stateSave: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {

                            var dir_not_full = this.showsnap_data.dir_not_full[meta.row]
                            return `
                            <div class="p-1 btn h-100 d-flex align-items-center justify-content-center">
                            <div class="text-center">
                             <div id="folder-border-`+ datafolder.dir_label[meta.row] + `" class="card rounded-none " style="min-width:6em;min-height:6em;">
                             <div onclick="open_folder_snapshot('`+ row + `')" id="folder_btn" class="card-body  d-flex align-items-center justify-content-center">
                            <i id="folder-`+ datafolder.dir_label[meta.row] + `" class='bx bxs-folder d-block' style="font-size:4em"></i>
                            <i id="folder-open-`+ datafolder.dir_label[meta.row] + `" class='bx bxs-folder-open d-none text-primary' style="font-size:4em"></i>                
                            </div>  
                            <div id="folder-footer-`+ datafolder.dir_label[meta.row] + `" class="card-footer text-center rounded-none">
                            ${row}` + (dir_not_full ? `<i  class="fas fa-exclamation-circle ms-2 text-danger"></i>` : ``) + `
                            </div>
                            </div>
                            </div>
                            </div>
                            `
                        }


                    },
                ]
            });





        },



        initTable_image(dataimages) {
            console.log("INIT TABLE IMAGES");
            console.log("MASUK INIT TABLE IMAGES");
            var mom = this;
            var images = dataimages;
            this.snapshot_images_table = new DataTable('#snapshotTableImages', {
                data: dataimages,
                scrollCollapse: true,
                stateSave: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {


                            return `
                            <div id="image-detail" class="p-1 btn h-100 d-flex align-items-center justify-content-center">
                            <div class="text-start">
                             <div  class="card rounded-none " style="min-width:6em;min-height:6em;">
                             <div data-bs-toggle="modal" data-bs-target="#modalimage_snapshot"   class="card-body  d-flex align-items-center justify-content-center">
                              <img src="${images[meta.row].src}" class="img-thumbnail snapshot_img" alt="...">
                            </div>  
                            <div  class="card-footer text-start rounded-none">
                            ${images[meta.row].label}<br/>
                            <span class="badge text-bg-success rounded-none">${images[meta.row].chLabel}</span>
                            </div>
                            </div>
                            </div>
                            </div>
                            `
                        }


                    },
                ]
            });

            var mom = this;
            this.snapshot_images_table.on('click', '#image-detail', function (e) {
                let tr = e.target.closest('tr');
                let row = mom.snapshot_images_table.row(tr);
                console.log(row.data());
                mom.showsnap_image_detail = row.data()
            });



        },
        //------------------------------------------------------------------



        //SHOW SNAPSHOT FUNCTIONS
        //------------------------------------------------------------------
        show_snapshot(item) {
            console.log("SHOW SNAPSHOT");
            this.is_showsnap = false;
            this.search_snapshotimage = null;
            this.search_snapshotfolder = null;
            this.showsnap_images = null;
            this.showsnap_folder = null;
            this.showsnap_ch = "kosong",
                $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                this.is_showsnap = true;
                this.fetch_show_snapshot(item);
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#showsnapshot_card").offset().top) - 68
                    }, 500);
                }, 10);

            }, 5);
        },
        close_show_snapshot() {
            this.is_showsnap = false;
            $('html, body').animate({ scrollTop: '0px' }, 500);
        },
        //------------------------------------------------------------------


        //ONCLICK FUNCTIONS
        //------------------------------------------------------------------
        filter_click(ch) {
            console.log("FILTER CLICK");
            this.showsnap_ch = ch;
            console.log(ch);
            this.fetch_image_list(this.showsnap_item.id, this.showsnap_folder, this.showsnap_ch, 1, 10);
        },
        folder_click(dir_label) {
            if (this.snapshot_images_table) {
                this.clear_snapshotTableImages_search();
            }

            if (this.snapshot_folder_table) {
                this.clear_snapshotTableFolder_search();
            }
            this.showsnap_folder = dir_label
            setTimeout(() => {
                $('#snapshotTableFolder').dataTable().fnDestroy()
                this.initTable_folder(this.showsnap_data)
                console.log(dir_label)
                console.log(this.showsnap_folder)




                $('#folder-' + this.showsnap_folder).removeClass('d-block');
                $('#folder-' + this.showsnap_folder).addClass('d-none');
                $('#folder-open-' + this.showsnap_folder).removeClass('d-none');
                $('#folder-open-' + this.showsnap_folder).addClass('d-block');
                $('#folder-footer-' + this.showsnap_folder).addClass('bg-primary text-white');
                $('#folder-border-' + this.showsnap_folder).addClass('border-primary');


                this.fetch_image_list(this.showsnap_item.id, dir_label, this.showsnap_ch, 1, 10);

                // setTimeout(() => {
                //     $('html, body').animate({ scrollTop: '0px' }, 5);
                //     setTimeout(() => {
                //         $('html, body').animate({
                //             scrollTop: ($("#showsnapshot_card").offset().top) - 68
                //         }, 50);
                //     }, 50);
                // }, 500);

            }, 500);

        },
        //------------------------------------------------------------------



        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        //Uninit Data 
        uninit_data() {
            this.snapshot_table=null,
            this.snapshotuser_table=null,
            this.snapshottrack_table=null,
            this.is_showsnap=null,
            this.search_snapshot=null,
            this.search_snapshotuser=null,
            this.search_snapshottrack=null,
            this.search_snapshotimage='',
            this.search_snapshotfolder='',
    
            this.snapshot_folder_table=null,
            this.snapshot_images_table=null,
            this.snapshot_data=null,
            this.last_center=[-1.4312313766684295, 119.1498789812993],
            this.last_zoom=6,
            this.notif_data=null,
            this.notif_id=null,
            this.mapsnapshot_data=null,
            this.mapsnapshot_data_date=null,
            this.page_off=10,
            this.page_off_tracking=10,
            this.page_off_folder=10,
            this.page_off_images=10,
            this.show_range="Last 24 Hours",
            this.export_range="24",
            this.mapsnapshot_id=null,
            this.showsnap_data=null,
            this.showsnap_item=null,
            this.showsnap_folder=null,
            this.showsnap_images=null,
            this.showsnap_image_detail=null,
            this.showsnap_ch="kosong",
            this.loading_range=false,
            this.tracking_layer=L.layerGroup(),
            this.target_layer=L.layerGroup(),
            this.link_layer=L.layerGroup(),
            this.search_layer=L.layerGroup(),
            this.marker_tracking=[],
            this.moving_marker=null,
            this.tracking_path=null,
            this.tracking_latLng=null,
            this.tracking_rotation=null,
            this.interval_tracking=null,
            this.snapshot_map=null,
            this.date_range=null,
            this.isBottom=false,
            this.range_arr=[
                { label: "Today", val: "today" },
                { label: "Last 24 Hours", val: "24" },
                { label: "Last 7 Days", val: "7" },
                { label: "Last 30 Days", val: "30" },
                { label: "This Week", val: "week" },
                { label: "This Month", val: "month" },
                { label: "Last Month", val: "lmonth" },
                { label: "Custom Range", val: "24" },
            ];
            this.page_off_arr= [
                10,
                25,
                50,
                100
            ];
    
            this.page_off_folder_arr= [
                10,
                25,
                50,
                100
            ];
    
            this.page_off_images_arr= [
                10,
                25,
                50,
                100
            ];
    
            this.th_snapshot_arr= [
                "No",
                "Vessel Name",
                "Company",
                "",
                "Show Snapshot",
            ];
    
            this.th_snapshot_folder_arr= [
                "",
            ];
    
            this.th_snapshot_images_arr= [
                "",
            ];
            this.th_snapshot_track_arr= [
                "#",
                "Latitude,Longitude",
                "DMS",
                "Speed\n(Knots)",
                "Heading\n(Degree)",
                "Distance\n(Nmi)",
                "Date/Time\n(UTC)",
                "Date/Time\n(GMT+7)"
            ];
            this.th_snapshot_user_arr= [
                "#",
                "Name",
                "Email",
                "Role",
                "Last Login"
            ];
            this.button= [
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'not', b: '' },
                { a: 'button', b: 'btn-secondary' },
                { a: 'button', b: 'btn-primary' },
            ];
            this.snapshot_arr= [
                4,
                28,
                28,
                20,
                20,
            ];
            this.snapshot_folder_arr= [
                0,
            ];
    
            this.snapshot_images_arr= [
                0,
            ];
            this.snapshot_track_arr= [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];
        }

    }
})
