import { useSailinkStore, useEtaStore, useNotifStore } from '@/stores';
import MapSailink from '@/views/showonmap/MapSailink_view.vue';
import $ from "jquery";

export default {
  name: "Sailink",
  components: {
    MapSailink,
  },
  data() {
    return {
      sailink_store: useSailinkStore(),
      notification_store:useNotifStore(),
      eta_store: useEtaStore(),
      MapSailinkComp: MapSailink,
      map: null,
      marker: null,
    }
  },

  mounted() {
    var mother = this
    window.showonmap_sailink = function (id) {
      mother.sailink_store.showonMap(id);
    }

    window.sailink_mrtg_click = function (vessel_id, id) {
      mother.sailink_store.mrtg_click(vessel_id, id);
    }

    

    // $(window).scroll(function () {
    //   console.log("IS BOTTOM SCROLL")
    //   if ($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
    //     mother.sailink_store.isBottom = true
    //   } else {
    //     mother.sailink_store.isBottom = false
    //   }
    // });
  },

  methods: {
  },

  watch: {
  },
  computed: {
  }
};