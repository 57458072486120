import { useAuthStore } from '@/stores';
import { useNotifStore } from '@/stores/modules/notification.store';
export default {
  components: {
  },
  data() {
    return {
      authStore: useAuthStore(),
      notification_store: useNotifStore(),
      version : "3.0.7",
    };
  },
  methods:{

  },
  computed: {
    returnPath() {
      return this.$route.path
    },


  },
};