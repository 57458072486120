import { defineStore } from 'pinia';
import { useEtaStore, useCustomRangeStore, useAuthStore, useNotifStore, useNewsStore } from '../index';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import L from "leaflet";
import Chart from 'chart.js/auto';


export const useIridiumStore = defineStore({
    id: 'iridium',
    state: () => ({
        eta_store: useEtaStore(),
        range_store: useCustomRangeStore(),
        authStore: useAuthStore(),
        notification_store: useNotifStore(),
        news_store: useNewsStore(),
        iridium_table: null,
        iridiumuser_table: null,
        iridiumtrack_table: null,
        is_showonmap: null,
        search_iridium: null,
        search_iridiumuser: null,
        search_iridiumtrack: null,
        iridium_data: null,
        iridium_isAdmin: false,
        last_center: [-1.4312313766684295, 119.1498789812993],
        last_zoom: 6,
        mapiridium_data: null,
        mapiridium_data_date: null,
        page_off: 10,
        page_off_tracking: 10,
        show_range: "Last 24 Hours",
        export_range: "24",
        mapiridium_id: null,
        loading_range: false,
        tracking_layer: L.layerGroup(),
        target_layer: L.layerGroup(),
        link_layer: L.layerGroup(),
        search_layer: L.layerGroup(),
        marker_tracking: [],
        moving_marker: null,
        tracking_path: null,
        tracking_latLng: null,
        tracking_rotation: null,
        interval_tracking: null,
        iridium_map: null,
        date_range: null,
        isBottom: false,
        skychat_icon: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNDQuMjkgMjM4LjM2Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9IkxheWVyXzIiIGRhdGEtbmFtZT0iTGF5ZXIgMiI+PGcgaWQ9IkxheWVyXzEtMiIgZGF0YS1uYW1lPSJMYXllciAxIj48cGF0aCBjbGFzcz0iY2xzLTEiIGQ9Ik0yMTEuNDgsNjUuOTJIMTkzLjExVjU0LjY1YTI2LjkzLDI2LjkzLDAsMCwwLTI2LjkzLTI2LjkySDU4LjQyTDI4LjgxLDBsLjI3LDI3LjczSDI2LjkyQTI2LjkyLDI2LjkyLDAsMCwwLDAsNTQuNjVWMTI5LjJhMjYuOTIsMjYuOTIsMCwwLDAsMjYuOTIsMjYuOTJINDYuMjR2MTMuNWEzMi44NSwzMi44NSwwLDAsMCwzMi44MSwzMi44MWg5OS42M0wyMTcsMjM4LjM2LDIxNi43LDIwMmEzMi44NSwzMi44NSwwLDAsMCwyNy41OS0zMi4zOVY5OC43M0EzMi44NSwzMi44NSwwLDAsMCwyMTEuNDgsNjUuOTJaTTEwMCw0NS43OXY1NC42N0w0Ni4yOCw5NFpNNDYuMTQsMTI3LjMzbC00LjU0LTExLjVMMTQ1LjI2LDk0LjA2bC0xOC43OSwzMy4yN1ptMTgzLjc0LDQyLjI5YTE4LjQyLDE4LjQyLDAsMCwxLTE4LjQsMTguNGgtOS4zMmwuMTYsMTYuODFMMTg0LjM4LDE4OEg3OS4wNWExOC40MiwxOC40MiwwLDAsMS0xOC40MS0xOC40di0xMy41SDE2Ni4xOGEyNi45MywyNi45MywwLDAsMCwyNi45My0yNi45MlY4MC4zM2gxOC4zN2ExOC40MiwxOC40MiwwLDAsMSwxOC40LDE4LjRaIi8+PC9nPjwvZz48L3N2Zz4=",
        range_arr: [
            { label: "Today", val: "today" },
            { label: "Last 24 Hours", val: "24" },
            { label: "Last 7 Days", val: "7" },
            { label: "Last 30 Days", val: "30" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],
        page_off_arr: [
            10,
            25,
            50,
            100
        ],

        th_iridium_arr: [
            "No",
            "Vessel Name",
            "Company",
            "Group",
            "IMEI",
            "Rateplan",
            "",
            "",
            ""
        ],

        th_iridium_track_arr: [
            "#",
            "Latitude,Longitude",
            "DMS",
            "Speed\n(Knots)",
            "Heading\n(Degree)",
            "Distance\n(Nmi)",
            "Date/Time\n(UTC)",
            "Date/Time\n(GMT+7)"
        ],
        th_iridium_user_arr: [
            "#",
            "Name",
            "Email",
            "Role",
            "Last Login"
        ],
        td_iridium_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            8.5,
            7.5,
            10.5
        ],
        td_iridium_track_arr: [
            0,
            0,
            0,
            0,
            0,
            0,
            0,
            0,
        ],

        th_iridium_skychat_arr: [
            "Name",
            "Email",
            "Username",
            "Role",
            "Enable",
        ],
        td_iridium_skychat_arr: [
            20,
            20,
            20,
            20,
            20,
        ],

        chatroom_data: null,
        chatroom_from: null,
        chatroom_to: null,
        chatroom_body: '',
        chatroom_sending: false,
        chatroom_messages: null,
        chatroom_thurayanumber: null,
        chatroom_item: null,
        chatroom_interval: null,
        chatroom_search: '',

        skychat_user: null,
        skychat_user_item: null,
        skychat_user_table: null,
        skychat_user_checked: [],
        skychat_user_unchecked: [],
        skychat_user_post: null,
        skychat_user_loading: false,

        skychat_user_role_arr: [
            {
                label: 'Full Privilege',
                id: 1,
            },
            {
                label: 'Read Write Chatboard',
                id: 2,
            },
            {
                label: 'Readonly',
                id: 3,
            }
        ],
        idxs: 0,
        speedalert_data: null,
        speedhistory_data: null,
        speedhistory_item: null,
        speedhistory_table: null,
        th_speedhistory_arr: [
            "",
            "",
            "",
        ],
        th_speedhistory_plc: [
            "",
            "",
            "",
        ],
        td_speedhistory_arr: [
            5,
            5,
            90,
        ],
        speedhistory_page_off: 10,
        search_speedhistory: '',
        speedhistory_page_off_arr: [
            10,
            25,
            50,
            100
        ],
        speedhistory_notifarr: [],
        ir_gpsemail_data: null,
        ir_gpsemail_loading: false,
        ir_gpsemail_item: null,

        cdrrange_arr: [
            { label: "Today", val: "today" },
            { label: "This Week", val: "week" },
            { label: "This Month", val: "month" },
            { label: "Last Month", val: "lmonth" },
            { label: "Custom Range", val: "24" },
        ],

        cdr_data: null,
        cdr_item: null,
        cdr_range: 'month',
        cdr_rangelabel: 'This Month',
        cdr_chart: null,
        th_cdr_skychat_arr: [
            "Service",
            "DateTime(UTC)",
            "DateTime(GMT+7)",
        ],
        td_cdr_skychat_arr: [
            0,
            0,
            0,
        ],
        th_cdr_tracking_arr: [
            "Service",
            "DateTime(UTC)",
            "DateTime(GMT+7)",
        ],
        td_cdr_tracking_arr: [
            0,
            0,
            0,
        ],
        pageoff_cdr_skychat: 10,
        pageoff_cdr_tracking: 10,
        search_cdr_skychat: '',
        search_cdr_tracking: '',
        iridiumSkychatCDR_table: null,
        iridiumTrackingCDR_table: null,
        pageoff_cdr_skychat_arr: [
            10,
            25,
            50,
            100
        ],
        pageoff_cdr_tracking_arr: [
            10,
            25,
            50,
            100
        ],


        cdradmin_data: null,
        cdradmin_item: null,
        cdradmin_range: 'month',
        cdradmin_rangelabel: 'This Month',
        cdradmin_chart1: null,
        cdradmin_chart2: null,
        th_cdradmin_iridium_arr: [
            "DateTime Local",
            "Volume",
            "Called Number",
            "Charge",
        ],
        td_cdradmin_iridium_arr: [
            0,
            0,
            0,
            0,
        ],
        th_cdradmin_skyreach_arr: [
            "DateTime Local",
            "DateTime Session",
            "Volume",
            "Content",
            "Destination"
        ],
        td_cdradmin_skyreach_arr: [
            0,
            0,
            0,
            0,
            0,
        ],
        pageoff_cdradmin_iridium: 10,
        pageoff_cdradmin_skyreach: 10,
        search_cdradmin_iridium: '',
        search_cdradmin_skyreach: '',
        iridiumIridiumCDRAdmin_table: null,
        iridiumSkyreachCDRAdmin_table: null,
        pageoff_cdradmin_iridium_arr: [
            10,
            25,
            50,
            100
        ],
        pageoff_cdradmin_skyreach_arr: [
            10,
            25,
            50,
            100
        ],

        reboot_item: null,
        reboot_data: null,
        page_off_reboot: 10,
        page_offreboot_arr: [
            10,
            25,
            50,
            100
        ],

        th_iridium_reboot_arr: [
            "No",
            "Content",
            "dateTime(GMT+7)",
        ],
        iridiumreboot_table: null,
        search_reboot: '',


        interval_item: null,
        interval_data: null,
        interval_input: 1,
        page_off_interval: 10,
        page_offinterval_arr: [
            10,
            25,
            50,
            100
        ],

        th_iridium_interval_arr: [
            "No",
            "Content",
            "dateTime(GMT+7)",
        ],
        iridiuminterval_table: null,
        search_interval: '',


        devid_item: null,
        devid_data: null,
        devid_input: 1,
        page_off_devid: 10,
        page_offdevid_arr: [
            10,
            25,
            50,
            100
        ],

        th_iridium_devid_arr: [
            "No",
            "Content",
            "dateTime(GMT+7)",
        ],
        iridiumdevid_table: null,
        search_devid: '',

        iots_item: null,
        iots_data: null,
        iots_input: '1 RPM',
        page_off_iots: 10,
        page_offiots_arr: [
            10,
            25,
            50,
            100
        ],
        th_iridium_iots_arr: [
            "No",
            "Content",
            "dateTime(GMT+7)",
        ],
        iridiumiots_table: null,
        search_iots: '',
        iots_arr: [
            '1 RPM',
            '2 RPM',
            '2 RPM 2 EXHAUST',
            'GPS Only',
            'GPS w/o SkyChat'
        ],

        gps_item: null,
        gps_data: null,
        gps_input: '1 RPM',
        page_off_gps: 10,
        page_offgps_arr: [
            10,
            25,
            50,
            100
        ],
        th_iridium_gps_arr: [
            "No",
            "Content",
            "dateTime(GMT+7)",
        ],
        iridiumgps_table: null,
        search_gps: '',
        gps_arr: [
            'USB',
            'S3',
            'S5',
        ],

        devdate_item: null,
        devdate_data: null,
        devdate_input: '',
        page_off_devdate: 10,
        page_offdevdate_arr: [
            10,
            25,
            50,
            100
        ],
        th_iridium_devdate_arr: [
            "No",
            "Content",
            "dateTime(GMT+7)",
        ],
        iridiumdevdate_table: null,
        search_devdate: '',
        devdate_arr: [
            'USB',
            'S3',
            'S5',
        ]


    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch Iridium Data
        async fetch_iridium() {
            await api.get(`/api/v1/navplus/iridium`, {}
            ).then((res) => {
                console.log("IRIDIUM RESPONSE");
                console.log(res);
                if (this.search_iridium == null || this.search_iridium.length == 0) {
                    this.iridium_data = res.data.data.vessels;
                    this.iridium_isAdmin = res.data.data.isAdmin;
                    if(!this.iridium_table){
                    this.initTable();
                    }
                }


            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Iridium Show on Map
        async fetch_IridiumMap(id) {
            this.mapiridium_data = null;
            this.mapiridium_id = id;
            await api.get(`/api/v1/navplus/iridium/` + id, {}
            ).then((res) => {
                console.log("SHOW ON MAP IRIDIUM FETCH");
                console.log(res);
                this.mapiridium_data = res.data.data
                setTimeout(() => {
                    this.initTable_user()
                    this.initTable_tracking(res.data.data)
                }, 1000);

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Iridium Show on Map Date
        async fetch_IridiumMap_date(range) {
            this.last_center = [-1.4312313766684295, 119.1498789812993]
            this.loading_range = true
            this.mapiridium_data_date = null
            this.export_range = range.toLowerCase()
            await api.post(`/api/v1/navplus/iridium/date
            `, {
                id: this.mapiridium_id,
                range: range.toLowerCase()
            }
            ).then((res) => {
                console.log("SHOW ON MAP IRIDIUM FETCH DATE");
                console.log(res);
                this.loading_range = false
                this.mapiridium_data_date = res.data.data
                this.moveTracking(res.data.data.tracking)
                this.initTable_tracking(res.data.data)
                console.log("TABLE TRACKING ISINYA");
                if (res.data.data.maptop != null) {
                    this.last_center = [res.data.data.maptop.lat, res.data.data.maptop.lon]
                    this.iridium_map.setView(new L.LatLng(res.data.data.maptop.lat, res.data.data.maptop.lon), 6);
                } else {
                    this.last_center = [-1.4312313766684295, 119.1498789812993]
                    this.iridium_map.setView(new L.LatLng(-1.4312313766684295, 119.1498789812993), 2);
                }

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Chatroom
        async fetch_chatroom(item, isonsend) {

            if (!isonsend) {
                this.chatroom_data = null
                this.chatroom_from = null
                this.chatroom_to = null
                this.chatroom_body = ''
                this.chatroom_messages = null
                this.chatroom_item = null
            }

            // this.chatroom_thurayanumber = null

            await api.get(`api/v1/navplus/skychat/cr/fetch/` + item.id, {}
            ).then((res) => {
                console.log("FETCH SKYCHAT RESPONSE");
                console.log(res);
                // console.log(this.authStore.user.user);

                // this.chatroom_id = item.id
                console.log(this.chatroom_data)
                this.chatroom_data = res.data.data
                this.chatroom_messages = res.data.data.messages
                this.chatroom_from = this.authStore.user.user.username
                this.chatroom_to = res.data.data.name
                this.chatroom_item = item
                this.chatroom_body = ''
                this.chatroom_sending = false

                var mom = this
                // this.chatroom_interval = setInterval(
                //     function () {
                //         if (mom.chatroom_data) {
                //             mom.gradual_chatroom()
                //         } else {
                //             clearInterval(mom.chatroom_interval);
                //         }
                //     }
                //     , 1000);


                setTimeout(() => {
                    var objDiv = document.getElementById("ir_chatroom_content");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }, 500);

            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Skychat User Setting
        async fetch_skychat_user_setting(item, isFirst) {
            if (isFirst) {
                this.skychat_user_checked = []
                this.skychat_user_unchecked = []
                this.skychat_user = null
                this.skychat_user_loading = false
                this.idxs = 0
            }


            if (this.skychat_user_table) {
                this.skychat_user_table.clear().draw();
            }

            await api.get(`api/v1/navplus/skychat/fetch/` + item.id, {}
            ).then((res) => {
                console.log("FETCH SKYCHAT USER SETTING RESPONSE");
                console.log(res);
                var mom = this;
                this.skychat_user = res.data.data
                this.skychat_user_post = res.data.data.vesselUsers
                mom.initTable_skychat_user(res.data.data)
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Speed Alert
        async fetch_speedalert(item) {
            console.log("SPEED ALERT DI CLICK");
            
            this.speedalert_data = null

            await api.get(`/api/v1/navplus/speed/` + item.id, {}
            ).then((res) => {
                console.log("SPEED ALERT RESPONSE");
                console.log(res);

                this.speedalert_data = {
                    emails: '',
                    id: 0,
                    isEnabled: 0,
                    max: 0,
                    min: 0,
                    source: '',
                    vessel_pt: ''
                }

                this.speedalert_data.emails = res.data.data.emails
                this.speedalert_data.id = res.data.data.id
                this.speedalert_data.isEnabled = res.data.data.isEnabled
                this.speedalert_data.max = parseInt(res.data.data.max)
                this.speedalert_data.min = parseInt(res.data.data.min)
                this.speedalert_data.source = res.data.data.source
                this.speedalert_data.vessel_pt = res.data.data.vessel_pt


                if (this.speedalert_data.isEnabled > 0 || this.speedalert_data.isEnabled != null) {
                    $("#speedalert_enable_yes").prop('checked', true);
                    $("#speedalert_enable_no").prop('checked', false);
                } else {
                    $("#speedalert_enable_yes").prop('checked', false);
                    $("#speedalert_enable_no").prop('checked', true);
                }
            }).catch((err) => {

            }).then(() => {
            });

        },
        //Fetch Speed History
        async fetch_speedhistory(item) {
            await api.get(`/api/v1/navplus/notification/get/speed/` + item.id, {}
            ).then((res) => {
                console.log("SPEED HISTORY RESPONSE");
                console.log(res);
                if (this.speedhistory_data == null) {
                    this.speedhistory_data = res.data.data
                    if (this.speedhistory_table != null) {
                        this.speedhistory_table.clear();
                        this.speedhistory_table = null;
                        console.log("HISTORY TABLE IS CLEARED");

                    }
                    setTimeout(() => {
                        this.initTable_history(this.speedhistory_data.notifications)
                    }, 5000);
                }

            }).catch((err) => {

            }).then(() => {
            });

        },
        //Fetch GPS Email Config
        async fetch_gpsemail(item) {
            this.ir_gpsemail_data = null

            await api.get("api/v1/navplus/iridium/email/" + item.id, {}
            ).then((res) => {
                console.log("FETCH GPS EMAIL CONFIG RESPONSE");
                console.log(res);
                this.ir_gpsemail_data = res.data.data
                setTimeout(() => {
                    if (res.data.data.isEnabled
                    ) {
                        $('#ir_gpmsmailEnabled1').prop('checked', true);
                        $('#ir_gpmsmailEnabled2').prop('checked', false);
                    } else {
                        $('#ir_gpmsmailEnabled1').prop('checked', false);
                        $('#ir_gpmsmailEnabled2').prop('checked', true);
                    }
                }, 500);
            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch CDR
        async fetch_cdr(item) {
            this.cdr_data = null
            this.pageoff_cdr_skychat = 10;
            this.pageoff_cdr_tracking = 10;
            this.search_cdr_skychat = ''
            this.search_cdr_tracking = ''

            if (this.cdr_chart) {
                this.cdr_chart.destroy()
            }

            if (this.iridiumSkychatCDR_table) {
                this.iridiumSkychatCDR_table.clear().draw();
                $('#iridiumSkychatTableCDR').DataTable().destroy();
                this.iridiumSkychatCDR_table = null
            }

            if (this.iridiumTrackingCDR_table) {
                this.iridiumTrackingCDR_table.clear().draw();
                $('#iridiumTrackingTableCDR').DataTable().destroy();
                this.iridiumTrackingCDR_table = null
            }

            await api.post(`/api/v1/navplus/iridium/cdr_summary/` + item.id, {
                range: this.cdr_range,
            }
            ).then((res) => {

                console.log("FETCH CDR RESPONSE");
                console.log(res);
                this.cdr_data = res.data.data

                this.initTable_cdr_skychat(res.data.data.output.skychat)
                this.initTable_cdr_track(res.data.data.output.track)



                var label_ = [];
                var skychat_ = [];
                var track_ = [];
                var total_ = [];


                if (res.data.data.chart) {
                    label_ = res.data.data.chart.labels
                    skychat_ = res.data.data.chart.count.skychat
                    track_ = res.data.data.chart.count.track
                    total_ = res.data.data.chart.total
                }

                this.cdr_chart = new Chart(
                    document.getElementById('iridium_cdr_chart'),
                    {
                        type: 'bar',
                        data: {
                            labels: label_,
                            datasets: [
                                {
                                    label: 'TRACKING/IOT Count Dialy',
                                    backgroundColor: '#F49223',
                                    data: track_,
                                },
                                {
                                    label: 'SKYCHAT Count Dialy',
                                    backgroundColor: '#37EB34',
                                    data: skychat_,
                                },
                                {
                                    label: 'Total Usage Up to Date',
                                    backgroundColor: '#325d9e',
                                    data: total_,
                                }
                            ]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: "top",
                                },
                                title: {
                                    display: false,
                                    text: 'Chart.js Bar Chart - Stacked'
                                },
                                tooltip: {
                                    callbacks: {
                                        footer: function (items) {
                                            return 'Total Balanced Usage : ' + parseFloat(items.reduce((a, b) => a + b.parsed.y, 0)).toFixed(2) + ' Unit'
                                        },
                                        label: function (items) {
                                            // console.log(items);
                                            return items.dataset.label + ' : ' + parseFloat(items.parsed.y).toFixed(2) + ' Unit';
                                        }
                                    }
                                },
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true,
                                },
                            },
                            interaction: {
                                mode: 'x',
                                intersect: false,
                            },
                        }
                    }
                );

            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch CDR
        async fetch_cdradmin(item) {
            this.cdradmin_data = null
            this.pageoff_cdradmin_iridium = 10;
            this.pageoff_cdradmin_skyreach = 10;
            this.search_cdradmin_iridium = ''
            this.search_cdradmin_skyreach = ''

            if (this.cdradmin_chart1) {
                this.cdradmin_chart1.destroy()
            }

            if (this.cdradmin_chart2) {
                this.cdradmin_chart2.destroy()
            }


            if (this.iridiumIridiumCDRAdmin_table) {
                this.iridiumIridiumCDRAdmin_table.clear().draw();
                $('#iridiumIridiumTableCDR').DataTable().destroy();
                this.iridiumIridiumCDRAdmin_table = null
            }

            if (this.iridiumSkyreachCDRAdmin_table) {
                this.iridiumSkyreachCDRAdmin_table.clear().draw();
                $('#iridiumSkyreachTableCDR').DataTable().destroy();
                this.iridiumSkyreachCDRAdmin_table = null
            }

            await api.post(`api/v1/navplus/iridium/cdr/` + item.id, {
                range: this.cdradmin_range,
            }
            ).then((res) => {

                console.log("FETCH CDR RESPONSE");
                console.log(res);
                this.cdradmin_data = res.data.data

                this.initTable_cdradmin_iridium(res.data.data.outputOri)
                this.initTable_cdradmin_skyreach(res.data.data.output)


                var amount_ori = [];
                var labels_ori = [];
                var total_ori = [];

                var amount_ = [];
                var labels_ = [];
                var total_ = [];



                if (res.data.data.chartOri) {
                    labels_ori = res.data.data.chartOri.labels
                    amount_ori = res.data.data.chartOri.amount
                    total_ori = res.data.data.chartOri.total
                }

                if (res.data.data.chart) {
                    labels_ = res.data.data.chart.labels
                    amount_ = res.data.data.chart.amount
                    total_ = res.data.data.chart.total
                }


                this.cdradmin_chart1 = new Chart(
                    document.getElementById('iridium_cdradmin_chart1'),
                    {
                        type: 'bar',
                        data: {
                            labels: labels_ori,
                            datasets: [
                                {
                                    label: 'Usage By Day',
                                    backgroundColor: '#F49223',
                                    data: amount_ori,
                                },
                                {
                                    label: 'Total Usage',
                                    backgroundColor: '#325d9e',
                                    data: total_ori,
                                }
                            ]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: "top",
                                },
                                title: {
                                    display: false,
                                    text: 'Chart.js Bar Chart - Stacked'
                                },
                                tooltip: {
                                    callbacks: {
                                        footer: function (items) {
                                            return 'Total Balanced Usage : ' + parseFloat(items.reduce((a, b) => a + b.parsed.y, 0)).toFixed(2) + ' Unit'
                                        },
                                        label: function (items) {
                                            // console.log(items);
                                            return items.dataset.label + ' : ' + parseFloat(items.parsed.y).toFixed(2) + ' Unit';
                                        }
                                    }
                                },
                                title: {
                                    display: true,
                                    text: 'CDR Source (Iridium)'
                                }
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true,
                                },
                            },
                            interaction: {
                                mode: 'x',
                                intersect: false,
                            },
                        }
                    }
                );


                this.cdradmin_chart2 = new Chart(
                    document.getElementById('iridium_cdradmin_chart2'),
                    {
                        type: 'bar',
                        data: {
                            labels: labels_,
                            datasets: [
                                {
                                    label: 'Usage By Day',
                                    backgroundColor: '#F49223',
                                    data: amount_,
                                },
                                {
                                    label: 'Total Usage',
                                    backgroundColor: '#325d9e',
                                    data: total_,
                                }
                            ]
                        },
                        options: {
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    position: "top",
                                },
                                title: {
                                    display: false,
                                    text: 'Chart.js Bar Chart - Stacked'
                                },
                                tooltip: {
                                    callbacks: {
                                        footer: function (items) {
                                            return 'Total Balanced Usage : ' + parseFloat(items.reduce((a, b) => a + b.parsed.y, 0)).toFixed(2) + ' Unit'
                                        },
                                        label: function (items) {
                                            // console.log(items);
                                            return items.dataset.label + ' : ' + parseFloat(items.parsed.y).toFixed(2) + ' Unit';
                                        }
                                    }
                                },
                                title: {
                                    display: true,
                                    text: 'CDR'
                                }
                            },
                            scales: {
                                x: {
                                    stacked: true,
                                },
                                y: {
                                    stacked: true,
                                },
                            },
                            interaction: {
                                mode: 'x',
                                intersect: false,
                            },
                        }
                    }
                );

            }).catch((err) => {

            }).then(() => {
            });
        },
        //Fetch Reboot Sailogger
        async fetch_reboot_sailogger(item) {
            this.reboot_data = null
            await api.get(`api/v1/navplus/sailogger/ota/` + item.id + `/reboot-sailogger`, {}
            ).then((res) => {
                console.log("FETCH REBOOT RESPONSE");
                console.log(res);
                this.reboot_data = res.data.data
                this.initTable_reboot(res.data.data)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Interval Sailogger
        async fetch_interval_sailogger(item) {
            this.interval_data = null
            await api.get(`api/v1/navplus/sailogger/ota/` + item.id + `/interval-sailogger`, {}
            ).then((res) => {
                console.log("FETCH INTERVAL RESPONSE");
                console.log(res);
                this.interval_data = res.data.data
                this.initTable_interval(res.data.data)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Dev ID Sailogger
        async fetch_devid_sailogger(item) {
            this.devid_data = null
            await api.get(`api/v1/navplus/sailogger/ota/` + item.id + `/dev_id-sailogger`, {}
            ).then((res) => {
                console.log("FETCH DEV ID RESPONSE");
                console.log(res);
                this.devid_data = res.data.data
                this.devid_input = res.data.data.latest_value
                this.initTable_devid(res.data.data)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch IoT Service Sailogger
        async fetch_iots_sailogger(item) {
            this.iots_data = null
            await api.get(`api/v1/navplus/sailogger/ota/` + item.id + `/iot_service-sailogger`, {}
            ).then((res) => {
                console.log("FETCH IOT SERVICE RESPONSE");
                console.log(res);
                this.iots_data = res.data.data
                this.iots_input = (res.data.data.latest_value != null && res.data.data.latest_value.length > 0) ? res.data.data.latest_value : '1 RPM'
                this.initTable_iots(res.data.data)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch IoT Service Sailogger
        async fetch_gps_sailogger(item) {
            this.gps_data = null
            await api.get(`api/v1/navplus/sailogger/ota/` + item.id + `/gps_dev-sailogger`, {}
            ).then((res) => {
                console.log("FETCH GPS RESPONSE");
                console.log(res);
                this.gps_data = res.data.data
                this.gps_input = (res.data.data.latest_value != null && res.data.data.latest_value.length > 0) ? res.data.data.latest_value : 'USB'
                this.initTable_gps(res.data.data)
            }).catch((err) => {
            }).then(() => {
            });
        },
                //Fetch IoT Service Sailogger
                async fetch_devdate_sailogger(item) {
                    this.devdate_data = null
                    await api.get(`api/v1/navplus/sailogger/ota/` + item.id + `/date-sailogger`, {}
                    ).then((res) => {
                        console.log("FETCH DEVICE DATE RESPONSE");
                        console.log(res);
                        this.devdate_data = res.data.data
                        this.devdate_input = (res.data.data.latest_value != null && res.data.data.latest_value.length > 0) ? res.data.data.latest_value : ''
                        this.initTable_devdate(res.data.data)
                    }).catch((err) => {
                    }).then(() => {
                    });
                },
        //------------------------------------------------------------------



        //CHATROOM ACTIONS FUNCTIONS
        //------------------------------------------------------------------
        //Filter Chatroom 
        filter_chatroom() {
            var message_ = this.chatroom_data.messages
            console.log(" HASIL SEARCH ");
            console.log(message_);


            const filter_message = message_.filter(val => val.body.includes(this.chatroom_search));
            console.log(filter_message);
            if (this.chatroom_search) {
                this.chatroom_messages = filter_message
            } else {
                this.chatroom_messages = this.chatroom_data.messages
            }

        },
        //Close Chatroom
        close_chatroom() {
            if (this.chatroom_interval) {
                clearInterval(this.chatroom_interval);
            }
            this.chatroom_data = null
        },
        //Clear Chatroom
        clear_chatroom_search() {
            this.chatroom_search = ''
            this.chatroom_messages = this.chatroom_data.messages
            clearInterval(this.chatroom_interval);
        },
        //Request Chatroom Data Gradually
        async gradual_chatroom() {
            await api.get(`/api/v1/navplus/sms/fetch/` + this.chatroom_item.id, {}
            ).then((res) => {
                console.log("UPDATE CHATROOM");
                console.log(res);
                this.chatroom_messages = res.data.messages
                if (!this.chatroom_search && this.chatroom_search.length == 0) {
                    this.chatroom_messages = res.data.messages.messages
                }
            }).catch((err) => {

            }).then(() => {
            });


        },
        //------------------------------------------------------------------



        // POST DATA FUNCTIONS
        //------------------------------------------------------------------
        async post_chatroom() {
            var message = this.chatroom_body
            this.chatroom_body = ''
            this.chatroom_sending = true
            await api.post(`/api/v1/navplus/skychat/cr/send`, {
                msg: message,
                vessel_id: this.chatroom_data.id,
            }
            ).then((res) => {
                console.log("POST SMS RESPONSE");
                console.log(res);
                var mom = this
                setTimeout(() => {
                    mom.fetch_chatroom(mom.chatroom_item, true)
                    var objDiv = document.getElementById("ir_chatroom_content");
                    objDiv.scrollTop = objDiv.scrollHeight;
                }, 1000);

            }).catch((err) => {

            }).then(() => {
            });
        },

        async post_skychat_user() {
            this.skychat_user_loading = true;
            await api.post(`/api/v1/navplus/skychat/push`, {
                data: this.skychat_user_post,
                vessel_id: this.skychat_user_item.id
            }
            ).then((res) => {
                console.log("POST SKYCHAT USER RESPONSE");
                console.log(res);
                this.skychat_user_loading = false;
                this.fetch_skychat_user_setting(this.skychat_user_item, false)

            }).catch((err) => {

            }).then(() => {
            });

        },

        async update_speedalert() {
            console.log("VESSEL SPEED ALERT CHANGE RESPONSE");


            await api.post("/api/v1/navplus/speed/" + this.speedalert_data.id, {
                isEnabled: this.speedalert_data.isEnabled ? this.speedalert_data.isEnabled : 0,
                speed_min: this.speedalert_data.min,
                speed_max: this.speedalert_data.max,
                emails: this.speedalert_data.emails,
            }

            ).then((res) => {
                console.log("VESSEL SPEED ALERT CHANGE RESPONSE");
                console.log(res);

                if (res.status == 200) {
                    $('#iridiumspeedNotifModal_alert').removeClass('d-none')
                    $('#iridiumspeedNotifModal_alert').addClass('d-block')
                    $('#iridiumspeedNotifModal_alert').html('Successfully Updated Speed Notification Configuration!')

                    setTimeout(() => {
                        $('#iridiumspeedNotifModal_alert').removeClass('d-block')
                        $('#iridiumspeedNotifModal_alert').addClass('d-none')
                        $('#iridiumspeedNotifModal_alert').html('')
                    }, 1500);

                } else {
                    $('#iridiumspeedNotifModal_alert').removeClass('alert-success')
                    $('#iridiumspeedNotifModal_alert').addClass('alert-danger')
                    $('#iridiumspeedNotifModal_alert').removeClass('d-none')
                    $('#iridiumspeedNotifModal_alert').addClass('d-block')
                    $('#iridiumspeedNotifModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#iridiumspeedNotifModal_alert').addClass('alert-success')
                        $('#iridiumspeedNotifModal_alert').removeClass('alert-danger')
                        $('#iridiumspeedNotifModal_alert').removeClass('d-block')
                        $('#iridiumspeedNotifModal_alert').addClass('d-none')
                        $('#iridiumspeedNotifModal_alert').html('')
                    }, 1500);
                }

            }).catch((err) => {
                $('#iridiumspeedNotifModal_alert').removeClass('alert-success')
                $('#iridiumspeedNotifModal_alert').addClass('alert-danger')
                $('#iridiumspeedNotifModal_alert').removeClass('d-none')
                $('#iridiumspeedNotifModal_alert').addClass('d-block')
                $('#iridiumspeedNotifModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#iridiumspeedNotifModal_alert').addClass('alert-success')
                    $('#iridiumspeedNotifModal_alert').removeClass('alert-danger')
                    $('#iridiumspeedNotifModal_alert').removeClass('d-block')
                    $('#iridiumspeedNotifModal_alert').addClass('d-none')
                    $('#iridiumspeedNotifModal_alert').html('')
                }, 1500);
            }).then(() => {
            });

        },
        //Post GPS Email Config
        async post_gpsemail() {
            this.ir_gpsemail_loading = true
            await api.post(`api/v1/navplus/iridium/email/`, {
                id: this.ir_gpsemail_item.id,
                isEnabled: this.ir_gpsemail_data.isEnabled,
                emails: this.ir_gpsemail_data.emails,
            }
            ).then((res) => {
                console.log("POST GPS EMAIL CONFIG RESPONSE");
                console.log(res);
                this.ir_gpsemail_loading = false
                this.fetch_gpsemail(this.ir_gpsemail_item);
                if (res.status == 200) {
                    $('#iridiumGPSEmailModal_alert').removeClass('d-none')
                    $('#iridiumGPSEmailModal_alert').addClass('d-block')
                    $('#iridiumGPSEmailModal_alert').html('Successfully Updated GPS Email Report Configuration!')

                    setTimeout(() => {
                        $('#iridiumGPSEmailModal_alert').removeClass('d-block')
                        $('#iridiumGPSEmailModal_alert').addClass('d-none')
                        $('#iridiumGPSEmailModal_alert').html('')
                    }, 1500);

                } else {
                    $('#iridiumGPSEmailModal_alert').removeClass('alert-success')
                    $('#iridiumGPSEmailModal_alert').addClass('alert-danger')
                    $('#iridiumGPSEmailModal_alert').removeClass('d-none')
                    $('#iridiumGPSEmailModal_alert').addClass('d-block')
                    $('#iridiumGPSEmailModal_alert').html('Something wrong, cannot change!')

                    setTimeout(() => {
                        $('#iridiumGPSEmailModal_alert').addClass('alert-success')
                        $('#iridiumGPSEmailModal_alert').removeClass('alert-danger')
                        $('#iridiumGPSEmailModal_alert').removeClass('d-block')
                        $('#iridiumGPSEmailModal_alert').addClass('d-none')
                        $('#iridiumGPSEmailModal_alert').html('')
                    }, 1500);
                }
            }).catch((err) => {
                $('#iridiumGPSEmailModal_alert').removeClass('alert-success')
                $('#iridiumGPSEmailModal_alert').addClass('alert-danger')
                $('#iridiumGPSEmailModal_alert').removeClass('d-none')
                $('#iridiumGPSEmailModal_alert').addClass('d-block')
                $('#iridiumGPSEmailModal_alert').html('Something wrong, cannot change!')

                setTimeout(() => {
                    $('#iridiumGPSEmailModal_alert').addClass('alert-success')
                    $('#iridiumGPSEmailModal_alert').removeClass('alert-danger')
                    $('#iridiumGPSEmailModal_alert').removeClass('d-block')
                    $('#iridiumGPSEmailModal_alert').addClass('d-none')
                    $('#iridiumGPSEmailModal_alert').html('')
                }, 1500);
            }).then(() => {
            });
        },
        //Post Reboot Sailogger
        async post_reboot_sailogger() {
            await api.post(`/api/v1/navplus/ota/` + this.reboot_item.id + `/reboot-sailogger`, {}
            ).then((res) => {
                console.log("REBOOT SAILOGGER RESPONSE");
                console.log(res);
                this.fetch_reboot_sailogger(this.reboot_item)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Post Interval Sailogger
        async post_interval_sailogger() {
            await api.post(`/api/v1/navplus/ota/` + this.interval_item.id + `/interval-sailogger`, {
                "slf-arg": this.interval_input
            }
            ).then((res) => {
                console.log("INTERVAL SAILOGGER RESPONSE");
                console.log(res);
                this.fetch_interval_sailogger(this.interval_item)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Post Dev ID Sailogger
        async post_devid_sailogger() {
            await api.post(`/api/v1/navplus/ota/` + this.devid_item.id + `/dev_id-sailogger`, {
                "slf-arg": this.devid_input
            }
            ).then((res) => {
                console.log("DEVID SAILOGGER RESPONSE");
                console.log(res);
                this.fetch_devid_sailogger(this.devid_item)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Post IoT Service Sailogger
        async post_iots_sailogger() {
            await api.post(`/api/v1/navplus/ota/` + this.iots_item.id + `/iot_service-sailogger`, {
                "slf-arg": this.iots_input
            }
            ).then((res) => {
                console.log("IOT SERVICE SAILOGGER RESPONSE");
                console.log(res);
                this.fetch_iots_sailogger(this.iots_item)
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Post IoT Service Sailogger
        async post_gps_sailogger() {
            await api.post(`/api/v1/navplus/ota/` + this.gps_item.id + `/gps_dev-sailogger`, {
                "slf-arg": this.gps_input
            }
            ).then((res) => {
                console.log("GPS DEVICE SAILOGGER RESPONSE");
                console.log(res);
                this.fetch_gps_sailogger(this.gps_item)
            }).catch((err) => {
            }).then(() => {
            });
        },
                //Post IoT Service Sailogger
                async post_devdate_sailogger() {
                    await api.post(`/api/v1/navplus/ota/` + this.devdate_item.id + `/date-sailogger`, {
                        "slf-arg": this.devdate_input
                    }
                    ).then((res) => {
                        console.log("DEVICE DATE SAILOGGER RESPONSE");
                        console.log(res);
                        this.fetch_devdate_sailogger(this.devdate_item)
                    }).catch((err) => {
                    }).then(() => {
                    });
                },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search iridium table
        iridiumTable_search() {
            if (this.search_iridium != null && this.search_iridium.length > 0) {
                this.iridium_table.search(this.search_iridium).draw();
            }
        },
        //search iridium user table
        userTable_search() {
            this.iridiumuser_table.search(this.search_iridiumuser).draw();
        },
        //search iridium tracking table
        trackTable_search() {
            this.iridiumtrack_table.search(this.search_iridiumtrack).draw();
        },
        //search sailoggers table
        rebootTable_search() {
            if (this.search_reboot != null && this.search_reboot.length > 0) {
                this.iridiumreboot_table.search(this.search_reboot).draw();
            }
        },
        //clear snapshot table
        clear_rebootTable_search() {
            this.search_reboot = ''
            this.iridiumreboot_table.search(this.search_reboot).draw();
        },

        //search sailoggers table
        intervalTable_search() {
            if (this.search_interval != null && this.search_interval.length > 0) {
                this.iridiuminterval_table.search(this.search_interval).draw();
            }
        },
        //clear snapshot table
        clear_intervalTable_search() {
            this.search_interval = ''
            this.iridiuminterval_table.search(this.search_interval).draw();
        },
        //search sailoggers table
        devidTable_search() {
            if (this.search_devid != null && this.search_devid.length > 0) {
                this.iridiumdevid_table.search(this.search_devid).draw();
            }
        },
        //clear snapshot table
        clear_devidTable_search() {
            this.search_devid = ''
            this.iridiumdevid_table.search(this.search_devid).draw();
        },
                //search sailoggers table
                gpsTable_search() {
                    if (this.search_gps != null && this.search_gps.length > 0) {
                        this.iridiumgps_table.search(this.search_gps).draw();
                    }
                },
                //clear snapshot table
                clear_gpsTable_search() {
                    this.search_gps = ''
                    this.iridiumgps_table.search(this.search_gps).draw();
                },
                 //search sailoggers table
                 devdateTable_search() {
                    if (this.search_devdate != null && this.search_devdate.length > 0) {
                        this.iridiumdevdate_table.search(this.search_devdate).draw();
                    }
                },
                //clear snapshot table
                clear_devdateTable_search() {
                    this.search_devdate = ''
                    this.iridiumdevdate_table.search(this.search_devdate).draw();
                },
        //search sailoggers table
        iotsTable_search() {
            if (this.search_iots != null && this.search_iots.length > 0) {
                this.iridiumiots_table.search(this.search_iots).draw();
            }
        },
        //clear snapshot table
        clear_iotsTable_search() {
            this.search_iots = ''
            this.iridiumiots_table.search(this.search_iots).draw();
        },
        //data shorting iridium table 
        iridiumTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridium_table.page.len(page).draw()
            this.page_off = page;
        },
        //data shorting iridium table 
        cdr_skychat_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumSkychatCDR_table.page.len(page).draw()
            this.pageoff_cdr_skychat = page;
        },
        //data shorting iridium table 
        cdr_tracking_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumTrackingCDR_table.page.len(page).draw()
            this.pageoff_cdr_tracking = page;
        },
        //data shorting iridium table 
        cdradmin_iridium_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumIridiumCDRAdmin_table.page.len(page).draw()
            this.pageoff_cdradmin_iridium = page;
        },
        //data shorting iridium table 
        cdradmin_skyreach_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumSkyreachCDRAdmin_table.page.len(page).draw()
            this.pageoff_cdradmin_skyreach = page;
        },
        //data shorting iridium table 
        iridiumTrackTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumtrack_table.page.len(page).draw()
            this.page_off_tracking = page;
        },
        //clear iridium table
        clear_iridiumTable_search() {
            this.search_iridium = ''
            this.iridium_table.search(this.search_iridium).draw();
        },
        speedhistoryTable_showof(page) {
            console.log("PAGE : " + page);
            this.speedhistory_table.page.len(page).draw()
            this.speedhistory_page_off = page;
        },
        iridiumrebootTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumreboot_table.page.len(page).draw()
            this.page_off_reboot = page;
        },
        iridiumintervalTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridiuminterval_table.page.len(page).draw()
            this.page_off_interval = page;
        },
        iridiumdevidTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumdevid_table.page.len(page).draw()
            this.page_off_devid = page;
        },
        iridiumiotsTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumiots_table.page.len(page).draw()
            this.page_off_iots = page;
        },
        iridiumgpsTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumgps_table.page.len(page).draw()
            this.page_off_gps = page;
        },
        iridiumdevdateTable_showof(page) {
            console.log("PAGE : " + page);
            this.iridiumdevdate_table.page.len(page).draw()
            this.page_off_devdate = page;
        },
        //clear sailink table
        clear_speedhistoryTable_search() {
            this.search_speedhistory = ''
            this.speedhistory_table.search(this.search_speedhistory).draw();
        },
        //search sailink speedhistory
        speedhistoryTable_search() {
            console.log(this.search_speedhistory)
            if (this.search_speedhistory != null && this.search_speedhistory.length > 0) {
                this.speedhistory_table.search(this.search_speedhistory).draw();
            }
        },

        cdr_skychat_search() {
            this.iridiumSkychatCDR_table.search(this.search_cdr_skychat).draw();
        },
        //clear sailink table
        clear_cdr_skychat_search() {
            this.search_cdr_skychat = ''
            this.iridiumSkychatCDR_table.search(this.search_cdr_skychat).draw();
        },
        cdr_tracking_search() {
            this.iridiumTrackingCDR_table.search(this.search_cdr_tracking).draw();
        },
        //clear sailink table
        clear_cdr_tracking_search() {
            this.search_cdr_tracking = ''
            this.iridiumTrackingCDR_table.search(this.search_cdr_tracking).draw();
        },
        cdradmin_iridium_search() {
            this.iridiumIridiumCDRAdmin_table.search(this.search_cdradmin_iridium).draw();
        },
        //clear sailink table
        clear_cdradmin_iridium_search() {
            this.search_cdradmin_iridium = ''
            this.iridiumIridiumCDRAdmin_table.search(this.search_cdradmin_iridium).draw();
        },
        cdradmin_skyreach_search() {
            this.iridiumSkyreachCDRAdmin_table.search(this.search_cdradmin_skyreach).draw();
        },
        //clear sailink table
        clear_cdradmin_skyreach_search() {
            this.search_cdradmin_skyreach = ''
            this.iridiumSkyreachCDRAdmin_table.search(this.search_cdradmin_skyreach).draw();
        },
        //------------------------------------------------------------------



        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Iridium Table
        initTable() {
            var root = this
            if (this.iridium_table) {
                console.log("MASUK CLEAR TABLE");
                root.iridium_table = null;
                root.initTable()
            } else {
                console.log("MASUK INIT TABLE");
                console.log(this.iridium_data);
                this.iridium_table = new DataTable('#iridiumTable', {
                    data: this.iridium_data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "5%",
                            data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${meta.row + 1}
                            </div>
                            `
                            }
                        },
                        {
                            width: "12.8%",
                            data: 'name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 h-100 row`+ meta.row + `">
                            ${data} 
                            ${this.iridium_data[meta.row].skychat && (this.iridium_data[meta.row].skychat.credit_config <= 0) ? '<span class="badge font-small text-bg-danger blink">CREDIT CONFIG EXCEEDED</span>' : ''}
                            ${this.iridium_data[meta.row].iridium.ext_source ? '<span class="badge text-bg-success font-small">' + this.iridium_data[meta.row].iridium.ext_source + '</span>' : ''}
                            </div>
                            `
                            }
                        },
                        {
                            width: "12.8%",
                            data: 'pt_name', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "11.8%",
                            data: 'pt_group', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data != null ? data : `-`}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "11.8%",
                            data: 'imei', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                `+ (this.iridium_data[meta.row].skychat && !this.iridium_data[meta.row].iridium.ext_source ? `<button data-bs-toggle="modal" data-bs-target="#iridiumChatModal"  id="irchat_btn" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-comments"></i></button>` : ``) + `${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "10.8%",
                            data: 'rateplan', className: 'p-0', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${data}
                                </div>
                                    `
                            }
                        },
                        {
                            width: "10.8%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                if (this.iridium_data[meta.row].skychat) {
                                    return `
                        <div id="notifdiv_${meta.row}" class="col-sm-12 p-1">
                               <div  class="dropdown">
          <button id="notif_btn_${meta.row}" ${this.authStore.userAllowed.role_id == 4 || this.iridium_data[meta.row].iridium.ext_source != null ? 'disabled' : ''} class="btn btn-sm rounded-none btn-primary dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
            <i class="fas fa-wrench me-1"></i>Config
          </button>

          <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
    
            `+ (this.iridium_data[meta.row].skychat && this.authStore.userAllowed.role_id <= 3 ? `<li><a data-bs-toggle="modal" data-bs-target="#skychatUserModal" id="irschatuser_btn" class="dropdown-item" href="#">Skychat User</a></li>` : ``) + `
             <li><a data-bs-toggle="modal" data-bs-target="#iridiumspeedNotifModal" id="irspeedalert_btn" class="dropdown-item aspeed_${meta.row}" href="#">Speed Alert</a></li>                      
             <li><a data-bs-toggle="modal" data-bs-target="#iridiumspeedHistoryModal" id="irspeedhistory_btn" class="dropdown-item" href="#">↳ History</a></li>
             <li><a data-bs-toggle="modal" data-bs-target="#iridiumGPSEmailModal" id="irgpsmail_btn" class="dropdown-item" href="#">GPS Email</a></li>
             `+
                                        (this.iridium_isAdmin ? `<li><a data-bs-toggle="modal" data-bs-target="#iridium_rebootmodal" id="irreboot_btn" class="dropdown-item" href="#">Reboot Sailogger</a></li>` : ``)
                                        + (this.iridium_isAdmin ? `<li><a data-bs-toggle="modal" data-bs-target="#iridium_intervalmodal" id="irinterval_btn" class="dropdown-item" href="#">Interval</a></li>` : ``)
                                        + (this.iridium_isAdmin ? `<li><a data-bs-toggle="modal" data-bs-target="#iridium_devidmodal" id="irdevid_btn" class="dropdown-item" href="#">Dev ID</a></li>` : ``)
                                        + (this.iridium_isAdmin ? `<li><a data-bs-toggle="modal" data-bs-target="#iridium_iotsmodal" id="iriots_btn" class="dropdown-item" href="#">IOT Service</a></li>` : ``)
                                        + (this.iridium_isAdmin ? `<li><a data-bs-toggle="modal" data-bs-target="#iridium_gpsmodal" id="irgps_btn" class="dropdown-item" href="#">GPS Device</a></li>` : ``)
                                        + (this.iridium_isAdmin ? `<li><a data-bs-toggle="modal" data-bs-target="#iridium_devdatemodal" id="irdevdate_btn" class="dropdown-item" href="#">Device Date</a></li>` : ``)
                                        + `
             </ul>
             </div>
        </div>
                              `} else {
                                    return ``
                                }
                            }
                        },
                        {
                            width: "11.8%",
                            data: 'id', className: 'p-2 no-wrap', render: (data, type, row, meta) => {

                                if (!this.iridium_data[meta.row].iridium.ext_source) {
                                    return `
                        
                                <div class="p-1 h-100">
                                <div class="row p-0">
                                `
                                        +
                                        (
                                            `<div class="col-sm-12 p-1">
                                <button id="ircdr_btn" data-bs-toggle="modal" data-bs-target="#iridiumCDRModal"  type="button" class="btn btn-sm btn-success w-100 rounded-none">CDR</button>
                                </div>`)
                                        +
                                        (this.authStore.userAllowed.allowed['view cdr iridium'] ?
                                            `<div class="col-sm-12 p-1">
                          <button id="ircdradmin_btn" data-bs-toggle="modal" data-bs-target="#iridiumCDRAdminModal"  type="button" class="btn btn-sm btn-success w-100 rounded-none">CDR (Admin)</button>
                          </div>`: ``)

                                        +
                                        `
                                </div>
                                </div>
                                `
                                }
                                else {
                                    return `
    
                              <div class="p-1 h-100">
                              </div>
                              `
                                }



                            }
                        },
                        {
                            width: "11.8%",
                            data: 'id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                        
                              <div class="p-1 h-100 ps-0">
                              <button onclick="showonmap_iridium('`+ data + `')"  type="button" class="btn btn-sm btn-primary w-100 rounded-none">Show on Map</button>
                              </div>
                              `
                            }
                        },
                    ]
                });


                var mom = this;
                setTimeout(() => {                    
                    if(mom.news_store.popover_mark){
                        mom.news_store.coachmark(mom.news_store.popover_mark,1);
                    }
                }, 500);

                this.iridium_table.on('click', '#irchat_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("CHATROOM DI CLICK");
                    console.log(row.data());

                    mom.fetch_chatroom(row.data(), false)

                });


                this.iridium_table.on('click', '#irschatuser_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("SKYCHAT USER BUTTON DI CLICK");
                    console.log(row.data());
                    mom.skychat_user_item = row.data()
                    mom.fetch_skychat_user_setting(row.data(), true)

                });

                this.iridium_table.on('click', '#irspeedalert_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("SPEED ALERT CLICK");
                    console.log(row.data());

                    mom.fetch_speedalert(row.data());

                });

                this.iridium_table.on('click', '#irspeedhistory_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("SPEED HISTORY DI CLICK");
                    console.log(row.data());
                    mom.speedhistory_data = null;
                    mom.search_speedhistory = '';
                    mom.fetch_speedhistory(row.data());

                });

                this.iridium_table.on('click', '#irgpsmail_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("GPS EMAIL DI CLICK");
                    console.log(row.data());

                    mom.ir_gpsemail_item = row.data()
                    mom.fetch_gpsemail(row.data())
                });

                this.iridium_table.on('click', '#ircdr_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("CDR DI CLICK");
                    console.log(row.data());


                    mom.cdr_item = row.data()
                    mom.cdr_range = 'month'
                    mom.cdr_rangelabel = 'This Month'

                    mom.fetch_cdr(row.data())
                });

                this.iridium_table.on('click', '#ircdradmin_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("CDR DI CLICK");
                    console.log(row.data());


                    mom.cdradmin_item = row.data()
                    mom.cdradmin_range = 'month'
                    mom.cdradmin_rangelabel = 'This Month'

                    mom.fetch_cdradmin(row.data())
                });


                this.iridium_table.on('click', '#irreboot_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("REBOOT DI CLICK");
                    console.log(row.data());
                    mom.reboot_item = row.data();
                    mom.page_off_reboot = 10;
                    mom.fetch_reboot_sailogger(row.data());
                });

                this.iridium_table.on('click', '#irinterval_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("INTERVAL DI CLICK");
                    console.log(row.data());
                    mom.interval_item = row.data();
                    mom.page_off_interval = 10;
                    mom.fetch_interval_sailogger(row.data());
                });


                this.iridium_table.on('click', '#irdevid_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("DEVID DI CLICK");
                    console.log(row.data());
                    mom.devid_item = row.data();
                    mom.page_off_devid = 10;
                    mom.fetch_devid_sailogger(row.data());
                });

                this.iridium_table.on('click', '#iriots_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("IOT SERVICE DI CLICK");
                    console.log(row.data());
                    mom.iots_item = row.data();
                    mom.page_off_iots = 10;
                    mom.fetch_iots_sailogger(row.data());
                });

                this.iridium_table.on('click', '#irgps_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("GPS DI CLICK");
                    console.log(row.data());
                    mom.gps_item = row.data();
                    mom.page_off_gps = 10;
                    mom.fetch_gps_sailogger(row.data());
                });

                this.iridium_table.on('click', '#irdevdate_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.iridium_table.row(tr);
                    console.log("DEVICE DATE DI CLICK");
                    console.log(row.data());
                    mom.devdate_item = row.data();
                    mom.page_off_devdate = 10;
                    mom.fetch_devdate_sailogger(row.data());
                });

            }
        },
        //Initialize Iridium User
        initTable_user() {
            var root = this
            console.log("INIT TABLE USER");
            console.log(this.iridiumuser_table);
            console.log("MASUK INIT TABLE USER");
            console.log(this.mapiridium_data.vesselUsers);
            this.iridiumuser_table = new DataTable('#iridiumTableUser', {
                data: this.mapiridium_data.vesselUsers,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 5,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-0 h-100">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "24.25%",
                        data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "14.25%",
                        data: 'role_name', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "34.25%",
                        data: 'lastLogin', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        //Initialize Tracking Table
        initTable_tracking(data) {
            console.log("INIT TABLE TRACKING");
            console.log(this.iridiumtrack_table);
            console.log("MASUK INIT TABLE TRACKING");
            console.log(data.tracking);
            this.iridiumtrack_table = new DataTable('#iridiumTableTrack', {
                data: data.dataTable,
                scrollCollapse: true,
                scroller: true,
                destroy: true,
                pageLength: 10,
                deferRender: true,
                ordering: true,
                bLengthChange: false,
                responsive: true,
                dom: 'lrtip',
                columns: [
                    {
                        width: "3%",
                        data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                            return `
                            <div class="p-1 h-100">
                            ${meta.row + 1}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'latlong', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                            <div onclick="popup_tracking('`+ meta.row + `')" class="p-0 h-100 link_tracking">
                            ${data}
                            </div>
                            `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dms', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'speed', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                                    `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'heading', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'distance', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                        
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }


                    },
                    {
                        width: "13.2%",
                        data: 'dateTime', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                    {
                        width: "13.2%",
                        data: 'dateTime_local', className: 'td_nowrap text-center', render: (data, type, row, meta) => {
                            return `  
                                <div class="p-0 h-100">
                                ${data}
                                </div>
                              `
                        }
                    },
                ]
            });
        },
        //Initialize Skychat User Setting Table
        initTable_skychat_user(data) {
            console.log("INIT TABLE CDR");
            console.log(this.skychat_user_table);
            console.log("MASUK INIT TABLE CDR");

            try {
                this.skychat_user_table = new DataTable('#iridiumTableSkychat', {
                    data: data.vesselUsers,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "20%",
                            data: 'name', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "20%",
                            data: 'email', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },
                        {
                            width: "20%",
                            data: 'username', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data ? data : '-'}
                                </div>
                                `
                            }
                        },
                        {
                            width: "20%",
                            data: 'skychat', className: 'td_nowrap', render: (data, type, row, meta) => {
                                if (this.skychat_user.vesselUsers[meta.row].username) {
                                    return `
                                <div class="p-1 h-100 w-100">
                                <div  class="dropdown">
          <button ${this.skychat_user.latest_status == 'ON AIR' ? 'disabled' : ''}  id="skychat_user_dropdown_label_${this.skychat_user.vesselUsers[meta.row].id}" class="btn btn-sm w-100 rounded-none btn-light border dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
           ${this.skychat_user_role_arr[data.role_id - 1].label}
          </button>

          <ul  class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
                                    ${this.return_dropdown(meta.row)}
          </ul>
        </div>
                                </div>
                                `
                                } else {
                                    return `
                                <div class="p-1 h-100 w-100">
                                <div  class="dropdown w-100">
          <button disabled  class="btn btn-sm rounded-none btn-light border w-100 dropdown-toggle" data-bs-toggle="dropdown"
            aria-expanded="false">
          -
          </button>

          <ul class="dropdown-menu border bg-main dropdown-menu-end rounded-none dropdown_nav">
    
          </ul>
        </div>
                                </div>
                                `
                                }

                            }
                        },
                        {
                            width: "20%",
                            data: 'id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                if (this.skychat_user.vesselUsers[meta.row].username) {
                                    return `
                                    <div class="p-1 h-100">
                                    <span title="" id="skychat_user_checkdiv_${data}" class="form-check">
                                    ${this.return_checkbox(meta.row)}
                                    </span>
                                    </div>
                                    `
                                } else {
                                    return `
                                    <div class="p-1 h-100"> 
                                    <span id="skychat_no_username_check" title="" class="form-check not_allowed_cursor">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled>
                                    </span>
                                    </div>
                                    `
                                }

                            }
                        },


                    ]
                });




            } catch (e) {

            }

            var mom = this;
            // this.skychat_user_table.on('click', '#check_sky_user', function (e) {
            //     let tr = e.target.closest('tr');
            //     let row = mom.skychat_user_table.row(tr);

            //     console.log(row.data());



            // });

            if (this.skychat_user_checked.length >= 3) {

                for (let i = 0; i < this.skychat_user_unchecked.length; i++) {
                    $('#check_sky_user_' + this.skychat_user_unchecked[i]).attr("disabled", true);
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).addClass("not_allowed_cursor");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).attr('title', 'Max Active SKYCHAT User of 3 has Exceeded, Please Disabled other first.');
                }
                $('#skychat_no_username_check').attr('title', 'Max Active SKYCHAT User of 3 has Exceeded, Please Disabled other first.');
            } else {
                for (let i = 0; i < this.skychat_user_unchecked.length; i++) {
                    $('#check_sky_user_' + this.skychat_user_unchecked[i]).removeAttr("disabled");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).removeClass("not_allowed_cursor");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).attr('title', '');
                }
                $('#skychat_no_username_check').attr('title', 'Username Required');
            }

        },
        //Initialize Notificaton History Table
        initTable_history(dataHistory) {

            var root = this
            console.log("INIT TABLE HISTORY NOTIF");
            console.log(this.speedhistory_table);
            console.log("MASUK INIT TABLE HISTORY NOTIF");
            console.log(dataHistory);



            try {
                if (dataHistory) {
                    this.speedhistory_table = new DataTable('#irspeedhistoryTable', {
                        data: dataHistory,
                        scrollCollapse: true,
                        scroller: true,
                        destroy: true,
                        pageLength: 5,
                        deferRender: true,
                        ordering: true,
                        bLengthChange: false,
                        responsive: true,
                        dom: 'lrtip',
                        columns: [
                            {
                                width: "5%",
                                data: '', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                ${meta.row + 1}
                                </div>
                                `
                                }
                            },
                            {
                                width: "2%",
                                data: 'msg_id', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                    return `
                                <div class="p-1 h-100 row`+ meta.row + `">
                                <div class="form-check">
                                <input ${this.speedhistory_data.notifications[meta.row].unRead == 1 ? '' : 'disabled'} onchange="oncheck_notif('${data}','iridium')" id="ir_checknotif_${data}" class="form-check-input" type="checkbox" value="">
                                </div>
                                </div>
                                `
                                }
                            },
                            {
                                width: "92%",
                                data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                    return `
                                    <div onclick="onclick_notif_speedhistory('${meta.row}','iridium')" id="notif_btn" class="p-1 h-100 pointer_cursor" data-bs-toggle="modal" data-bs-target="#notification_speed_detailModal">
                                     <h8 class="${!this.speedhistory_data.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-dark'}"><b>${(this.speedhistory_data.notifications[meta.row].type == 1 || this.speedhistory_data.notifications[meta.row].type == 2 || this.speedhistory_data.notifications[meta.row].type == 5) ? 'Speed Alert' : (this.speedhistory_data.notifications[meta.row].type == 3) ? 'RPM Alert' : 'Speed Alert'}</b></h8>${this.speedhistory_data.notifications[meta.row].unRead ? `<i  style="font-size: 0.6em;" class='bx bxs-circle text-danger ms-2'></i>` : ``}<br/>
                                    <span class="${!this.speedhistory_data.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-dark'}" style="font-size: 0.8em;">${this.speedhistory_data.notifications[meta.row].msg}</span><br/>
                                    <p class="${!this.speedhistory_data.notifications[meta.row].unRead ? 'text-secondary opacity-50' : 'text-primary'}" style="font-size: 0.8em;" ><b>${this.speedhistory_data.notifications[meta.row].dateDiff} (${data ? data : '-'})</b></p>
                                    </div>
                                    `
                                }
                            },

                        ]
                    });
                }
            } catch (e) {

            }
        },
        //Initialize Table CDR
        initTable_cdr_skychat(data) {
            console.log("INIT TABLE CDR SKYCHAT");
            console.log(this.iridiumSkychatCDR_table);
            try {
                this.iridiumSkychatCDR_table = new DataTable('#iridiumSkychatTableCDR', {
                    data: data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: 'id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                        <div class="p-1 h-100">
                                        SKYCHAT
                                        </div>
                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'dateTime', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                        <div class="p-1 h-100">
                                        ${data}
                                        </div>
                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                        <div class="p-1 h-100">
                                        ${data}
                                        </div>
                                        `
                            }
                        },
                    ]
                });




            } catch (e) {

            }

        },
        //Initialize Table CDR
        initTable_cdr_track(data) {
            console.log("INIT TABLE CDR TRACK");
            console.log(this.iridiumSkychatCDR_table);
            try {
                this.iridiumTrackingCDR_table = new DataTable('#iridiumTrackingTableCDR', {
                    data: data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: 'id', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                <div class="p-1 h-100">
                                                TRACKING/IOT
                                                </div>
                                                `
                            }
                        },
                        {
                            width: "",
                            data: 'dateTime', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                <div class="p-1 h-100">
                                                ${data}
                                                </div>
                                                `
                            }
                        },
                        {
                            width: "",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                <div class="p-1 h-100">
                                                ${data}
                                                </div>
                                                `
                            }
                        },
                    ]
                });




            } catch (e) {

            }

        },
        //Initialize Table CDR
        initTable_cdradmin_iridium(data) {
            console.log("INIT TABLE CDR IRIDIUM");
            console.log(this.iridiumIridiumCDRAdmin_table);
            try {
                this.iridiumIridiumCDRAdmin_table = new DataTable('#iridiumIridiumTableCDR', {
                    data: data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                        <div class="p-1 h-100">
                                                        ${data ? data : '-'}
                                                        </div>
                                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'Data_Volume_Reference', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                        <div class="p-1 h-100">
                                                        ${data ? data : '-'}
                                                        </div>
                                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'Called_Number', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                        <div class="p-1 h-100">
                                                        ${data ? data : '-'}
                                                        </div>
                                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'Charge', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                        <div class="p-1 h-100">
                                                        ${data ? parseFloat(parseInt(data) / 1000) : '-'}
                                                        </div>
                                                        `
                            }
                        },
                    ]
                });




            } catch (e) {

            }

        },
        //Initialize Table CDR
        initTable_cdradmin_skyreach(data) {
            console.log("INIT TABLE CDR SKYREACH");
            console.log(this.iridiumIridiumCDRAdmin_table);
            try {
                this.iridiumSkyreachCDRAdmin_table = new DataTable('#iridiumSkyreachTableCDR', {
                    data: data,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                                        <div class="p-1 h-100">
                                                                        ${data ? data : '-'}
                                                                        </div>
                                                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'dateTime_session', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                                        <div class="p-1 h-100">
                                                                        ${data ? data : '-'}
                                                                        </div>
                                                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'amount', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                                        <div class="p-1 h-100">
                                                                        ${data ? data : '-'}
                                                                        </div>
                                                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'content', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                                        <div class="p-1 h-100">
                                                                        ${data ? data : '-'}
                                                                        </div>
                                                                        `
                            }
                        },
                        {
                            width: "",
                            data: 'to', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                                        <div class="p-1 h-100">
                                                                        ${data ? parseInt(data) == 1005 ? 'INBOUND' : 'OUTBOUND' : '-'}
                                                                        </div>
                                                                        `
                            }
                        },
                    ]
                });




            } catch (e) {

            }

        },
        //Initialize Table Reboot
        initTable_reboot(data) {
            console.log("INIT TABLE REBOOT");
            console.log(this.iridiumreboot_table);
            console.log("MASUK INIT TABLE REBOOT");
            console.log(data.output)
            try {
                console.log("MASUK SINI");

                this.iridiumreboot_table = new DataTable('#iridiumrebootTable', {
                    data: data.output,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "3%",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                 ${meta.row + 1}
                                </div>
                                `
                            }
                        },
                        {
                            width: "68.5%",
                            data: 'content', className: '', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },

                        {
                            width: "28.5%",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                <div class="p-1 h-100">
                                ${data}
                                </div>
                                `
                            }
                        },


                    ]
                });




            } catch (e) {

            }

        },
        //Initialize Table Interval
        initTable_interval(data) {
            console.log("INIT TABLE INTERVAL");
            console.log(this.iridiuminterval_table);
            console.log("MASUK INIT TABLE REBOOT");
            console.log(data.output)
            try {
                console.log("MASUK SINI");

                this.iridiuminterval_table = new DataTable('#iridiumintervalTable', {
                    data: data.output,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "3%",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                        <div class="p-1 h-100">
                                         ${meta.row + 1}
                                        </div>
                                        `
                            }
                        },
                        {
                            width: "68.5%",
                            data: 'content', className: '', render: (data, type, row, meta) => {
                                return `
                                        <div class="p-1 h-100">
                                        ${data}
                                        </div>
                                        `
                            }
                        },

                        {
                            width: "28.5%",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                        <div class="p-1 h-100">
                                        ${data}
                                        </div>
                                        `
                            }
                        },


                    ]
                });




            } catch (e) {

            }

        },
        //Initialize Table Dev ID
        initTable_devid(data) {
            console.log("INIT TABLE DEVID");
            console.log(this.iridiumdevid_table);
            console.log("MASUK INIT TABLE DEVID");
            console.log(data.output)
            try {
                console.log("MASUK SINI");

                this.iridiumdevid_table = new DataTable('#iridiumdevidTable', {
                    data: data.output,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "3%",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                <div class="p-1 h-100">
                                                 ${meta.row + 1}
                                                </div>
                                                `
                            }
                        },
                        {
                            width: "68.5%",
                            data: 'content', className: '', render: (data, type, row, meta) => {
                                return `
                                                <div class="p-1 h-100">
                                                ${data}
                                                </div>
                                                `
                            }
                        },

                        {
                            width: "28.5%",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                <div class="p-1 h-100">
                                                ${data}
                                                </div>
                                                `
                            }
                        },


                    ]
                });




            } catch (e) {

            }

        },
        //Initialize Table IoT Service
        initTable_iots(data) {
            console.log("INIT TABLE IOT SERVICE");
            console.log(this.iridiumiots_table);
            console.log("MASUK INIT TABLE IOT SERVICE");
            console.log(data.output)
            try {
                console.log("MASUK SINI");

                this.iridiumiots_table = new DataTable('#iridiumiotsTable', {
                    data: data.output,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "3%",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                        <div class="p-1 h-100">
                                                         ${meta.row + 1}
                                                        </div>
                                                        `
                            }
                        },
                        {
                            width: "68.5%",
                            data: 'content', className: '', render: (data, type, row, meta) => {
                                return `
                                                        <div class="p-1 h-100">
                                                        ${data}
                                                        </div>
                                                        `
                            }
                        },

                        {
                            width: "28.5%",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                        <div class="p-1 h-100">
                                                        ${data}
                                                        </div>
                                                        `
                            }
                        },


                    ]
                });




            } catch (e) {

            }

        },
        //Initialize Table GPS
        initTable_gps(data) {
            console.log("INIT TABLE GPS");
            console.log(this.iridiumgps_table);
            console.log("MASUK INIT TABLE GPS");
            console.log(data.output)
            try {
                console.log("MASUK SINI");

                this.iridiumgps_table = new DataTable('#iridiumgpsTable', {
                    data: data.output,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    pageLength: 10,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "3%",
                            data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                                <div class="p-1 h-100">
                                                                 ${meta.row + 1}
                                                                </div>
                                                                `
                            }
                        },
                        {
                            width: "68.5%",
                            data: 'content', className: '', render: (data, type, row, meta) => {
                                return `
                                                                <div class="p-1 h-100">
                                                                ${data}
                                                                </div>
                                                                `
                            }
                        },

                        {
                            width: "28.5%",
                            data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                return `
                                                                <div class="p-1 h-100">
                                                                ${data}
                                                                </div>
                                                                `
                            }
                        },


                    ]
                });




            } catch (e) {

            }

        },
                //Initialize Table DEVICE DATE
                initTable_devdate(data) {
                    console.log("INIT TABLE DEVICE DATE");
                    console.log(this.iridiumdevdate_table);
                    console.log("MASUK INIT TABLE DEVICE DATE");
                    console.log(data.output)
                    try {
                        console.log("MASUK SINI");
        
                        this.iridiumdevdate_table = new DataTable('#iridiumdevdateTable', {
                            data: data.output,
                            scrollCollapse: true,
                            scroller: true,
                            destroy: true,
                            pageLength: 10,
                            deferRender: true,
                            ordering: true,
                            bLengthChange: false,
                            responsive: true,
                            dom: 'lrtip',
                            columns: [
                                {
                                    width: "3%",
                                    data: '', className: 'td_nowrap', render: (data, type, row, meta) => {
                                        return `
                                                                        <div class="p-1 h-100">
                                                                         ${meta.row + 1}
                                                                        </div>
                                                                        `
                                    }
                                },
                                {
                                    width: "68.5%",
                                    data: 'content', className: '', render: (data, type, row, meta) => {
                                        return `
                                                                        <div class="p-1 h-100">
                                                                        ${data}
                                                                        </div>
                                                                        `
                                    }
                                },
        
                                {
                                    width: "28.5%",
                                    data: 'dateTime_local', className: 'td_nowrap', render: (data, type, row, meta) => {
                                        return `
                                                                        <div class="p-1 h-100">
                                                                        ${data}
                                                                        </div>
                                                                        `
                                    }
                                },
        
        
                            ]
                        });
        
        
        
        
                    } catch (e) {
        
                    }
        
                },
        //------------------------------------------------------------------



        //SHOW ON MAP FUNCTIONS
        //------------------------------------------------------------------
        //show on map iridium
        showonMap(id) {
            console.log("IRIDIUM SHOW ON MAP");
            this.is_showonmap = false;
            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {

                if (this.eta_store.tableRoutedetail) {
                    this.eta_store.tableRoutedetail.destroy();
                    this.eta_store.tableRoutedetail = null
                }

                this.is_showonmap = true;
                this.mapiridium_data = null;
                this.mapiridium_data_date = null;
                this.iridium_id = null;
                this.show_range = "Last 24 Hours";
                this.tracking_layer.clearLayers();
                this.target_layer.clearLayers();
                this.link_layer.clearLayers();
                this.fetch_IridiumMap(id);



                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: $("#mapiridium_card").offset().top - 68
                    }, 500);
                }, 10);
                setTimeout(() => {
                    this.fetch_IridiumMap_date("24");
                }, 1000);
            }, 5);
        },
        //close show on map iridium
        closeonMap() {
            this.is_showonmap = null;
            this.mapiridium_id = null;
            this.mapiridium_data = null;
            this.mapiridium_data_date = null;
            this.show_range = "Last 24 Hours";
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.link_layer.clearLayers();
            $('html, body').animate({ scrollTop: '0px' }, 500);

            if (this.eta_store.tableRoutedetail) {
                this.eta_store.tableRoutedetail.destroy();
                this.eta_store.tableRoutedetail = null
            }
        },
        //------------------------------------------------------------------



        //CHANGE RANGE FUNCTIONS
        //------------------------------------------------------------------
        change_range(index) {
            this.show_range = this.range_arr[index].label;
            this.fetch_IridiumMap_date(this.range_arr[index].val);
            this.link_layer.clearLayers();
        },
        //------------------------------------------------------------------



        //MAP ESSENTIALS FUNCTIONS
        //------------------------------------------------------------------
        //Initialize Map
        initializeMap(map_) {
            console.log("MAP INITIALIZED");

            this.iridium_map = map_;
            this.target_layer.addTo(this.iridium_map);
            this.tracking_layer.addTo(this.iridium_map);
            this.link_layer.addTo(this.iridium_map);
            this.search_layer.addTo(this.iridium_map);
            this.iridium_map.scrollWheelZoom.disable();
            var searchLayer = this.search_layer;
            var mymap = this.iridium_map;
            this.eta_store.init_eta(mymap, 'iridium');
            this.eta_store.init_start_eta(mymap, 'iridium', this.mapiridium_data.maptop);
            this.eta_store.init_detail_route(mymap, 'iridium')
            this.eta_store.table_koordinat();
            setTimeout(() => {
                L.Control.geocoder(
                    {
                        defaultMarkGeocode: false

                    }
                ).on('markgeocode', function (e) {
                    console.log(e);
                    console.log(e.geocode.center);
                    console.log(e.geocode.html);
                    mymap.setView(new L.LatLng(e.geocode.center.lat, e.geocode.center.lng), 8);

                    var circle = L.circle([e.geocode.center.lat, e.geocode.center.lng], {
                        color: "#1DC01D",
                        fillColor: "#1DC01D",
                        fillOpacity: 0.0,
                        opacity: 0.0,
                        weight: 0,
                        radius: 1600,
                    }).bindPopup(
                        e.geocode.html.length > 0 ?
                            e.geocode.html : e.geocode.name
                    )

                    circle.on("add", function (event) {
                        event.target.openPopup();
                    });

                    circle.addTo(searchLayer)
                }).addTo(this.iridium_map);
            }, 500);

        },
        //Set View Map
        setview_map() {
            this.iridium_map.setView(new L.LatLng(this.mapiridium_data_date.maptop.lat, this.mapiridium_data_date.maptop.lon), 6);
        },
        //On Map Click
        onmap_click() {
            this.search_layer.clearLayers();
        },
        //Fullscreen Options
        fullscreen_scroll() {
            console.log("CLICK FULL");
            this.iridium_map.invalidateSize();
            if (this.iridium_map.isFullscreen() == true) {
                this.iridium_map.scrollWheelZoom.enable();
            } else {
                this.iridium_map.scrollWheelZoom.disable();
            }
        },
        //Return Icon Moving Marker
        return_track_icon() {
            const svgIcon = L.divIcon({
                html: `
                <svg
                stroke="#0000FF" 
                stroke-width="0"
                viewBox="0 0 57 57"
                xmlns="http://www.w3.org/2000/svg"
                >
                <path d="M50.8,56.4L30.6,43.7c-1.3-0.8-3-0.8-4.3,0L6.2,56.4c-3.4,2.2-7.5-1.6-5.7-5.2L24.9,2.2c1.5-3,5.7-3,7.2,0l24.5,48.9
                C58.4,54.8,54.3,58.5,50.8,56.4z" fill="#0000FF"></path>
                </svg>`,
                className: "",
                iconSize: [57 / 3.5, 57 / 3.5],
                iconAnchor: [57 / 3.5 / 2, 57 / 3.5 / 2],
            });
            return svgIcon
        },
        //Return Icon Iridium Marker
        return_icon(maptop) {
            let svgIcon;
            svgIcon = L.divIcon({
                html: `
                    <svg
                    stroke="#FFF" 
                    stroke-width="10"
                    viewBox="0 0 40 100"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <path d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" fill="`+ maptop.fill + `"></path>
                    </svg>`,
                className: "",
                iconSize: [40 / 5, 100 / 5],
                iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
            });

            return svgIcon;


        },
        //Popup Tracking
        popup_tracking(index) {
            console.log(index);
            this.link_layer.clearLayers();
            var circle = L.circle([this.marker_tracking[index].lat, this.marker_tracking[index].lng], {
                color: "#1DC01D",
                fillColor: "#1DC01D",
                fillOpacity: 0.0,
                opacity: 0.0,
                weight: 0,
                radius: 1600,
            }).bindPopup(
                "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lat +
                "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                this.marker_tracking[index].lng +
                "</td></tr><tr>" +
                "<td><b>DMS</b></td><td>:</td>" +
                "<td>" +
                this.marker_tracking[index].dms +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td><b>Speed</b></td>" +
                "<td>:</td>" +
                " <td>" +
                this.marker_tracking[index].speed +
                "&nbsp;knots </td>" +
                "</tr>" +
                "<tr>" +
                " <td><b>Heading</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].heading +
                "</td>" +
                " </tr>" +
                "<tr>" +
                "<td><b>Last Update</b></td>" +
                "<td>:</td>" +
                "<td>" +
                this.marker_tracking[index].updated +
                "</td>" +
                "</tr>" +
                "</table>"
            )

            circle.on("add", function (event) {
                event.target.openPopup();
            });

            this.iridium_map.setView(new L.LatLng(this.marker_tracking[index].lat, this.marker_tracking[index].lng), 6);

            circle.addTo(this.link_layer)


            $('html, body').animate({ scrollTop: '0px' }, 5);
            setTimeout(() => {
                setTimeout(() => {
                    $('html, body').animate({
                        scrollTop: ($("#map_iridium").offset().top) - 68
                    }, 500);
                }, 10);
            }, 10);
        },
        //------------------------------------------------------------------



        //MOVING MARKER FUNCTIONS
        //-----------------------------------------------------------------
        //fetch tracking 24 hour marker clicked
        moveTracking(data_tracking) {
            clearInterval(this.interval_tracking);
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            console.log("MASUK KE MOVE TRACKING");
            console.log(data_tracking);
            this.marker_tracking = data_tracking;
            this.tracking_layer.clearLayers();
            this.target_layer.clearLayers();
            this.moving_marker = null;

            if (data_tracking.length > 0) {
                this.moving_marker = L.marker([data_tracking[0].lat, data_tracking[0].lng], {
                    icon: this.return_track_icon(),
                    rotationAngle: data_tracking[0].heading,
                }).addTo(this.target_layer);

                var firstpolyline = new L.Polyline(data_tracking, {
                    color: '#FEE800',
                    weight: 6,
                    opacity: 0.5,
                    smoothFactor: 1
                });
                this.tracking_layer.addLayer(firstpolyline);
                var mom = this
                var i = data_tracking.length
                setTimeout(() => {
                    data_tracking.forEach(function (coord) {
                        i--
                        if (i != 0) {
                            if (mom.authStore.userAllowed && !mom.authStore.userAllowed.allowed['hide marker dot']) {
                                var circle = L.circle([coord.lat, coord.lng], {
                                    color: "#1DC01D",
                                    fillColor: "#1DC01D",
                                    fillOpacity: 0.3,
                                    opacity: 0.6,
                                    weight: 2,
                                    radius: 1600,
                                }).bindPopup(
                                    "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
                                    coord.lat +
                                    "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
                                    coord.lng +
                                    "</td></tr><tr>" +
                                    "<td><b>DMS</b></td><td>:</td>" +
                                    "<td>" +
                                    coord.dms +
                                    "</td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    "<td><b>Speed</b></td>" +
                                    "<td>:</td>" +
                                    " <td>" +
                                    coord.speed +
                                    "&nbsp;knots </td>" +
                                    "</tr>" +
                                    "<tr>" +
                                    " <td><b>Heading</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.heading +
                                    "</td>" +
                                    " </tr>" +
                                    "<tr>" +
                                    "<td><b>Last Update</b></td>" +
                                    "<td>:</td>" +
                                    "<td>" +
                                    coord.updated +
                                    "</td>" +
                                    "</tr>" +
                                    "</table>"
                                ).addTo(mom.target_layer)
                            }
                        }
                    });
                }, 1600);
                this.initialdata_moving();
                this.movingStart();
            }


        },
        //moving marker
        initialdata_moving() {
            this.tracking_path = this.marker_tracking.slice()
            if (this.tracking_path[0]) {
                this.tracking_latLng = L.latLng([this.tracking_path[0].lat, this.tracking_path[0].lng])
                this.tracking_rotation = this.tracking_path[0].heading
            }

        },
        //moving marker
        movingStart() {
            var mother = this
            this.interval_tracking = setInterval(function () {
                mother.simulate()
            }, 250);
        },
        //Simulate
        simulate() {
            if (this.tracking_path && this.tracking_path.length > 0) {
                let point = this.tracking_path.shift()
                this.tracking_latLng = L.latLng([point.lat, point.lng])
                this.tracking_rotation = point.heading
                this.moving_marker.setLatLng(this.tracking_latLng)
                this.moving_marker.slideTo(this.tracking_latLng, {
                    duration: 500,
                });
                this.moving_marker.options.rotationAngle = this.tracking_rotation;
            } else {
                this.target_layer.removeLayer(this.moving_marker);
                clearInterval(this.interval_tracking);
                this.tracking_path = null;
            }

        },
        //------------------------------------------------------------------

        //OTHER FUNCTION
        //-----------------------------------------------------------------
        return_dropdown(index) {

            var obj = '';
            for (let i = 0; i < this.skychat_user_role_arr.length; i++) {
                obj = obj + `<li><a onclick="roledown_click('` + this.skychat_user.vesselUsers[index].id + `','` + index + `','` + this.skychat_user_role_arr[i].id + `')"  class="dropdown-item" href="#">${this.skychat_user_role_arr[i].label}</a></li>`;
            }
            return obj;
        },
        return_checkbox(index) {
            var obj = '';
            var mom = this;
            this.idxs = this.idxs + 1;


            console.log("INDEX KE :" + this.idxs);
            console.log("INDEX TABLE :" + index);
            console.log("ID :" + this.skychat_user.vesselUsers[index].id);
            console.log("ISENABLED :" + this.skychat_user.vesselUsers[index].skychat.isEnabled);
            console.log("-----------");


            if (this.skychat_user.vesselUsers[index].skychat.isEnabled != false) {
                console.log(this.skychat_user_checked.includes(this.skychat_user.vesselUsers[index].id))

                if (this.skychat_user_checked.includes(this.skychat_user.vesselUsers[index].id) == false) {
                    console.log("PUSH CHECKED")
                    this.skychat_user_checked.push(this.skychat_user.vesselUsers[index].id)
                }

                obj = `<input id="check_sky_user_` + mom.skychat_user.vesselUsers[index].id + `" onchange="onchange_click('` + mom.skychat_user.vesselUsers[index].id + `','` + index + `')" class="form-check-input pointer_cursor" type="checkbox" value="" checked ${this.skychat_user.latest_status == 'ON AIR' ? ' disabled' : ''} >`
            } else {
                if (this.skychat_user_unchecked.includes(this.skychat_user.vesselUsers[index].id) == false) {
                    console.log("PUSH UNCHECKED")
                    this.skychat_user_unchecked.push(this.skychat_user.vesselUsers[index].id)
                }
                if (this.skychat_user.latest_status == 'ON AIR') {
                    obj = `<input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled>`
                } else {
                    obj = `<input  id="check_sky_user_` + mom.skychat_user.vesselUsers[index].id + `" onchange="onchange_click('` + mom.skychat_user.vesselUsers[index].id + `','` + index + `')" class="form-check-input pointer_cursor" type="checkbox" value="">`
                }

            }
            console.log("---------");
            return obj;
        },
        close_speedhistory() {
            if (this.speedhistory_table != null) {
                this.speedhistory_table.clear();
                this.speedhistory_table = null;
                console.log("HISTORY TABLE IS CLEARED");
            }
            this.speedhistory_data = null;
        },
        clear_all_ir_notif() {
            this.speedhistory_notifarr = []
            this.notification_store.clear_notif(this.speedhistory_data.notifications)
            var notif_data = this.speedhistory_data.notifications
            for (let i = 0; i < notif_data.length; i++) {
                notif_data[i].unRead = false
            }
            this.reinit_speedhistory(notif_data)
        },
        clear_ir_notif() {
            var mom = this;
            this.notification_store.clear_selected_notif(this.speedhistory_notifarr)
            var notif_data = this.speedhistory_data.notifications
            for (let i = 0; i < notif_data.length; i++) {
                if (mom.speedhistory_notifarr.includes(notif_data[i].msg_id.toString())) {
                    notif_data[i].unRead = false
                }
            }
            this.speedhistory_notifarr = []
            this.reinit_speedhistory(notif_data);
        },
        reinit_speedhistory(notif_data) {
            this.speedhistory_table.clear();
            this.speedhistory_table = null;
            this.initTable_history(notif_data);
        },
        oncheck_notif(id) {
            if ($('#ir_checknotif_' + id).prop('checked')) {
                console.log("CHECK NOTIF TRUE")
                this.speedhistory_notifarr.push(id);
            } else {
                console.log("CHECK NOTIF FALSE")
                this.speedhistory_notifarr = this.speedhistory_notifarr.filter(e => e !== id);
            }
        },
        //-----------------------------------------------------------------


        // USER SKYCHAT FUNCTIONS
        //------------------------------------------------------------------
        onchange_click(id, index) {
            console.log("CHECK SKY USER");
            console.log(id);
            console.log("SKYCHAT USER CHECKBOX DI CLICK");
            console.log(index);
            console.log(this.skychat_user_post[index].skychat.isEnabled)
            var id_ = parseInt(id);
            this.skychat_user_post[index].skychat.isEnabled = $('#check_sky_user_' + id_).prop('checked');
            console.log(this.skychat_user_post[index].skychat.isEnabled)

            if ($('#check_sky_user_' + id_).prop('checked')) {
                console.log("SKYCHAT USER CHECKED")
                if (!this.skychat_user_checked.includes(id_)) {
                    this.skychat_user_checked.push(id_)
                    console.log("SKYCHAT USER CHECKED PUSH KE ARRAY")
                }

                if (this.skychat_user_unchecked.includes(id_)) {
                    this.skychat_user_unchecked = this.skychat_user_unchecked.filter(e => e !== id_)
                    console.log("SKYCHAT USER UNCHECKED REMOVE DARI ARRAY")
                }

            } else {
                console.log("SKYCHAT USER UNCHECKED")
                if (!this.skychat_user_unchecked.includes(id_)) {
                    this.skychat_user_unchecked.push(id_)
                    console.log("SKYCHAT USER UNCHECKED PUSH KE ARRAY")
                }

                if (this.skychat_user_checked.includes(id_)) {
                    this.skychat_user_checked = this.skychat_user_checked.filter(e => e !== id_)
                    console.log("SKYCHAT USER CHECKED REMOVE DARI ARRAY")
                }

            }


            if (this.skychat_user_checked.length >= 3) {
                for (let i = 0; i < this.skychat_user_unchecked.length; i++) {
                    $('#check_sky_user_' + this.skychat_user_unchecked[i]).attr("disabled", true);
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).addClass("not_allowed_cursor");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).attr('title', 'Max Active SKYCHAT User of 3 has Exceeded, Please Disabled other first.');
                }
                $('#skychat_no_username_check').attr('title', 'Max Active SKYCHAT User of 3 has Exceeded, Please Disabled other first.');
            } else {
                for (let i = 0; i < this.skychat_user_unchecked.length; i++) {
                    $('#check_sky_user_' + this.skychat_user_unchecked[i]).removeAttr("disabled");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).removeClass("not_allowed_cursor");
                    $('#skychat_user_checkdiv_' + this.skychat_user_unchecked[i]).attr('title', '');
                }
                $('#skychat_no_username_check').attr('title', 'Username Required');
            }
        },
        roledown_click(id, index, value) {
            console.log("CHANGE ROLE ID SKY USER");
            console.log(id);
            console.log("SKYCHAT USER CHECKBOX DI CLICK");
            console.log(index);
            console.log(this.skychat_user_post[index].id);
            console.log(this.skychat_user_post[index].skychat.role_id)
            this.skychat_user_post[index].skychat.role_id = value;
            console.log(this.skychat_user_post[index].skychat.role_id);
            $("#skychat_user_dropdown_label_" + this.skychat_user_post[index].id).html(this.skychat_user_role_arr[value - 1].label)

        },
        //Change Range CDR
        changerange_cdr(val, label) {
            this.cdr_range = val
            this.cdr_rangelabel = label
            this.fetch_cdr(this.cdr_item)
        },
        //Change Range CDR
        changerange_cdradmin(val, label) {
            this.cdradmin_range = val
            this.cdradmin_rangelabel = label
            this.fetch_cdradmin(this.cdradmin_item)
        },
        //------------------------------------------------------------------

        // UNINITIALIZE DATA WHEN LOGOUT
        //------------------------------------------------------------------
        speedenable_change(value, cat) {

            console.log($('#iridium_speedalert_enable_yes').prop('checked'));
            console.log($('#iridium_speedalert_enable_no').prop('checked'));

            if (cat.toUpperCase() == 'YES') {
                if ($('#iridium_speedalert_enable_yes').prop('checked') == true) {
                    $("#iridium_speedalert_enable_no").prop('checked', false);

                } else if ($('#iridium_speedalert_enable_no').prop('checked') == true) {
                    $("#iridium_speedalert_enable_yes").prop('checked', false);
                }
            } else {
                if ($('#iridium_speedalert_enable_no').prop('checked') == true) {
                    $("#iridium_speedalert_enable_yes").prop('checked', false);

                } else if ($('#iridium_speedalert_enable_yes').prop('checked') == true) {
                    $("#iridium_speedalert_enable_no").prop('checked', false);
                }
            }
            this.speedalert_data.isEnabled = value
            console.log(this.speedalert_data.isEnabled);
        },
        //Uninit Data 
        uninit_data() {
            this.iridium_table = null;
            this.iridiumuser_table = null;
            this.iridiumtrack_table = null;
            this.is_showonmap = null;
            this.search_iridium = null;
            this.search_iridiumuser = null;
            this.search_iridiumtrack = null;
            this.iridium_data = null;
            this.last_center = [-1.4312313766684295, 119.1498789812993];
            this.last_zoom = 6;
            this.mapiridium_data = null;
            this.mapiridium_data_date = null;
            this.page_off = 10;
            this.page_off_tracking = 10;
            this.show_range = "Last 24 Hours";
            this.export_range = "24";
            this.mapiridium_id = null;
            this.loading_range = false;
            this.tracking_layer = L.layerGroup();
            this.target_layer = L.layerGroup();
            this.link_layer = L.layerGroup();
            this.search_layer = L.layerGroup();
            this.marker_tracking = [];
            this.moving_marker = null;
            this.tracking_path = null;
            this.tracking_latLng = null;
            this.tracking_rotation = null;
            this.interval_tracking = null;
            this.iridium_map = null;
            this.date_range = null;
            this.isBottom = false;
            this.range_arr = [
                { label: "Today", val: "today" },
                { label: "Last 24 Hours", val: "24" },
                { label: "Last 7 Days", val: "7" },
                { label: "Last 30 Days", val: "30" },
                { label: "This Week", val: "week" },
                { label: "This Month", val: "month" },
                { label: "Last Month", val: "lmonth" },
                { label: "Custom Range", val: "24" },
            ];
            this.page_off_arr = [
                10,
                25,
                50,
                100
            ];

            this.th_iridium_arr = [
                "No",
                "Vessel Name",
                "Company",
                "IMEI",
                "Rateplan",
                ""
            ];
            this.th_iridium_track_arr = [
                "#",
                "Latitude,Longitude",
                "DMS",
                "Speed\n(Knots)",
                "Heading\n(Degree)",
                "Distance\n(Nmi)",
                "Date/Time\n(UTC)",
                "Date/Time\n(GMT+7)"
            ];
            this.th_iridium_user_arr = [
                "#",
                "Name",
                "Email",
                "Role",
                "Last Login"
            ];
            this.td_iridium_arr = [
                0,
                0,
                0,
                0,
                0,
                24.5
            ];
            this.td_iridium_track_arr = [
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
            ];

            this.th_iridium_skychat_arr = [
                "Name",
                "Email",
                "Username",
                "Role",
                "Enable",
            ];
            this.td_iridium_skychat_arr = [
                20,
                20,
                20,
                20,
                20,
            ];

            this.chatroom_data = null;
            this.chatroom_from = null;
            this.chatroom_to = null;
            this.chatroom_body = '';
            this.chatroom_sending = false;
            this.chatroom_messages = null;
            this.chatroom_thurayanumber = null;
            this.chatroom_item = null;
            this.chatroom_interval = null;
            this.chatroom_search = '';

            this.skychat_user = null;
            this.skychat_user_item = null;
            this.skychat_user_table = null;
            this.skychat_user_checked = [];
            this.skychat_user_unchecked = [];
            this.skychat_user_post = null;
            this.skychat_user_loading = false;

            this.skychat_user_role_arr = [
                {
                    label: 'Full Privilege',
                    id: 1,
                },
                {
                    label: 'Read Write Chatboard',
                    id: 2,
                },
                {
                    label: 'Readonly',
                    id: 3,
                }
            ];
            this.idxs = 0;
        }
        //------------------------------------------------------------------
    }
})
