import { defineStore } from 'pinia';
import api from "@/plugins/axios";
import $ from "jquery";
import DataTable from 'datatables.net-dt';
import * as bootstrap from 'bootstrap'
import { useSailinkStore, useSailoggerStore, useToastStore, useThurayaStore, useIridiumStore, useAuthStore } from '../index';



export const useNewsStore = defineStore({
    id: 'news',
    state: () => ({

        sailogger_store: useSailoggerStore(),
        sailink_store: useSailinkStore(),
        thuraya_store: useThurayaStore(),
        iridium_store: useIridiumStore(),
        toast_store: useToastStore(),
        authStore: useAuthStore(),

        sailogger_data: null,
        sailink_data: null,
        thuraya_data: null,
        iridium_data: null,

        news_table: null,
        newsurgent_table: null,

        search_news: null,
        search_urgent: null,

        news_data: null,
        urgent_data: null,

        page_off: 10,
        urgent_page_off: 10,


        page_off_arr: [
            10,
            25,
            50,
            100
        ],
        urgent_page_off_arr: [
            10,
            25,
            50,
            100
        ],



        th_news_arr: [
            "News List",
        ],

        td_news_arr: [
            0,
        ],


        th_urgent_arr: [
            "News List",
        ],

        td_urgent_arr: [
            0,
        ],

        popover: null,
        popover_id: '',
        popover_step: null,
        popover_mark: null,

        fuelusage_idx: null,
        rpmalert_idx: null,
        speedalert_idx: null,
        speedalert_page: null,
        speedalert_modal: null,
    }),

    actions: {



        // DATA FETCH FUNCTIONS
        //------------------------------------------------------------------
        //Fetch News Active Data
        async fetch_news() {
            await api.get(`api/v1/navplus/news/fetch`, {}
            ).then((res) => {
                console.log("NEWS RESPONSE");
                console.log(res);
                if (this.search_news == null || this.search_news.length == 0) {
                    this.news_data = res.data.data;
                    if (!this.news_table) {
                        this.initTable(res.data.data.news_active);
                    }

                }


            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch News Urgent Data
        async fetch_news_urgent() {
            await api.get(`api/v1/navplus/news/fetch`, {}
            ).then((res) => {
                console.log("NEWS URGENT RESPONSE");
                console.log(res);
                if (this.search_urgent == null || this.search_urgent.length == 0) {
                    this.urgent_data = res.data.data;
                    this.initTable_urgent(res.data.data.news_urgent);
                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch News Detail
        async fetch_news_detail(id) {
            await api.get(`api/v1/navplus/news/fetch/` + id, {}
            ).then((res) => {
                console.log("NEWS DETAIL RESPONSE");
                console.log(res);
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Sailogger for News
        async fetch_sailogger() {

            await api.get(`/api/v1/navplus/iot`, {}
            ).then((res) => {
                console.log("SAILOGGER RESPONSE");
                console.log(res);
                this.sailogger_data = res.data.data.vessels;
                var mom = this;
                for (let key in res.data.data.vessels) {
                    console.log(key)
                    console.log(mom.sailogger_data[key]);
                    if (mom.sailogger_data[key].devices.rpm || mom.sailogger_data[key].devices.iot) {
                        if (mom.fuelusage_idx == null) {
                            mom.fuelusage_idx = key
                            $('.lmbtn_4').prop("disabled", false)
                        }
                    }

                    if (mom.sailogger_data[key].devices.rpm == true) {
                        if (mom.rpmalert_idx == null) {
                            mom.rpmalert_idx = key
                            $('.lmbtn_3').prop("disabled", false)
                        }
                    }
                }
            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Sailink for News
        async fetch_sailink() {
            await api.get(`/api/v1/navplus/sailink`, {}
            ).then((res) => {
                console.log("SAILINK RESPONSE");
                console.log(res);
                this.sailink_data = res.data.data.vessels;
                var mom = this;

                if (mom.sailink_data.length > 0) {
                    if (mom.speedalert_idx == null) {
                        mom.speedalert_idx = 0,
                            mom.speedalert_page = 1,
                            mom.speedalert_modal = 'sailinkspeedNotifModal'
                        $('.lmbtn_1').prop("disabled", false)
                    }
                }

                this.fetch_thuraya();

            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Iridium for News
        async fetch_iridium() {
            await api.get(`/api/v1/navplus/iridium`, {}
            ).then((res) => {
                console.log("IRIDIUM RESPONSE");
                console.log(res);

                this.iridium_data = res.data.data.vessels;
                var mom = this;

                for (let key in res.data.data.vessels) {
                    if (mom.iridium_data[key].skychat) {
                        if (mom.speedalert_idx == null) {
                            mom.speedalert_idx = key,
                                mom.speedalert_page = 9,
                                mom.speedalert_modal = 'iridiumspeedNotifModal'
                            $('.lmbtn_1').prop("disabled", false)
                        }
                    }
                }


            }).catch((err) => {
            }).then(() => {
            });
        },
        //Fetch Thuraya for News
        async fetch_thuraya() {
            await api.get(`/api/v1/navplus/thuraya`, {}
            ).then((res) => {
                console.log("thuraya RESPONSE");
                console.log(res);

                this.thuraya_data = res.data.data.vessels;
                var mom = this;

                if (mom.thuraya_data.length > 0) {
                    if (mom.speedalert_idx == null && mom.authStore.userAllowed.allowed['view tracking']) {
                        mom.speedalert_idx = 0,
                            mom.speedalert_page = 4,
                            mom.speedalert_modal = 'thurayaSpeedNotifModal'
                        $('.lmbtn_1').prop("disabled", false)
                    }
                }



                this.fetch_iridium();
            }).catch((err) => {
            }).then(() => {
            });
        },
        //------------------------------------------------------------------



        //TABLE SEARCH & PAGE FUNCTIONS
        //------------------------------------------------------------------
        //search manifests table
        newsTable_search() {
            console.log(this.search_news);
            console.log(this.news_table);

            if (this.search_news != null && this.search_news.length > 0) {
                this.news_table.search(this.search_news).draw();
            }
        },
        clear_newsTable_search() {
            this.search_news = ''
            this.news_table.search(this.search_news).draw();
        },
        //data shorting newss table 
        newsTable_showof(page) {
            console.log("PAGE : " + page);
            this.news_table.page.len(page).draw()
            this.page_off = page;
        },
        urgentTable_search() {
            console.log(this.search_urgent);
            console.log(this.newsurgent_table);

            if (this.search_urgent != null && this.search_urgent.length > 0) {
                this.newsurgent_table.search(this.search_urgent).draw();
            }
        },
        clear_urgentTable_search() {
            this.search_urgent = ''
            this.newsurgent_table.search(this.search_urgent).draw();
        },
        //data shorting newss table 
        urgentTable_showof(page) {
            console.log("PAGE : " + page);
            this.newsurgent_table.page.len(page).draw()
            this.urgent_page_off = page;
        },
        //------------------------------------------------------------------


        //INITIALIZE TABLE FUNCTIONS
        //------------------------------------------------------------------
        //Initable News Active
        initTable(data_) {
            var root = this
            if (this.news_table) {
                console.log("MASUK CLEAR TABLE");
                root.news_table = null;
                // this.news_table.clear().draw();
                root.initTable(this.news_data.news_active);
            } else {
                console.log("MASUK INIT TABLE");
                this.news_table = new DataTable('#newsTable', {
                    data: data_,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: 'title', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                            <div class="p-1 w-100 h-100">
                             <div class="card w-100 rounded-none">

                             <div class="card-body">
                             <div class="d-flex">
                             <span class="text-primary"><b>${data}</b></span>
                             <span class="badge text-bg-danger  ms-3">IMPORTANT</span>
                             </div>
                             
                             <p class="text-secondary">${data_[meta.row].dateTime}</p>
                             <div style="width:50em" class="text-wrap">
                             ${data_[meta.row].preview}
                             </div>
                             </div>
                            
                             <div class="card-footer text-end bg-white">
                                <button disabled id="learmore_btn" class="btn btn-primary rounded-none lmbtn_${data_[meta.row].type}">Learn More</button>
                             </div>

                             </div>
                            </div>
                            `
                            }
                        },


                    ]
                });



                var mom = this;
                this.fetch_sailogger();
                this.fetch_sailink();


                this.news_table.on('click', '#learmore_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.news_table.row(tr);
                    console.log(row.data());

                    mom.onclick_learnmore(row.data(), 0);
                });
            }
        },
        //Initable News Urgent
        //Initable Manifest
        initTable_urgent(data_) {
            var root = this
            if (this.newsurgent_table) {
                console.log("MASUK CLEAR TABLE");
                root.newsurgent_table = null;
                root.initTable(this.urgent_data.news_urgent);
            } else {
                console.log("MASUK INIT TABLE");
                this.newsurgent_table = new DataTable('#newsUrgentTable', {
                    data: data_,
                    scrollCollapse: true,
                    scroller: true,
                    destroy: true,
                    deferRender: true,
                    ordering: true,
                    bLengthChange: false,
                    responsive: true,
                    dom: 'lrtip',
                    columns: [
                        {
                            width: "",
                            data: 'title', className: 'p-0 no-wrap', render: (data, type, row, meta) => {
                                return `
                                    <div class="p-1 w-100 h-100">
                                     <div class="card w-100 rounded-none">
        
                                     <div class="card-body">
                                     <div class="d-flex">
                                     <span class="text-primary"><b>${data}</b></span>
                                     <span class="badge text-bg-danger  ms-3">IMPORTANT</span>
                                     </div>
                                     
                                     <p class="text-secondary">${data_[meta.row].dateTime}</p>
                                     <div style="width:30em" class="text-wrap">
                                     ${data_[meta.row].preview}
                                     </div>
                                     </div>
                                    
                                     <div class="card-footer text-end bg-white">
                                        <button disabled id="learmore_btn" type="button" class="btn btn-primary rounded-none lmbtn_${data_[meta.row].type}">Learn More</button>
                                     </div>
        
                                     </div>
                                    </div>
                                    `
                            }
                        },


                    ]
                });


                var mom = this;
                this.fetch_sailogger();
                this.fetch_sailink();

                this.newsurgent_table.on('click', '#learmore_btn', function (e) {
                    let tr = e.target.closest('tr');
                    let row = mom.newsurgent_table.row(tr);
                    console.log(row.data());
                    mom.onclick_learnmore(row.data(), 1);
                });
            }
        },
        //------------------------------------------------------------------

        //OTHER FUNCTIONS
        //-------------------------------------------------------------------
        onclick_learnmore(item, isurgent) {
            console.log(item.type);
            $('html, body').animate({ scrollTop: '0px' }, 5);
            if (isurgent > 0) {
                $('#urgentclose_btn').click();
            }
            this.fetch_news_detail(item.id);

            switch (item.type) {
                case 1:
                    window.onclick_swapComponent(this.speedalert_page);
                    setTimeout(() => {
                        this.coachmark(3, 1)
                    }, 500);
                    break;
                case 3:
                    //RPM Alert
                    window.onclick_swapComponent(6);
                    setTimeout(() => {
                        this.coachmark(2, 1)
                    }, 500);
                    break;
                case 4:
                    //Fuel Calculator
                    window.onclick_swapComponent(6);
                    setTimeout(() => {
                        this.coachmark(1, 1)
                    }, 500);
                    break;

            }
        },

        popover_show(title, content, button) {
            $('#popover_cover').removeClass('d-none');
            $('#popover_cover').addClass('d-block');
            this.popover = new bootstrap.Popover(document.getElementById(this.popover_id), {
                html: true,
                sanitize: false,
                title: `<div style="font-size:0.9em" class="d-flex justify-content-between w-100">${title} <button type="button" onclick="popover_close()" class="btn-close ms-2" aria-label="Close"></button></div>`,
                content: `
                    <div class="d-block">
                    ${content}
                    <hr>
                    <div class="d-flex justify-content-between w-100">
                    <div></div>
                    <div>${button}</div>
                    </div>
                    </div>`,
                trigger: "manual",
                placement: 'left',
            })
            this.popover.show()
        },

        popover_hide() {
            this.popover.hide()
            $(".popover").remove();
            $('#popover_cover').addClass('d-none');
            $('#popover_cover').removeClass('d-block');
            $('.hightlight').removeClass('hightlight');
        },

        popover_close() {
            this.popover_hide();
            this.popover = null;
            this.popover_id = '';
            this.popover_step = null;
            this.popover_mark = null;
        },

        //------------------------------------------------------------------

        //COACHMARKS 
        //------------------------------------------------------------------
        //COACHMARKS
        coachmark(mark, step) {
            this.popover_mark = mark;
            console.log("MARK DI CLICK ==========================");

            switch (mark) {
                case 1:
                    this.coachmark_fuel(step)
                    break;
                case 2:
                    this.coachmark_rpm(step)
                    break;
                case 3:
                    this.coachmark_speed(step)
                    break;
            }
        },
        //COACHMARKS FUEL USAGE
        coachmark_fuel(step) {

            switch (step) {
                case 1:
                    if (this.popover_step > step) {
                        $('#close_fuel_btn').click()
                        this.popover_hide()
                    }
                    $("#popover_cover").css({ "z-index": "1100" });
                    this.popover_id = 'fueldiv_' + this.fuelusage_idx
                    $('#' + this.popover_id).addClass('hightlight');
                    var title = 'Choose Button'
                    var content = `Click <span class="badge text-bg-primary rounded-none"><i class="fas fa-wrench me-2"></i>Fuel Usage Calculator</span> button to open the fuel usage calculator dialog.`
                    var button = `<button onclick="coachmark(1,2)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_show(title, content, button)
                    this.popover_step = step;
                    break;
                case 2:
                    var timeout = 0;
                    if (this.popover_step < step) {
                        $('.fuelbtn_' + this.fuelusage_idx).click()
                        timeout = 1000
                    }
                    $("#popover_cover").css({ "z-index": "1110" });
                    this.popover_hide()
                    this.popover_id = 'fuel_setting_engine'

                    var title = 'Change The Configuration'
                    var content = `Change the configuration according to your needs`
                    var button = `<button onclick="coachmark(1,1)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button type="button" onclick="coachmark(1,3)" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    setTimeout(() => {
                        this.popover_show(title, content, button);
                        $('#fuel_setting').addClass('hightlight');
                    }, timeout);
                    break;
                case 3:
                    this.popover_hide()
                    this.popover_id = 'fuel_daterange_input'
                    $('#fuel_daterange_input').addClass('hightlight');
                    var title = 'Choose Date Range'
                    var content = `which RPM data will be used to be calculated`
                    var button = `<button onclick="coachmark(1,2)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(1,4)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 4:
                    this.popover_hide()
                    this.popover_id = 'fuel_enginemodel_input'
                    $('#fuel_enginemodel_input').addClass('hightlight');
                    var title = 'Input Engine Model'
                    var content = `Engine Model Name, new or previously used`
                    var button = `<button onclick="coachmark(1,3)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(1,5)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 5:
                    this.popover_hide()
                    this.popover_id = 'fuel_engine_save'
                    $('#fuel_engine_save').addClass('hightlight');
                    var title = 'Save Button'
                    var content = `Use to save Engine Data configuration`
                    var button = `<button onclick="coachmark(1,4)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(1,6)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 6:
                    this.popover_hide()
                    this.popover_id = 'fuel_engine_load'
                    $('#fuel_engine_load').addClass('hightlight');
                    var title = 'Load Button'
                    var content = `Use to load previously used Engine Data configuration`
                    var button = `<button onclick="coachmark(1,5)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(1,7)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 7:
                    this.popover_hide()
                    this.popover_id = 'fuel_engine_config'
                    $('#fuel_engine_config').addClass('hightlight');
                    var title = 'Engine Data Configuration'
                    var content = `Fill in Engine Data Configuration with RPM Average Range and estimated Fuel Rate Usage for its Range`
                    var button = `<button onclick="coachmark(1,6)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(1,8)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break
                case 8:
                    this.popover_hide()
                    this.popover_id = 'fuel_calculate_button'
                    $('#fuel_calculate_button').addClass('hightlight');
                    var title = 'Calculate Button'
                    var content = `Press Calculate Usage to start calculating using the inputted Engine Data Configuration`
                    var button = `<button onclick="coachmark(1,7)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(1,9)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break
                case 9:
                    this.popover_hide()
                    this.popover_id = 'Fuel_Usage_History'
                    $('#Fuel_Usage_History').addClass('hightlight');
                    var title = 'Fuel Usage History'
                    var content = `History of previously Done Calculation and Details of said Calculation`
                    var button = `<button onclick="coachmark(1,8)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(1,10)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    $('#modal_fuelusage').animate({
                        scrollTop: ($("#Fuel_Calculation").offset().top) - 60
                    }, 500);
                    this.popover_show(title, content, button);
                    break
                case 10:
                    this.popover_hide()
                    this.popover_close()
                    this.toast_store.notif(`Congrat's you finished it!`, 'That is all for Fuel Usage Calculator Guide', 'bg-dangernav', 'text-dark')
                    break
            }
        },
        //COACHMARKS RPM ALERT
        coachmark_rpm(step) {
            switch (step) {
                case 1:
                    if (this.popover_step > step) {
                        $('#close_rpmalert_btn').click()
                        this.popover_hide()
                    }
                    $("#popover_cover").css({ "z-index": "1100" });
                    this.popover_id = 'rpmalertdiv_' + this.rpmalert_idx
                    $('#' + this.popover_id).addClass('hightlight');
                    var title = 'Choose Button'
                    var content = `Click <span class="badge text-bg-primary rounded-none"><i class="fas fa-wrench me-2"></i>RPM Alert</span> button to open the RPM Alert setting.`
                    var button = `<button onclick="coachmark(2,2)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_show(title, content, button)
                    this.popover_step = step;
                    break;
                case 2:
                    var timeout = 0;
                    if (this.popover_step < step) {
                        $('.rpmalertbtn_' + this.rpmalert_idx).click()
                        timeout = 1000
                    }
                    $("#popover_cover").css({ "z-index": "1110" });
                    this.popover_hide()
                    this.popover_id = 'rpm_alert_setpop'

                    var title = 'Change The Configuration'
                    var content = `Change the configuration according to your needs`
                    var button = `<button onclick="coachmark(2,1)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button type="button" onclick="coachmark(2,3)" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    setTimeout(() => {
                        this.popover_show(title, content, button);
                        $('#rpm_alert_setting').addClass('hightlight');
                    }, timeout);
                    break;
                case 3:
                    this.popover_hide()
                    this.popover_id = 'rpm_alert_enable'
                    $('#rpm_alert_enable').addClass('hightlight');
                    var title = 'Enable or Disable'
                    var content = `Enable or disable RPM alert notification`
                    var button = `<button onclick="coachmark(2,2)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(2,4)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 4:
                    this.popover_hide()
                    this.popover_id = 'rpm_alert_minimum'
                    $('#rpm_alert_minimum').addClass('hightlight');
                    var title = 'Minimum RPM Limit'
                    var content = `Input the minimum RPM limit`
                    var button = `<button onclick="coachmark(2,3)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(2,5)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 5:
                    this.popover_hide()
                    this.popover_id = 'rpm_alert_maximum'
                    $('#rpm_alert_maximum').addClass('hightlight');
                    var title = 'Maximum RPM Limit'
                    var content = `Input the maximum RPM limit`
                    var button = `<button onclick="coachmark(2,4)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(2,6)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 6:
                    this.popover_hide()
                    this.popover_id = 'rpm_alert_emails'
                    $('#rpm_alert_emails').addClass('hightlight');
                    var title = 'Input Email Adresses'
                    var content = `Email Contacts that will receive RPM Alert Notification when RPM is Over/Under Determined Limit Range`
                    var button = `<button onclick="coachmark(2,5)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(2,7)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 7:
                    this.popover_hide()
                    this.popover_id = 'rpm_alert_submit'
                    $('#rpm_alert_submit').addClass('hightlight');
                    var title = 'Update Button'
                    var content = `Press the update button to save configuration`
                    var button = `<button onclick="coachmark(2,6)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(2,8)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    $('#modal_rpmalert').animate({
                        scrollTop: ($("#rpm_alert_submit").offset().top) - 60
                    }, 500);
                    this.popover_show(title, content, button);
                    break
                case 8:
                    this.popover_hide()
                    this.popover_close()
                    this.toast_store.notif(`Congrat's you finished it!`, 'That is all for RPM Alert Configuration Guide', 'bg-dangernav', 'text-dark')
                    break
            }
        },

        //COACHMARKS SPEED ALERT
        coachmark_speed(step) {
            console.log("COACHMARK SPEED");

            switch (step) {
                case 1:
                    if (this.popover_step > step) {
                        $('#close_speedalert_btn').click()
                        this.popover_hide()
                    }
                    $('#notif_btn_' + this.speedalert_idx).click();
                    $("#popover_cover").css({ "z-index": "1100" });
                    this.popover_id = 'notifdiv_' + this.speedalert_idx

                    $('.aspeed_' + this.speedalert_idx).addClass('hightlight');
                    $('#' + this.popover_id).addClass('hightlight');
                    var title = 'Choose Button'
                    var content;

                    if (this.speedalert_page == 1) {
                        content = `Choose speed alert configuration iniside <span class="badge text-bg-dark rounded-none"><i class="fas fa-wrench me-2"></i>Notification</span> button to open the speed alert configuration.`
                    } else if (this.speedalert_page == 4) {
                        content = `Choose speed alert configuration iniside <span class="badge text-bg-primary rounded-none"><i class="fas fa-wrench me-2"></i>Config</span> button to open the speed alert configuration.`
                    } else if (this.speedalert_page == 9) {
                        content = `Choose speed alert configuration iniside <span class="badge text-bg-primary rounded-none"><i class="fas fa-wrench me-2"></i>Config</span> button to open the speed alert configuration.`
                    }


                    var button = `<button onclick="coachmark(3,2)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_show(title, content, button)
                    this.popover_step = step;
                    break;
                case 2:
                    var timeout = 0;
                    if (this.popover_step < step) {
                        console.log("click notif =====");
                        timeout = 1000
                    }

                    if (this.speedalert_page == 1) {
                        this.sailink_store.fetch_speedalert(this.sailink_store.sailink_data[this.speedalert_idx])
                    } else if (this.speedalert_page == 4) {
                        this.thuraya_store.fetch_speed_notif(this.thuraya_store.thuraya_data[this.speedalert_idx])
                    } else if (this.speedalert_page == 9) {
                        this.iridium_store.fetch_speedalert(this.iridium_store.iridium_data[this.speedalert_idx])
                    }

                    var speedNotifModal = new bootstrap.Modal(document.getElementById(this.speedalert_modal), {});
                    speedNotifModal.show();
                    $("#popover_cover").css({ "z-index": "1110" });
                    this.popover_hide()
                    this.popover_id = 'speed_alert_setpop'

                    var title = 'Change The Configuration'
                    var content = `Change the configuration according to your needs`
                    var button = `<button onclick="coachmark(3,1)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button type="button" onclick="coachmark(3,3)" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    setTimeout(() => {
                        this.popover_show(title, content, button);
                        $('#speed_alert_body').addClass('hightlight');
                    }, timeout);
                    break;
                case 3:
                    this.popover_hide()
                    this.popover_id = 'speed_alert_enable'
                    $('#speed_alert_enable').addClass('hightlight');
                    var title = 'Enable or Disable'
                    var content = `Enable or disable speed alert notification`
                    var button = `<button onclick="coachmark(3,2)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(3,4)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 4:
                    this.popover_hide()
                    this.popover_id = 'speed_alert_source'
                    $('#speed_alert_source').addClass('hightlight');
                    var title = 'Source of Data'
                    var content = `Source of data which used in Speed Calculation`
                    var button = `<button onclick="coachmark(3,3)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(3,5)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 5:
                    this.popover_hide()
                    this.popover_id = 'speed_alert_minimum'
                    $('#speed_alert_minimum').addClass('hightlight');
                    var title = 'Minimum Speed Limit'
                    var content = `Input the minimum speed limit`
                    var button = `<button onclick="coachmark(3,4)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(3,6)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 6:
                    this.popover_hide()
                    this.popover_id = 'speed_alert_maximum'
                    $('#speed_alert_maximum').addClass('hightlight');
                    var title = 'Maximum Speed Limit'
                    var content = `Input the maximum speed limit`
                    var button = `<button onclick="coachmark(3,5)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(3,7)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 7:
                    this.popover_hide()
                    this.popover_id = 'speed_alert_emails'
                    $('#speed_alert_emails').addClass('hightlight');
                    var title = 'Input Email Adresses'
                    var content = `Email contacts that will receive speed alert notification when speed is over/under determined limit range`
                    var button = `<button onclick="coachmark(3,6)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(3,8)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    this.popover_show(title, content, button);
                    break;
                case 8:
                    this.popover_hide()
                    this.popover_id = 'speed_alert_submit'
                    $('#speed_alert_submit').addClass('hightlight');
                    var title = 'Update Button'
                    var content = `Press the update button to save configuration`
                    var button = `<button onclick="coachmark(3,7)" type="button" class="btn btn-primary btn-sm rounded-none me-2"><i class="fas fa-caret-left me-2"></i> Prev</button><button onclick="coachmark(3,9)" type="button" class="btn btn-primary btn-sm rounded-none">Next <i class="fas fa-caret-right ms-2"></i></button>`
                    this.popover_step = step;
                    $('#sailinkspeedNotifModal').animate({
                        scrollTop: ($("#speed_alert_submit").offset().top) - 60
                    }, 500);
                    this.popover_show(title, content, button);
                    break
                case 9:
                    this.popover_hide()
                    this.popover_close()
                    this.toast_store.notif(`Congrat's you finished it!`, 'That is all for Speed Alert Configuration Guide', 'bg-dangernav', 'text-dark')
                    break
            }
        },

        uninit_newsid() {
            this.fuelusage_idx = null;
            this.rpmalert_idx = null;
            this.speedalert_idx = null;
            this.speedalert_page = null;
        }
    }
})
